import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import MonthPicker from '../../Dropdowns/MonthSelect/MonthPicker';
import { doGET, doPOST } from '../../../util/HttpUtil';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ApplicationsManagedByAgent = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().unix()); // Unix timestamp for the selected month
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [supervisorNames, setSupervisorNames] = useState({}); // To store supervisor IDs to names mapping

  const fetchSupervisorNames = async () => {
    try {
      const response = await doGET('/api/supervisor/grid?rows=-1'); // Get all supervisor data
      const supervisorMapping = {};
      response?.data?.rows?.forEach(sup => {
        supervisorMapping[sup._id] = `${sup.salutation} ${sup.name}`; // Map supervisor_id to name
      });
      setSupervisorNames(supervisorMapping); // Save the mapping
    } catch (error) {
      console.error('Error fetching supervisor names:', error);
    }
  };

  const formatAPIData = (data) => {
    const formattedData = {
      labels: [],
      datasets: [
        {
          label: 'Applications Managed',
          data: [],
          backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue color with transparency
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };

    // Aggregate the count of applications by supervisor_ids
    const applicationsBySupervisor = {};

    data.forEach(item => {
      const supervisors = item._id.supervisor_ids || [];
      const count = item.count;

      supervisors.forEach(supervisor => {
        if (supervisor) {
          if (!applicationsBySupervisor[supervisor]) {
            applicationsBySupervisor[supervisor] = 0;
          }
          applicationsBySupervisor[supervisor] += count;
        }
      });
    });

    // Populate data for the chart
    Object.keys(applicationsBySupervisor).forEach(supervisor => {
      const supervisorName = supervisorNames[supervisor] || `${supervisor}`; // Use name if available
      formattedData.labels.push(supervisorName); // Labels for each supervisor
      formattedData.datasets[0].data.push(applicationsBySupervisor[supervisor]); // Number of applications
    });

    return formattedData;
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);

    try {
      // Get the start and end of the month
      const startOfMonth = moment.unix(selectedMonth).startOf('month').unix();
      const endOfMonth = moment.unix(selectedMonth).endOf('month').unix();

      // API call for applications managed by agents (supervisor_ids)
      const response = await doPOST('/api/application-form/statistics', {
        groupBy: { "supervisor_ids": "supervisor_ids" }, // Group by supervisor_ids
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      // Format data for the chart
      const formattedData = formatAPIData(response?.data);
      setChartData(formattedData);
    } catch (error) {
      console.error('Error fetching applications managed by agents:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSupervisorNames(); // Fetch supervisor names once when the component mounts
  }, []);

  useEffect(() => {
    if (Object.keys(supervisorNames).length > 0) {
      fetchData(); // Fetch applications data only after supervisor names are loaded
    }
  }, [selectedMonth, Object.keys(supervisorNames).length]); // Fetch when month or supervisorNames change

  return (
    <Col sm={12}>
      <Card className="py-2 pb-5 px-4 flex-1" style={{ height: "450px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="font-size-14 my-2 d-flex align-items-center">
            <h2><b>{"Applications Managed by Agents"}</b></h2>
          </div>

          <div className="d-flex align-items-center">
            <MonthPicker
              value={moment(selectedMonth * 1000).format('YYYY-MM')} // Convert back to human-readable format
              onChange={(month) => setSelectedMonth(moment(month, 'YYYY-MM').unix())} // Store as Unix timestamp
              type="text"
              noLabel
              className={"my-0"}
            />
          </div>
        </div>

        {loading ?
          <div className="d-flex justify-content-center p-4">
            <Skeleton height={30} width={700} className='text-crm-greyish font-size-12' />
          </div>
          : chartData ? (
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    grid: {
                      color: 'rgba(200, 200, 200, 0.3)', // Lighten the grid
                    },
                  },
                  x: {
                    grid: {
                      display: false, // Remove vertical grid lines
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    position: 'top',
                    labels: {
                      font: {
                        size: 14,
                        weight: 'bold',
                      },
                    },
                  },
                  tooltip: {
                    backgroundColor: '#fff',
                    titleColor: '#333',
                    bodyColor: '#666',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                  },
                },
              }}
            />
          ) : (
            <p>No data available</p>
          )}
      </Card>
    </Col>
  );
};

export default ApplicationsManagedByAgent;
