import React, { useState, useEffect } from "react";
import { doGET } from "../../util/HttpUtil";
import { InputField } from "../../components";

export default function EmailWithValidation({
  className,
  label = "Email",
  value: initialValue,
  onChange,
  isDisabled,
  notifyParent,
  onError = () => { },
  showErr,
  required,
  inputStyle,
  group = "Businesses_Lite",
  err,
  form_id = "",
  placeholder = "Email"
}) {
  const [value, setValue] = useState(initialValue || "");
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setError(err);
  }, [err]);

  useEffect(() => {
    setHasError(showErr);
  }, [showErr]);

  const handleChange = async (newData) => {
    const updatedValue = newData.email || "";
    setValue(updatedValue);
    onChange(updatedValue);
  };

  const validateEmail = async (focused) => {
    if (!value || focused) return;
    setLoading(true);

    const response = await doGET(`/api/cyclos/verify/field?group=${group}&value=${encodeURIComponent(value)}&field=email&form_id=${form_id}`);
    setLoading(false);
    if (response.status === 204) {
      notifyParent(true);
      onError({ errorMsg: "", hasError: false })
      setError(null);
      setHasError(false);
    } else if (response.status === 200 && response.data) {
      setError(response.data);
      onError({ errorMsg: response.data, hasError: true })
      setHasError(true);
      notifyParent(false);
    }
  };

  useEffect(() => {
    validateEmail(isFocused)
  }, [value])

  return (
    <div className={`${className}`}>
      <InputField
        label={label}
        placeholder={placeholder}
        type="email"
        value={value}
        onChange={(e) => handleChange({ email: e })}
        onBlur={() => {
          setIsFocused(false)
          validateEmail(false)
        }}
        onFocus={(v) => {
          setIsFocused(true)
        }}
        error={error}
        showErr={hasError}
        isDisabled={isDisabled}
        required={required}
        style={inputStyle}
      />
      {loading ? <div className="selectfield__info mt-1">Validating Email...</div> : null}
    </div>
  );
}
