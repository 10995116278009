import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import {
  InputField,
  SelectField
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import FormQuestionsService from "./FormQuestionsService";
import "./style.css";
import { FiMinusCircle } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";

const EditFormQuestions = ({ editId, onChange = () => { }, hasErr }) => {

  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});


  const fetch = async () => {
    try {
      let form = await FormQuestionsService.get(editId);
      setData({ ...form });
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData({})
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, FormQuestionsService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  const changeSectionField = (sectionIndex, value, field) => {

    setData((X) => {
      return {
        ...data, options: (data?.options ?? []).map((f, curr) => {
          if (sectionIndex === curr) return value;
          return f;
        })
      }
    }
    );
  };



  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <div style={{ width: "calc(100% - 5px)" }} className="row px-3 ">


          <Row className="mb-3 mt-4">
            <Col className="col-lg">
              <SelectField
                data={[
                  { label: "Business Plus", value: "BUSINESS_PLUS" },
                  { label: "Individual Plus", value: "INDIVIDUAL_PLUS" },
                  { label: "Business Prime", value: "BUSINESS_PRIME" },
                  { label: "Individual Prime", value: "INIDIVIDUAL_PRIME" },
                ]}
                value={data?.formType}
                required={true}
                onChange={(v) => {
                  setData({ ...data, formType: v?.value })
                }}
                label={"Form Type"}
                placeholder={"Form Type"}
                error={!data?.formType?.length ? ("Please select form type.") : ""}
                showErr={hasErr} 
              />
            </Col>

            <Col className="col-lg">
              <SelectField
                data={[
                  { label: "Text", value: "TEXT" },
                  // { label: "Attachment", value: "DOCUMENT" },
                  { label: "Dropdown", value: "DROPDOWN" },
                  // { label: "Radio", value: "RADIO" },
                  // { label: "Date", value: "DATE" },
                  // { label: "Number", value: "NUMBER" },
                  // { label: "Country", value: "COUNTRY" },
                  // { label: "City", value: "CITY" }
                ]}
                value={data?.inputType}
                required={true}
                onChange={(v) => {
                  setData({ ...data, inputType: v?.value })
                }}
                label={"Field Type"}
                placehold
                error={!data?.inputType?.length ? ("Please select field type.") : ""}
                showErr={hasErr} 

              />
            </Col>


            <Col className="col-lg">
              <SelectField
                data={[
                  { label: "Application Form", value: "Application Form" },
                  { label: "Proof of Address", value: "Proof of Address" },
                  { label: "KYC Verification", value: "KYC Verification" },
                  { label: "Sanctions Screening", value: "Sanctions Screening" },
                  { label: "Risk Assessment", value: "Risk Assessment" }
                ]}
                value={data?.step}
                required
                onChange={(v) => {
                  setData({ ...data, step: v?.value })
                }}
                label={"Step"}
                placeholder={"Step"}
                error={!data?.step?.length ? ("Please select step.") : ""}
                showErr={hasErr} 

              />
            </Col>

          </Row>

          <Row className="mb-3">
            <Col className="col-lg">
              <InputField
                value={data?.title}
                label={"Question Label"}
                onChange={(v) => {
                  setData({
                    ...data,
                    title: v,
                  });
                }}
                type="text"
                error={!data?.title ? ("Please enter Question Label.") : ""}
                showErr={hasErr} 
                required
                maxLength={300}
              />
            </Col>

          </Row>
          {(data?.inputType === "DROPDOWN") && (
            <>
              <Row className="d-flex mx-1 justify-content-end pb-2 my-1 ">
                <Button
                  style={{
                    backgroundColor: '#1678AE',
                    height: "40px",
                    padding: "2px 4px 2px 4px",
                    fontSize: "16px",
                    width: "130px",
                    borderRadius: "10px"
                  }}
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => setData({ ...data, options: [...(data?.options ?? []), ""] })}
                >
                  <FaPlus className="me-3" /> Options

                </Button>
              </Row>
              {(data?.options ?? [])?.map((section, sectionIndex) => (
                <Row className="border-bottom d-flex align-items-center border-dark ps-4 ">

                  <InputField
                    showErr={hasErr}
                    required={true}
                    value={section}
                    onChange={(v) => changeSectionField(sectionIndex, v, "value")}
                    label={`Option ${sectionIndex + 1}`}
                    className=" col-sm-11 ps-3 py-1"
                    type="text"
                  />


                  <FiMinusCircle
                    style={{
                      cursor: "pointer"
                    }}
                    className=" col-sm-1 px-1 "
                    onClick={() =>
                      setData(
                        {
                          ...data,
                          options: (data?.options ?? []).filter((t, tcurr) => tcurr !== sectionIndex)
                        }
                      )
                    }
                    color="red"
                    size={21}
                  />




                </Row>
              ))}
            </>
          )}


        </div>
      }



    </React.Fragment >
  );
};

export default observer(EditFormQuestions);
