import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import MonthPicker from '../../Dropdowns/MonthSelect/MonthPicker';
import { doPOST } from '../../../util/HttpUtil';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend);

const AccountCreatedChart = () => {
  const [selectedMonth, setSelectedMonth] = useState(moment().unix()); // Unix timestamp for the selected month
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  const formatAPIData = (data, daysInMonth, label, color) => {
    const formattedData = {
      label: label,
      data: new Array(daysInMonth).fill(0), // Initialize with 0 for each day of the month
      borderColor: `${color}`,
      backgroundColor: `${color}11`, // Light color with transparency for fill
      pointBackgroundColor: color,
      pointBorderColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: color,
      fill: true,
      borderWidth: 3,
      tension: 0.4, // Smooth line
    };

    let totalCount = 0; // To keep track of the total count

    // Populate data for each day
    data.forEach(item => {
      const createdAtDate = moment.unix(item._id["createdAt"]); // Convert timestamp to moment
      const day = createdAtDate.date() - 1; // Get day (1-based) and convert to 0-based index
      formattedData.data[day] += item.count;
      totalCount += item.count; // Increment the total count
    });
    formattedData.label = `${label} (${totalCount})`;

    return formattedData;
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);

    try {
      // Get the start and end of the month
      const startOfMonth = moment.unix(selectedMonth).startOf('month').unix();
      const endOfMonth = moment.unix(selectedMonth).endOf('month').unix();
      const daysInMonth = moment.unix(selectedMonth).daysInMonth();

      // API calls for the various statuses
      const accountCreatedResponse = await doPOST('/api/application-form/statistics', {
        groupBy: { "createdAt": "createdAt" },
        inFilters: { status: [6] },
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      const accountDeclinedResponse = await doPOST('/api/application-form/statistics', {
        groupBy: { "createdAt": "createdAt" },
        inFilters: { status: [9] },
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      const newApplicationsResponse = await doPOST('/api/application-form/statistics', {
        groupBy: { "createdAt": "createdAt" },
        inFilters: { status: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] },
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      // Additional API calls for Incomplete, Draft, and Rejected statuses
      const incompleteResponse = await doPOST('/api/application-form/statistics', {
        groupBy: { "createdAt": "createdAt" },
        inFilters: { status: [2] },
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      const draftResponse = await doPOST('/api/application-form/statistics', {
        groupBy: { "createdAt": "createdAt" },
        inFilters: { status: [0] },
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      const rejectedResponse = await doPOST('/api/application-form/statistics', {
        groupBy: { "createdAt": "createdAt" },
        inFilters: { status: [4] },
        btwFilters: { createdAt: [startOfMonth, endOfMonth] }
      });

      // Format data for each line
      const accountCreatedData = formatAPIData(accountCreatedResponse?.data, daysInMonth, 'Account Created', '#4bc0c0');
      const accountDeclinedData = formatAPIData(accountDeclinedResponse?.data, daysInMonth, 'Declined', '#a754ff');
      const newApplicationsData = formatAPIData(newApplicationsResponse?.data, daysInMonth, 'New Applications', '#36a2eb');
      const incompleteData = formatAPIData(incompleteResponse?.data, daysInMonth, 'Incomplete', '#ff9f40');
      const draftData = formatAPIData(draftResponse?.data, daysInMonth, 'Draft', '#999999');
      const rejectedData = formatAPIData(rejectedResponse?.data, daysInMonth, 'Rejected', '#ff6384');

      // Combine all datasets
      setChartData({
        labels: Array.from({ length: daysInMonth }, (_, i) => `${i + 1} ${moment(selectedMonth * 1000).format('MMM YYYY')}`),
        datasets: [newApplicationsData, accountCreatedData, accountDeclinedData, incompleteData, draftData, rejectedData],
      });
    } catch (error) {
      console.error('Error fetching Application status stats:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth]);

  return (
    <Col sm={12}>
      <Card className="py-2 pb-5 px-4 flex-1" style={{ height: "450px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="font-size-14 my-2 d-flex align-items-center">
            <h2><b>{"Application Status"}</b></h2>
          </div>

          <div className="d-flex align-items-center">
            <MonthPicker
              value={moment(selectedMonth * 1000).format('YYYY-MM')} // Convert back to human-readable format
              onChange={(month) => setSelectedMonth(moment(month, 'YYYY-MM').unix())} // Store as Unix timestamp
              type="text"
              noLabel
              className={"my-0"}
            />
          </div>
        </div>

        {loading ?
          <div className="d-flex justify-content-center p-4">
            <Skeleton height={30} width={700} className='text-crm-greyish font-size-12' />
          </div>
          : chartData ? (
            <Line
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    grid: {
                      color: 'rgba(200, 200, 200, 0.3)', // Lighten the grid
                    },
                  },
                  x: {
                    grid: {
                      display: false, // Remove vertical grid lines
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    position: 'top',
                    labels: {
                      font: {
                        size: 12,
                      },
                    },
                  },
                  tooltip: {
                    backgroundColor: '#fff',
                    titleColor: '#333',
                    bodyColor: '#666',
                    borderColor: 'rgba(66, 165, 245, 1)',
                    borderWidth: 1,
                  },
                },
              }}
            />
          ) : (
            <p>No data available</p>
          )}
      </Card>
    </Col>
  );
};

export default AccountCreatedChart;
