import { toJS } from "mobx";
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SelectField } from '../../SelectField/SelectField';
import { SelectFieldMultiple } from '../../SelectField/SelectFieldMultiple';
import { BottomBarButton, ModalComponent, SearchButton } from '../../index';
import ReasonCodeSelectService from './ReasonCodeSelectService';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';

const ReasonCodeSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label = "Introducer Name",
    showErr,
    outline,
    inline,
    borderNone,
    multi,
    ...props
}) => {
    const [reasonCodes, setReasonCodes] = useState([]);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);

    const fetchTrackers = async () => {
        try {
            await ReasonCodeSelectService.fetch();
        } catch (error) {
            showError(t("Error"), error);
        }
    };

    useEffect(() => {
        if (ReasonCodeSelectService?.reasonCodes?.length === 0) fetchTrackers();
    }, []);

    const updateTrackers = () => {
        setReasonCodes(
            toJS(ReasonCodeSelectService.reasonCodes)
                ?.map((introducer) => ({
                    value: introducer._id,
                    label: <div>{introducer.title}</div>,
                    title: introducer.title
,
                }))
                .filter(Boolean)
        );
    };

    useEffect(() => {
        updateTrackers();
    }, [ReasonCodeSelectService.reasonCodes]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const filterOption = useCallback((option, inputValue) => {
        return (
            !inputValue ||
            option?.data?.title?.toLowerCase()?.includes(inputValue?.toLowerCase()) 
        );
    }, []);

    const renderSelectField = ({ disabled }) => (
        <SelectField
            filterOption={filterOption}
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Reason Codes")} />
            )}
            outline={outline}
            className={className}
            onChange={onChange}
            data={reasonCodes}
            required={required}
            value={value}
            label={label || (!isDisabled && t("Reason Code"))}
            error={!value ? t(`Please Select ${label || "Reason Code"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            // RightComponent={() => <SearchButton onClick={openModal} />}
            {...props}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            filterOption={filterOption}
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Reason Codes")} />
            )}
            outline={outline}
            className={className}
            onChange={(selectedValues) =>
                onChange(selectedValues.map((c) => c?.value ?? c))
            }
            data={reasonCodes}
            value={value ? reasonCodes.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Reason Codes"}`) : ""}
            required={required}
            label={label || (!isDisabled && t("Select Reason Codes"))}
            showErr={showErr}
            // RightComponent={() => <SearchButton onClick={openModal} />}
        />
    );

    return (
        <>
            {modal && (
                <ModalComponent
                    position="top"
                    size="large"
                    onToggle={closeModal}
                    isOpen={modal}
                >
                    <ModalComponent.Title>{t("Manage Reason Codes")}</ModalComponent.Title>
                    <ModalComponent.Body>
                    </ModalComponent.Body>
                </ModalComponent>
            )}

            {reasonCodes?.length > 0
                ? multi
                    ? renderSelectFieldMultiple()
                    : renderSelectField({ disabled: false })
                : renderSelectField({ disabled: true })}
        </>
    );
};

export default observer(ReasonCodeSelect);
