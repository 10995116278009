import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaSpinner } from "react-icons/fa";
import { Button } from "reactstrap";
import { ModalComponent } from '../../../../components';
import { DialogContext } from '../../../../store/context/DialogContext';
import { doPOST } from '../../../../util/HttpUtil';
import "./styles.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";  // Import Quill CSS
import QuillToolbar from "./QuillToolbar";

const EmailModal = ({ isOpen, toggle, toEmail, applicationId, onSend = () => {} }) => {
    const { showError, showMessage } = useContext(DialogContext);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailRemark, setEmailRemark] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ subject: '', remark: '' });
    const quillRef = useRef(null);

    // Validate fields before sending email
    const validateFields = () => {
        const newErrors = {
            subject: emailSubject ? '' : 'Subject is required.',
            remark: !["<p><br></p>","<p></p>"]?.includes(emailRemark) && emailRemark ? '' : 'Content is required.',
        };
        setErrors(newErrors);
        // Return false if any error exists
        if (newErrors?.subject) (showError(newErrors?.subject))
        if (newErrors?.remark) (showError(newErrors?.remark))
        return !newErrors.subject && !newErrors.remark;
    };

    // Handle form submission and email sending
    const handleEmailSend = async (e) => {
        e.preventDefault();
        if (loading) return;

        // Validate the inputs
        if (!validateFields()) return;

        setLoading(true);
        try {
            const response = await doPOST( "/api/application-form/send/email", {
                form_id: applicationId,
                subject: emailSubject,
                htmlString: emailRemark,
            });
            if(response?.status == 200) {
                showMessage('Email sent successfully');
                onSend();
                setEmailSubject('');
                setEmailRemark('');
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    // Clear specific error when the user types in the input field
    const handleSubjectChange = (e) => {
        setEmailSubject(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, subject: e.target.value ? '' : "Subject is required." }));
    };

    const handleRemarkChange = (e) => {
        setEmailRemark(e);
        setErrors((prevErrors) => ({
            ...prevErrors, remark: e ? '' : "Remark is required."
        }));
    };

    useEffect(() => {
        // Reset fields and errors when modal opens
        if (isOpen) {
            setEmailSubject('');
            setEmailRemark('');
            setErrors({ subject: '', remark: '' });
        }
    }, [isOpen]);

    return (
        <ModalComponent
            position={"centered"}
            size={"large"}
            isOpen={isOpen}
            onToggle={toggle}
            className="fv_email_modal"
        >
            <ModalComponent.Title>
                <span className='ms-2'>Send Email</span>
            </ModalComponent.Title>
            <ModalComponent.Body>
                <form className="fv_email_form" onSubmit={handleEmailSend}>
                    <div className="fv_email_field">
                        <input
                            type="email"
                            name="to"
                            placeholder="To"
                            value={toEmail || ''}
                            readOnly
                            className="fv_email_input fv_email_to"
                        />
                    </div>

                    <div className="fv_email_field">
                        <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={emailSubject}
                            onChange={handleSubjectChange}
                            className={`fv_email_input fv_email_subject ${errors.subject ? 'fv_email_input_error' : ''}`}
                        />
                        {errors.subject && <span className="fv_email_error">{errors.subject}</span>}
                    </div>

                
                    <div className={`d-flex flex-column align-items-center email_quill ${errors.remark ? 'fv_email_input_error' : ''}`} >
                        <ReactQuill
                            ref={quillRef}
                            value={emailRemark}
                            onChange={(content, delta, source, editor) => {
                                if (source !== "api") {
                                    handleRemarkChange(editor.getHTML());
                                }
                            }}
                            className='w-100'
                            modules={{ toolbar: "#quill-toolbar" }}
                            placeholder="Content"
                        />
                    </div>

               

                    <div className="fv_email_footer">
                 
                        <div className="quill-toolbar-container">
                            <QuillToolbar />
                        </div>

                        <Button
                            type="submit"
                            color="primary"
                            className="fv_email_send_button"
                            disabled={loading}
                        >
                            {loading ? <FaSpinner className="spinner" /> : 'Send'}
                        </Button>
                    </div>
                </form>
            </ModalComponent.Body>
        </ModalComponent>
    );
};

export default observer(EmailModal);
