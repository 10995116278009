import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { doGET, doPOST } from '../../../../util/HttpUtil';
import { InfoPair } from '../ondato/Ondato';
import AppearsOn from './AppearsOn';
import { ComplianceBadge, ComplyAdvantageSkeleton, DownloadReport, Refresh, isUrl, matchStatusOptions, matchStatusRejectedOptions, riskLevelOptions } from './ComplyAdvantage';
import StatusDropDown from './StatusDropDown';
import { UserContext } from '../../../../store/context/UserContext';
import { DialogContext } from '../../../../store/context/DialogContext';
import { getBaseUrls } from '../../../../constants';
import { StatusDisplay, stepStatusOptions } from '../applicationDocument/ApplicationDocument';


const formatStatus = (status) => {
  return _.startCase(_.replace(status, /_/g, ' '));
};

const ComplyAdvantageViewer = ({ isLocked, uboName, formId, complyAdvantageReport, complyObject, onStatusChange, onRiskLevelChange, onRefresh }) => {

  const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  const { userContent, } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { showError, showMessage, showConfirmWithTextAreaAndCheckBox, showConfirm } = useContext(DialogContext);


  const refreshComplyData = async () => {
    setLoading(true)
    try {
      const complyResponse = await doGET(`/api/comply/advantage/form/status?id=${complyObject?._id}&refresh=true`);
      if (complyResponse?.status == 200) {
        onRefresh();
      }
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false)
    }
  }

 
  const handleStatusChange = async (payload) => {
    setLoading(true);
    try {
      if (['potential_match', 'true_positive', 'true_positive_reject'].includes(payload)) {
        const result = await showConfirmWithTextAreaAndCheckBox({
          title: `Do you want to change the match status to "${formatStatus(payload)}"?`,
          description: `This will reject the application. Please provide a reason.`,
          remarkRequired: false,
          checkboxRequired: true,
          checkboxLabel: "Notify Applicant",
          defaulValueCheckBox: true 
        });

        if (result === true || (result && result.confirmed)) {
          const updatedStatus = await doPOST(`/api/comply/advantage/status/change${result?.isChecked ? "?notifyApplicant=true" : ""}`, {
            _id: complyObject?._id,
            risk_level: complyObject?.risk_level,
            match_status: payload,
            remark: result?.remark ?? "",
          });

          onStatusChange();
          showMessage("Status Changed and Application Rejected");
        }
      } else {
        const result = await showConfirm({
          title: `Do you want to change the match status to "${formatStatus(payload)}"?`,
          description: `This will approve sanction screening for ${uboName}`
        });

        if (result) {
          const updatedStatus = await doPOST(`/api/comply/advantage/status/change`, {
            _id: complyObject?._id,
            risk_level: complyObject?.risk_level,
            match_status: payload
          });

          onStatusChange();
          showMessage("Status Changed");
        }
      }
    } catch (error) {
      console.error('Failed to change status:', error);
      showError(error);
    } finally {
      setLoading(false);
    }
  };




  const handleRiskLevelChange = async (payload) => {
    setLoading(true)
    try {
      const updatedStatus = await doPOST(`/api/comply/advantage/status/change`, {
        _id: complyObject?._id,
        match_status: complyObject?.match_status,
        risk_level: payload,
      });
      onRiskLevelChange()
      showMessage("Risk Level Changed")
    } catch (error) {
      console.error('Failed to change status:', error);
    } finally {
      setLoading(false)
    }
  };

  const baseUrls = getBaseUrls();

  const hasUnrejectRight = (status) => {
    if (status == "potential_match" || status == "true_positive") { // rejected
      return userContent?.rights?.includes(2804);
    }
    return true;
  };

  if (loading)
    return <ComplyAdvantageSkeleton />

  return (
    <Container key={userContent?.rights} fluid>
      {complyAdvantageReport && Object.keys(complyAdvantageReport)?.length ?
        <Row>
          <div className='d-flex flex-column flex-sm-row justify-content-end align-items-sm-center'>
            {(!isLocked && userContent?.rights?.includes(2802)) ? <StatusDropDown
              label="Match Status"
              data={hasUnrejectRight(complyObject?.match_status) ? matchStatusOptions : matchStatusRejectedOptions}
              value={complyObject?.match_status}
              onChange={handleStatusChange}
              className={"me-2 mb-1"}
            /> : <></>}

            {!isLocked && userContent?.rights?.includes(2802) ? <StatusDropDown
              label="Risk Level"
              data={riskLevelOptions}
              value={complyObject?.risk_level}
              onChange={handleRiskLevelChange}
              className={"me-2 mb-1"}
            /> : <></>}

            <div className='d-flex align-items-center mb-2'>

              {!isLocked && <Refresh
                onClick={refreshComplyData}
                formId={formId}
              />}


              <a
                href={`${baseUrls?.complyAdvantage}/app/case-management/search/${complyAdvantageReport?.ref}`}
                target="_blank"
                className='me-2'
                rel="noopener noreferrer"
                data-tip="View KYC Document"
                id={tooltipId}
              >
                <FaExternalLinkAlt size={20} style={{ color: '#007bff' }} />
              </a>
              <Tooltip
                style={{
                  zIndex: '200000',
                }}
                anchorSelect={`#${tooltipId}`}
              >
                View on Comply
              </Tooltip>


              <DownloadReport reportId={formId} />
            </div>

          </div>
          <Col>
            <Row className='p-0'>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Compliance Check Details</h4>
                    <InfoPair label="Match Status" value={complyObject?.match_status ? <ComplianceBadge status={complyObject?.match_status} /> : "—"} />
                    <InfoPair label="Risk Level" value={complyObject?.risk_level ?? "—"} />
                    <InfoPair label="Total Blacklist Hits" value={complyAdvantageReport?.total_blacklist_hits ?? "—"} />
                    <InfoPair label="Total Hits" value={complyAdvantageReport?.total_hits ?? "—"} />
                    <InfoPair label="Total Matches" value={complyAdvantageReport?.total_matches ?? "—"} />
                    <InfoPair label="Submitted Term" value={complyAdvantageReport?.submitted_term ?? "—"} />
                    <InfoPair label="Search Term" value={complyAdvantageReport?.search_term ?? "—"} />
                    <InfoPair label="Creation Date" value={complyAdvantageReport?.created_at ?? "—"} />
                    <InfoPair label="Last Updated" value={complyAdvantageReport?.updated_at ?? "—"} />

                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Tags</h4>
                    {complyAdvantageReport?.labels?.map((label, index) => (
                      <InfoPair key={index} label={`${label.name}`} value={label.value} />
                    ))}
                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Filters</h4>
                    <InfoPair label="Entity Type" value={complyAdvantageReport?.filters?.entity_type ?? "—"} />
                    <InfoPair label="Fuzziness" value={complyAdvantageReport?.filters?.fuzziness ?? "—"} />
                    <InfoPair label="Exact Match" value={complyAdvantageReport?.filters?.exact_match ? "Yes" : "No"} />
                  </CardBody>
                </Card>
              </Col>

              {complyAdvantageReport?.hits?.length ? <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Hits</h4>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                      {complyAdvantageReport?.hits?.map((hit, index) => (
                        <Accordion.Item eventKey={index?.toString()} key={index}>
                          <Accordion.Header>  <div className='d-flex justify-content-between align-items-center w-100 me-2  '> <span>{hit?.doc?.name} <ComplianceBadge status={hit?.match_status} /> </span>  <AppearsOn types={hit?.doc?.types} /></div>  </Accordion.Header>
                          <Accordion.Body>
                            <InfoPair label="Name" value={hit?.doc?.name} />
                            <InfoPair label="Entity Type" value={hit?.doc?.entity_type} />
                            {/* <InfoPair label="Match Status" value={hit?.match_status ? <ComplianceBadge status={hit?.match_status} /> : "—"} /> */}
                            <InfoPair label="Score" value={hit?.score} />
                            <InfoPair label="Whitelisted" value={hit?.is_whitelisted ? "Yes" : "No"} />

                            <Accordion>
                              <Accordion.Item eventKey={`${index}-fields`}>
                                <Accordion.Header>Doc Fields</Accordion.Header>
                                <Accordion.Body>
                                  {hit?.doc?.fields?.map((field, fieldIndex) => (
                                    <div key={fieldIndex}>
                                      <p><strong>{field.name}: </strong>

                                        {isUrl(field.value) ? (
                                          <a href={field.value} target="_blank" rel="noopener noreferrer">
                                            {field.value}
                                          </a>
                                        ) : (
                                          field.value
                                        )}
                                      </p>
                                    </div>
                                  ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>


                            <Accordion>
                              <Accordion.Item eventKey={`${index}-associates`}>
                                <Accordion.Header>Associates</Accordion.Header>
                                <Accordion.Body>
                                  {hit?.doc?.associates?.map((associate, assocIndex) => (
                                    <div key={assocIndex}>
                                      <p><strong>{associate.name} ({associate.association})</strong> </p>
                                    </div>
                                  ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>


                            <Accordion>
                              <Accordion.Item eventKey={`${index}-media`}>
                                <Accordion.Header>Media</Accordion.Header>
                                <Accordion.Body>
                                  {hit?.doc?.media?.map((media, mediaIndex) => (
                                    <div className='mt-2' style={{ borderBottom: "1px solid #dee2e6" }} key={mediaIndex}>
                                      <p><strong>Date: </strong> {media.date}</p>
                                      <p><strong>Title: </strong> {media.title}</p>
                                      <p><strong>Snippet: </strong> {media.snippet}</p>
                                      <p><strong>URL: </strong> <a href={media.url} target="_blank" rel="noopener noreferrer">Read more</a></p>
                                    </div>
                                  ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </CardBody>
                </Card>
              </Col> : null}
            </Row>
          </Col>
        </Row> :
        <div>
          <h4><b>Compliance Check is not performed yet</b></h4>
          <ComplyAdvantageSkeleton />
        </div>}
    </Container>
  )
}

export const ComplyAdvantageGroupedByUBOs = ({ isLocked, reload, formId, refreshId }) => {
  const [complyAdvantageReports, setComplyAdvantageReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uboIdName, setUboIdName] = useState({});

  const fetchComplyData = async () => {
    setLoading(true);
    try {
      const complyAdvantageResponse = await doGET(`/api/comply/advantage/form/grid?form_id=${formId}`);
      if (complyAdvantageResponse?.status == 200 && complyAdvantageResponse?.data?.rows) {
        setComplyAdvantageReports(complyAdvantageResponse?.data?.rows);
      }
    } catch (error) {
      console.error('Failed to fetch Comply data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUboData = async () => {
    if (formId) {
      try {
        const uboResponse = await doGET(`/api/ubo/grid?application_id=${formId}`);
        if (uboResponse.status === 200) {
          const obj = {};
          (uboResponse?.data?.rows ?? []).forEach((v) => {
            obj[v?._id] = v;
          });
          setUboIdName(obj);
        } else {
          throw new Error('Failed to fetch UBO data');
        }
      } catch (error) {
        console.error('Failed to fetch UBO data:', error);
      }
    }
  };

  useEffect(() => {
    fetchUboData();
    fetchComplyData();
  }, [formId, refreshId]);

  if (loading) return <ComplyAdvantageSkeleton />;

  if (!complyAdvantageReports?.length) return <>
    <div>
      <h4><b>Compliance Check is not performed yet</b></h4>
      <ComplyAdvantageSkeleton />
    </div>
  </>;

  const groupedReports = _.groupBy(complyAdvantageReports, report => report.ubo_id || 'Business');
  const maxSeqIndex = complyAdvantageReports.reduce((acc, current, idx, arr) => {
    return (arr[acc].seq > current.seq) ? acc : idx;
  }, 0);


  const renderAccordion = (reports, uboId, uboName) => (
    <Accordion defaultActiveKey={`${maxSeqIndex}`}>
      {reports?.map((report, index) => (
        <Accordion.Item eventKey={`${index}`} key={index}>
          <Accordion.Header>
            <div className='d-flex justify-content-between align-items-center w-100 '>
              <span className='d-flex'>
                {report?.seq}. {report?.data?.search_term}
                <span className='ms-2'><StatusDisplay value={stepStatusOptions?.find(v => report?.flowStatus == v.value)} /></span>
              </span>
              <span className='me-3'>
                

                Match Status: {formatStatus(report?.match_status) ?? "Unknown"}
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ComplyAdvantageViewer
              onRefresh={() => { fetchComplyData(); reload();}}
              onRiskLevelChange={() => {
                reload();
                fetchComplyData()
              }}
              onStatusChange={() => {
                reload();
                fetchComplyData()
              }}
              formId={formId}
              uboName={uboName}
              complyObject={report}
              complyAdvantageReport={report?.data}
              isLocked = {isLocked}
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );

  return (
    <Container fluid>
      {groupedReports && Object?.keys(groupedReports).map(uboId => {
        const reports = groupedReports[uboId];
        const highestSeqReport = reports.reduce((prev, current) => (prev.seq > current.seq ? prev : current), reports[0]);
        const uboName = `${uboIdName[uboId]?.firstName ?? ''} ${uboIdName[uboId]?.lastName ?? ''}`.trim();

        return (
          <Card key={uboId}>
            <CardBody>
              <div className='d-flex justify-content-between align-items-center'>
                <h4>Sanctions Screening for  {uboId == "Business" ? "Business" : uboName || 'Unknown UBO'} <span className='ms-2'><StatusDisplay value={stepStatusOptions?.find(v => highestSeqReport?.flowStatus == v.value)} /></span> </h4>

              </div>
              {renderAccordion(reports, uboId, uboName)}
            </CardBody>
          </Card>
        );
      })}
      {!Object?.keys(groupedReports).length && (
        <Card>
          <CardBody>
            <ComplyAdvantageViewer onRefresh={() => { fetchComplyData(); reload();}} onRiskLevelChange={() => { fetchComplyData() }} onStatusChange={() => { fetchComplyData() }} formId={formId} complyObject={complyAdvantageReports[0]} complyAdvantageReport={complyAdvantageReports[0]?.data} />
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default ComplyAdvantageGroupedByUBOs;