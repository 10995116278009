import _ from 'lodash';

export const RiskCalculationGridColumns = {
  version: 1,
  columns: [

    {
      accessor: "forForm",
      title: "For Form",
      renderer: (dataObj) => {
        return <div>
          {_.startCase(_.toLower(dataObj?.formType ?? ""))}
        </div>
      },
      show: true,
    },

    {
      accessor: "name",
      title: "Name",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.name ?? "")}
        </div>
      },
      show: true,
    },

 

  
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 60,
      show: true,
      required: true,
    },
  ],
};
