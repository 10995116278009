import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "reactstrap";
import {
    InputField
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import UTMTrackerService from "./UTMTrackerService";
import { hostMap } from "../../constants";
import clipboardCopy from 'clipboard-copy';
import { FaCopy } from 'react-icons/fa';
import _ from "lodash";

const EditUTMTracker = ({ editId, onChange = () => { }, hasErr }) => {

    const { showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [applicationLinks, setApplicationLinks] = useState([]);

    const fetch = async () => {
        setLoading(true);
        try {
            let form = await UTMTrackerService.get(editId);
            setData({ ...form });
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setData({});
        if (editId) {
            fetch();
        } else {
            setLoading(false);
        }
    }, [editId, UTMTrackerService?.version]);

    useEffect(() => {
        onChange(data);
        generateApplicationLinks();
    }, [data]);

    const generateApplicationLinks = () => {
        const currentHost = window.location.hostname;
        const applyHost = hostMap[_.toLower(currentHost)] || 'apply.fvbank.us';

        const applicationTypes = [
            { label: "Individual Plus", path: "individual-plus" },
            // { label: "Business Plus", path: "business-plus" }
        ];

        if (data?.utm_referrer && /^[a-z0-9]+$/.test(data.utm_referrer)) {
            const links = applicationTypes.map(appType => ({
                label: appType.label,
                url: `https://${applyHost}/${appType.path}?utm_referrer=${data.utm_referrer}`
            }));
            setApplicationLinks(links);
        } else {
            setApplicationLinks([]);
        }
    };

    const handleCopy = (text) => {
        clipboardCopy(text);
        showMessage("Link copied to clipboard");
    };

    return (
        <React.Fragment>
            {loading ? (
                <ThreeBounce />
            ) : (
                <div className="row px-3" style={{ width: "calc(100% - 5px)" }}>
                    <Row>
                        <InputField
                            value={data?.name}
                            required
                            label="Introducer Name"
                            onChange={(v) => {
                                setData({
                                    ...data,
                                    name: v,
                                });
                            }}
                            regex={/^[A-Za-z0-9\s\-\/\.,:_]*$/}
                            maxLength={50}
                            className="col-12"
                            type="text"
                        />
                    </Row>

                    <Row>
                        <InputField
                            value={data?.utm_referrer}
                            required
                            label="Referrer Code"
                            onChange={(v) => {
                                setData({
                                    ...data,
                                    utm_referrer: v,
                                });
                            }}
                            className="col-12"
                            type="text"
                            error={
                                data?.utm_referrer && !/^[a-z0-9]+$/.test(data?.utm_referrer)
                                    ? "Invalid Referrer Code. Only lowercase letters and numbers are allowed."
                                    : ""
                            }
                            showErr={data?.utm_referrer && !/^[a-z0-9]+$/.test(data?.utm_referrer)}
                        />
                    </Row>

                    {applicationLinks.length > 0 && (
                        <Row className="mt-4">
                            <div className="col-12">
                                <label>
                                    <strong>Applications Link (Preview):</strong>
                                </label>
                                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                    {applicationLinks.map((link, index) => (
                                        <li key={index} className="d-flex align-items-center mb-2">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
                                                {link.label}:
                                            </span>
                                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                {link.url}
                                            </a>

                                            <FaCopy
                                                style={{
                                                    marginLeft: '10px',
                                                    cursor: 'pointer',
                                                    color: '#007bff',
                                                }}
                                                onClick={() => { handleCopy(link.url) }}
                                                title="Copy to clipboard"
                                            />

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Row>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default observer(EditUTMTracker);
