import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditSupervisors from "./EditSupervisors";
import { STRUCTURE } from "./SupervisorConstant";
import { GridColumns } from "./SupervisorGridColumns";
import Supervisorservice from "./SupervisorService";
import PagesNoRights from "../Utility/pages-no-rights";
import AuditLogButton from "../../components/Audit/AuditLogButton";

export function isValidEmail(email) {
    const re = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(email);
}


const Supervisors = ({ value,
    insidePane,
    multiMode,
    onSelect = () => { },
    corp_id,
    hideTitleBar,
    hideFilterBar,
    dontShowCheck = false,
    updateHasSupervisors = () => { },


}) => {

    let { supervisor_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const { userContent } = useContext(UserContext);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(supervisor_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/user/create" || supervisor_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            const rows = await Supervisorservice.fetch(filterUrl);
            setLoading(false);

            updateHasSupervisors(rows?.length ? true : false)

        } catch (e) {
            showError(e);
            setLoading(false);

        }
    };


    useEffect(() => {
        if (userContent?.rights?.includes(2300)) {
            let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
            if (corp_id) {
                filterObject = { ...filterObject, corp_id: corp_id };
                filterUrl += "corp_id=" + corp_id;
            }
            setFilterURL(filterUrl)
            setFilterObject(filterObject)
            fetchData(filterUrl);
            multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
        }
    }, [userContent?.rights])





    useEffect(() => {
        if (!insidePane) {
            if (supervisor_id) {
                setEditID(supervisor_id)
            } else {
                if (window.location.pathname !== "/user/create") {
                    setShowDetailPage(false)
                }
                setEditID(null);
            }
        }
    }, [supervisor_id, window.location.pathname])


    const onSave = async (e) => {
        e.preventDefault();

        if (!(detailData?.name)) {
            setHasErr(true);
            showError(t("Please enter a valid name"));
            return;
        }
        if (!isValidEmail(detailData?.email)) {
            setHasErr(true);
            showError(t("Please enter a valid email"));
            return;
        }
        if (!detailData?.supervisorExtn?.roles?.length) {
            setHasErr(true);
            showError(t("Please select roles"));
            return;
        }

        setLoading(true);
        try {
            let supervisorDetailData = { ...detailData };
            if (!supervisorDetailData?.phoneNo?.phone) {
                delete supervisorDetailData.phoneNo;
            }

            if (!supervisorDetailData?.name) {
                delete supervisorDetailData.salutation;
            }

            if (editID) {
                await Supervisorservice.edit({ data: supervisorDetailData, corp_id });
                setLoading(false);
                showMessage(t("User updated successfully."));
                await fetchData(filterURL);
                if (!insidePane) navigate(`/user/edit/${editID}`);
            } else {
                if (corp_id) supervisorDetailData = { ...supervisorDetailData, adminOf: corp_id }
                const supervisor = await Supervisorservice?.save({
                    data: supervisorDetailData, corp_id: corp_id
                });
                setLoading(false);
                if (supervisor) setEditID(supervisor)
                setLoading(false);
                showMessage(t("User saved successfully."));
                await fetchData(filterURL);
                if (!insidePane) navigate(`/user/edit/${supervisor}`);
            }
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
        setHasErr(false)

    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);

            try {
                await Supervisorservice.delete(id, corp_id);
                setLoading(false);

                setShowDetailPage(false);
                showMessage(t("User Deleted Successfully"), t("Deleted"));
                if (!corp_id) {
                    navigate(`/user`);
                }
            } catch (e) {
                showError(e);
                setLoading(false);

            }
        }
    };


    const renderLastCol = useCallback((supervisor) => {
        return (
            <>
                {userContent?.rights?.includes(2302) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/user/edit/${supervisor?._id}`);
                            setEditID(supervisor?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(2303) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, supervisor?._id)}
                    />
                    : null}

                {!multiMode && insidePane && !dontShowCheck ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(supervisor?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])

    if (!userContent?.rights?.includes(2300)) {
        return <div key={userContent?.rights} > <PagesNoRights /></div>
    }


    return (
        <React.Fragment key={userContent?.rights}>
            <Layout
                medium
                hideAdd={!userContent?.rights?.includes(2301)}
                showDetailPage={showDetailPage}
                backDetailPage={() => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/user");
                    setEditID(null);
                }}
                title={t("Supervisors")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/user/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={Supervisorservice.page}
                rows={Supervisorservice.rows}
                total={Supervisorservice.total}
                fetch={Supervisorservice.fetch}
                hideTitleBar={hideTitleBar}
                hideSearch={hideFilterBar}
            >

                <Layout.Table>
                    <DataGrid
                        gridLoading={loading}
                        data={Supervisorservice.records}
                        total={Supervisorservice.total}
                        uiPreference="Supervisor.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={Supervisorservice.page}
                        rowsPerPage={Supervisorservice.rowsPerPage}
                        onPaginationChange={Supervisorservice.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {detailData?._id ? t("Edit User") : t("Add User")}
                </Layout.DetailPageTitle>
                <Layout.DetailPageBody>
                    <EditSupervisors
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        corp_id={corp_id}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    <div className="d-flex justify-content-between align-items-center">


                        {detailData?._id ? (
                            <AuditLogButton title= "Audit Logs - Users" module="Supervisor" id={detailData?._id} />
                        ) : null}


                        {editID && userContent?.rights?.includes(2303) ? (
                            <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
                        ) : null}
                        {(userContent?.rights?.includes(2302) || userContent?.rights?.includes(2301)) ?
                            <SaveButton
                                loading={loading}
                                onClick={onSave}
                            />
                            : null}

                    </div>

                </Layout.DetailPageFooter>
            </Layout>
        </React.Fragment>
    );
};
export default observer(Supervisors);
