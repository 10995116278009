import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import Audits from './Audit';
import AuditLogService from './AuditLogService';
import "./style.css";
import { Tab } from ".."
import EmailLogForApplication from './EmailLogForApplication';
import ApiLog from './ApiLog';

function AuditLogs({ description, title, modules = null, module = null, id = null, asLabel = false }) {
    const [step, setStep] = useState(1);


    return (
        <div className="h-100 w-100 App pb-5">
            <div className="d-flex justify-content-between align-items-center mb-2 ">
                <div className='fs-4 fw-600'>
                    <div>{title ?? "-"}</div>
                    <div className='font-size-12 text-primary fw-600'>{description ?? "-"}</div>
                </div>

                <div className='ms-auto p-2' style={{ cursor: "pointer" }} onClick={AuditLogService.toggleModal}>
                    <RxCross2 size={20} />
                </div>
            </div>

            <div class="parent-container">
                {(Array.isArray(modules) && modules.length >= 1) ?
                    <Tab
                        vertical={false}
                        left
                        activeTab={step}
                        onChange={(data) => {
                            setStep(data);
                        }}
                        className="d-flex"
                        outerClassName="mt-0 pt-0 ms-4"
                    >
                        {modules.map((module, index) => {
                            if(module?.key == "EMAIL_LOG") {
                                return (
                                    <div num={index + 1} label={(module?.title)}>
                                        <EmailLogForApplication value={module?.value} />
                                    </div>
                                )
                            } else if (module?.key == "API_LOG") {
                                return (
                                    <div num={index + 1} label={(module?.title)}>
                                        <ApiLog refId={module?.value} />
                                    </div>
                                )
                            }
                            return (
                                <div num={index + 1} label={(module?.title)}>
                                    <Audits module={module?.key} ids={module?.value} />
                                </div>
                            )
                        })}
                    </Tab> :
                    <div className="h-100">

                        <Audits module={module} ids={id} />
                    </div>
                }
            </div>
        </div>
    )
}

export default observer(AuditLogs)