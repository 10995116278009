import React from 'react';
import { AiOutlineLock } from "react-icons/ai";
import { FaCheck, FaTimes } from 'react-icons/fa';
import './StatusSquares.scss';

const StatusProgressSquares = ({
  data = [],
  isAccountCreated,
  isQueued,
  isDeclined,
}) => {
  const getStatusClass = (status) => {
    switch (status) {
      case 1: return 'submitted';
      case 2: return 'askForMore';
      case 3: return 'approved';
      case 4: return 'rejected';
      default: return 'incomplete';
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        {data.map((step, index) => (
          <div className='position-relative'>
            <div className={`${isAccountCreated ? "zr_status_square" : "zr_status_square"} ${getStatusClass(step.status)}`} />
            {isAccountCreated ? <FaCheck size={14} style={{ position: "absolute", top: 3, left: 3 }} /> : null}
            {isDeclined ? <FaTimes size={14} style={{ position: "absolute", top: 3, left: 3 }} /> : null}
            {isQueued ? <AiOutlineLock size={15} style={{ position: "absolute", top: 3, left: 3 }} /> : null}
          </div>
        ))}
      </div>

    
    </div>

  );
};

export default StatusProgressSquares;
