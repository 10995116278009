import React, { useState, useEffect } from 'react';
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { useTranslation } from '../../../../store/context/TranslationContext';

export default function CurrencyField({
    className,
    label = "Amount",
    value: initialValue,
    onChange,
    isDisabled,
    notifyParent,
    showErr,
    required,
    err,
}) {
    const MAX_VALUE = 2147483647;
    const [value, setValue] = useState(initialValue || '');
    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);
    const { t } = useTranslation()
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        setError(err);
        setHasError(showErr);
    }, [err, showErr]);

    const validateCurrency = (currencyValue) => {
        const numericValue = parseInt(currencyValue, 10);
        return numericValue <= MAX_VALUE;
    };

    const handleChange = (newValue) => {
        const numericValue = parseInt(newValue, 10);
        if (numericValue > MAX_VALUE) {
            setError(`Value cannot exceed ${MAX_VALUE}`);
            setHasError(true);
            notifyParent(false);
            return;
        }

        setValue(newValue);
        onChange(newValue);
        setError(null);
        setHasError(false);
    };

    const maskGenerator = createDefaultMaskGenerator('9999999999');

    return (
        <div className={`${className} ${(error && hasError) ? 'error' : ''}`}>
            <div className='inputfield__container'>
                <div className='inputfield__innercontainer_outline'>
                    <Label label={t(label)} required={required} />
                    <div className={`inputfield px-0`} style={{ margin: "0px 0px 5px 0px", height: "50px" }}>
                        <MaskedInput
                            className="inputfield__input"
                            maskGenerator={maskGenerator}
                            value={value}
                            onChange={handleChange}
                            placeholder="Enter amount"
                            disabled={isDisabled}
                            style={{
                                height: "50px",
                                borderRadius: "15px",
                                paddingLeft: "15px",
                                border: "none",
                                fontSize: "14px",
                                outline: "none",
                                backgroundColor: "#F1FAFC",
                                width: "100%",
                            }}
                        />
                    </div>
                </div>
            </div>
            {(error?.length && hasError) ? <em className="selectfield__error">{t(error)}</em> : null}
        </div>
    );
}

const Label = ({ label, required }) => {
    return (
        <label
            style={{
                minWidth: "120px",
                fontSize: 16,
                margin: "0px 0px 5px 5px",
                fontWeight: "500",
                textAlign: "left",
                display: 'block',
                marginBottom: '5px',
                color: "#495057"
            }}>
            {label ?? "Currency"}
            {required && <font color="red">*</font>}
        </label>
    );
};
