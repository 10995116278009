import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import AuditLogButton from "../../components/Audit/AuditLogButton";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import PagesNoRights from "../Utility/pages-no-rights";
import EditFormQuestions from "./EditFormQuestions";
import { STRUCTURE } from "./FormQuestionsConstant";
import { FormQuestionsGridColumns } from "./FormQuestionsGridColumns";
import FormQuestionsService from "./FormQuestionsService";
import "./style.css";

const FormQuestions = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { qedit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(qedit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/questions/create" || qedit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await FormQuestionsService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(2400)) {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }
  }, [userContent?.rights])


  useEffect(() => {
      if (qedit_id) {
        setEditID(qedit_id)
      } else {
        if (window.location.pathname !== "/questions/create") {
          setShowDetailPage(false)
        }
        setEditID(null);
      }
  }, [qedit_id, window.location.pathname])


  const onSave = async (e) => {
    e.preventDefault();

    if (!(detailData?.formType?.length)) {
      setHasErr(true);
      showError(t("Please select Form Type"));
      return;
    }

    if (!(detailData?.inputType?.length)) {
      setHasErr(true);
      showError(t("Please select Field Type"));
      return;
    }

    if (!(detailData?.step?.length)) {
      setHasErr(true);
      showError(t("Please select step."));
      return;
    }

    if (!(detailData?.title)) {
      setHasErr(true);
      showError(t("Please enter Question Label."));
      return;
    }

    if (detailData?.inputType === "DROPDOWN" && !detailData?.options?.length) {
      setHasErr(true);
      showError(t("Please enter Options for dropdown field type"));
      return;
    }

    if (detailData?.inputType === "DROPDOWN") {
      const index = detailData?.options?.map(option => option?.trim())?.findIndex(v => (v === '' || v === null || v === undefined || !v?.length));
      if (index !== -1) {
        setHasErr(true);
        showError(`Please enter Option ${index + 1}`);
        return;
      }
    }

    const trimmedData = {
      ...detailData,
      formType: detailData?.formType?.trim(),
      inputType: detailData?.inputType?.trim(),
      step: detailData?.step?.trim(),
      title: detailData?.title?.trim(),
      options: detailData?.options?.map(option => option?.trim()),  // Trim each option in the array
    };

    setLoading(true);
    try {
      if (editID) {
        await FormQuestionsService.edit(trimmedData, editID);
        setLoading(false);
        showMessage(t("Question updated successfully."));
      } else {
        const question = await FormQuestionsService.save(trimmedData);
        setLoading(false);
        showMessage(t("Question saved successfully."));
        if (!insidePane) navigate(`/questions/edit/${question}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await FormQuestionsService.delete(id);
        setLoading(false);
        showMessage("Question Deleted SuccessFully", "Deleted");
        navigate("/questions");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((question) => {
    return (
      <>
        {userContent?.rights?.includes(2402) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/questions/edit/${question?._id}`);
              setEditID(question?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(2403) ?
          <DeleteLink
            onClick={(event) => onDelete(event, question?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(question?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])


  if (!userContent?.rights?.includes(2400)) {
    return <div key={userContent?.rights} > <PagesNoRights /></div>
  }


  return (
    <React.Fragment key={userContent?.rights}>
      <Layout
        hideAdd={!userContent?.rights?.includes(2401)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/questions");
          setEditID(null);
        }}
        title={t("Questions")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/questions/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={FormQuestionsService.page}
        rows={FormQuestionsService.rows}
        total={FormQuestionsService.total}
        fetch={FormQuestionsService.fetch}
      >

        <Layout.Table>
          <DataGrid
            data={FormQuestionsService.records}
            total={FormQuestionsService.total}
            uiPreference="questions.grid"
            headers={FormQuestionsGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            gridLoading={loading}
            selectedIDs={selectedIDs}
            page={FormQuestionsService.page}
            rowsPerPage={FormQuestionsService.rowsPerPage}
            onPaginationChange={FormQuestionsService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit questions") : t("Add Question")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditFormQuestions
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <div className="d-flex justify-content-between align-items-center">

            {detailData?._id ? (
              <AuditLogButton title="Audit Logs - Questions" module="VerificationAsk" id={detailData?._id} />
            ) : null
            }
          {editID && userContent?.rights?.includes(2403) && (detailData?._id) ?
            <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
          : null}

          {userContent?.rights?.includes(2402) || userContent?.rights?.includes(2401) ?
            <SaveButton onClick={onSave} loading={loading} />
            : null}

            </div >


        </Layout.DetailPageFooter>
      </Layout>



    </React.Fragment>
  );
};

export default observer(FormQuestions);


