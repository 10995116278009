import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { Card, CardBody, Container } from 'reactstrap';
import { doGET } from '../../../../util/HttpUtil';
import { InfoPair, InfoPairColumn } from '../ondato/Ondato';
import { getParchaFilteredData } from './helper';
import ProofOfAddressDisplay from './components/ProofOfAddressDisplay';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaRedo } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { DialogContext } from '../../../../store/context/DialogContext';
import Skeleton from 'react-loading-skeleton';

export const StatusBadge = ({ passed }) => {
  const styleConfig = {
    true: { backgroundColor: '#d4edda', color: '#155724' },
    false: { backgroundColor: '#f8d7da', color: '#721c24' },
    default: { backgroundColor: '#e2e3e5', color: '#383d41' }
  };

  const { backgroundColor, color } = styleConfig?.[passed] || styleConfig?.default;

  return (
    <div style={{
      display: "inline-block",
      borderRadius: 5,
      padding: "6px 8px",
      fontSize: "13px",
      backgroundColor,
      color,
      textAlign: "center"
    }}>
      {_.startCase(passed ? "Passed" : "Failed")}
    </div>
  );
};


const Refresh = ({ onClick, uniqueId }) => {
  const tooltipId = _.uniqueId(`tooltip-${uniqueId}-`);

  return (
    <>
      <FaRedo
        id={tooltipId}
        size={20}
        className='me-2'
        style={{ cursor: 'pointer', outline: "none" }}
        onClick={onClick}
        title="Refresh Data"
      />
      <Tooltip
        style={{ zIndex: '200000' }}
        anchorSelect={`#${tooltipId}`}
      >
        Refresh from Parcha
      </Tooltip>
    </>
  );
};


const CompletePoa = ({ onRefresh, poaData, onChange }) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(DialogContext);

  const updateData = useCallback(() => {
    if (poaData) {
      const proofOfAddressMessage = getParchaFilteredData(poaData)
      setData(proofOfAddressMessage);
      onChange(proofOfAddressMessage)
    }
  }, [poaData]);

  useEffect(() => {
    updateData();
  }, [updateData])

  const refreshParchaData = async () => {
    if (poaData?._id) {
      setLoading(true)
      try {
        const response = await doGET(`/api/application-doc/fresh?id=${poaData?._id}`)
        if (response?.status == 200) {
          onRefresh(response?.data)
        }
      } catch (error) {
        showError(error)
      } finally {
        setLoading(false)
      }
    } else {
      showError("Document data not found")
    }
  }

  if (loading) return <div>
    <Skeleton count={3} height={10} />
  </div>
  if (poaData?.internalStatus === "error") return <Card>
    <CardBody>
      <InfoPair label="Status" value={<StatusBadge passed={false} />} />
    </CardBody>
  </Card>


  if (!data?.thirdPartyId) return <h4> <b>Proof Of Address is not performed yet</b></h4>

  return (
    <div>
      <div className='d-flex justify-content-between '>
        <div style={{ textDecoration: "underline" }}>
          <a
            href={data?.redirectionLink}
            target="_blank"
            style={{ color: '#007bff' }}
          >
            Proof Of Address
          </a>
        </div>
        <Refresh
          onClick={refreshParchaData}
          uniqueId={data?.thirdPartyId}
        />
      </div>

      {data?.event == "error" ?
        <ProofOfAddressError poa={data} /> :
        data?.toShowCompletedData ?
          <ProofOfAddressDisplay poa={data} />
          : null}
    </div>
  );
}
const ProofOfAddressError = ({ poa }) => {
  return (
    <div>
      <Card>
        <CardBody>
          <InfoPair applyValidation label="Error Type" value={poa?.errorType} />
          <InfoPairColumn applyValidation label="Error Message" value={poa?.errorDisplay} />
        </CardBody>
      </Card>
    </div>
  );
};



const ProofOfAddress = ({ formId, refreshId, applicationDocId }) => {
  const [poaList, setPoaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posStatuses, setPoaStatuses] = useState([])

  const fetchPOAStatus = useCallback(async () => {
    setLoading(true);
    try {

      const poaGridResponse = await doGET(`/api/application-doc/status?id=${applicationDocId}&refresh=true`);
      if (poaGridResponse?.status === 200 && poaGridResponse?.data) {
        setPoaList([poaGridResponse?.data ?? {}]);
      }
    } catch (error) {
      console.error('Failed to fetch pao grid data:', error);
    } finally {
      setLoading(false);
    }
  }, [formId, refreshId, applicationDocId]);

  useEffect(() => {
    fetchPOAStatus();
  }, [formId, refreshId, applicationDocId]);

  if (loading) return <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-1  fv_ondato_rule">
    <FaSpinner size={14} className="spinner" />
  </div>

  return (
    <Container className='mx-0 px-0' fluid>

      {
        <Accordion defaultActiveKey={``}>
          {(poaList ?? []).map((poa, index) => (
            <Accordion.Item eventKey={`${index}`} key={index}>
              <Accordion.Header>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                  <div>
                    {poa?.seq}.   {poa?.input_payload?.self_attested_data?.first_name}  {poa?.input_payload?.self_attested_data?.middle_name}  {poa?.input_payload?.self_attested_data?.last_name}
                  </div>
                  <div className='me-2'>
                    {posStatuses?.statusDisplay}
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <CompletePoa
                  poaData={poa}
                  onChange={(v) => {
                    setPoaStatuses(v)
                  }}
                  onRefresh={(v) => {
                    setPoaList([v?? {}]);
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      }
    </Container>
  );
};

export default ProofOfAddress;