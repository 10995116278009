import React from "react";
import { BiSolidBell, BiSolidBellOff } from "react-icons/bi";
import { FaExternalLinkAlt, FaRedo } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { generateRandomString } from "../../../../components";
import { applicationStatuses } from "../../thirdParty/applicationDocument/ApplicationDocument";
export const Refresh = ({ onClick }) => {
    const tooltipId = generateRandomString(15)
    return <>
        <Tooltip
            style={{
                zIndex: '200000',
            }}
            anchorSelect={`#${tooltipId}`}
        >
            Refresh Application
        </Tooltip>
        <div id={tooltipId}>
            <Button
                type="button"
                onClick={onClick}
                color="primary"
                className={"btn mx-1 btn-primary "}
                style={{ padding: "3px" }}
            >
                <FaRedo
                    size={14}
                    color="#fff"
                    title="Refresh Data"
                />
            </Button>
        </div>
    </>
}

export const MarkActionableButton = ({ onClick }) => {
    const tooltipId = generateRandomString(15)
    return <>
        <Tooltip
            anchorSelect={`#${tooltipId}`}
        >
            Mark Actionable
        </Tooltip>
        <div id={tooltipId}>
            <Button
                type="button"
                onClick={onClick}
                color="secondary"
                className={"btn mx-1 btn-primary "}
                style={{ padding: "3px" }}
            >
                <BiSolidBellOff
                    size={16}
                    color="#fff"
                    title="Mark Actionable"
                />
            </Button>
        </div>
    </>
}

export const MarkNonActionableButton = ({ onClick }) => {
    const tooltipId = generateRandomString(15)
    return <>
        <Tooltip
            anchorSelect={`#${tooltipId}`}
        >
            Mark Non-Actionable
        </Tooltip>
        <div id={tooltipId}>
            <Button
                type="button"
                onClick={onClick}
                color="primary"
                className={"btn mx-1 btn-primary "}
                style={{ padding: "3px" }}
            >
                <BiSolidBell
                    size={16}
                    color="#fff"
                    title="Mark Non-Actionable"
                />
            </Button>
        </div>
    </>
}

export const RedirectToLink = ({ onClick }) => {
    const tooltipId = generateRandomString(15)

    return (
        <>
            <Tooltip
                style={{
                    zIndex: '200000',
                }}
                anchorSelect={`#${tooltipId}`}
            >
                View Created Account
            </Tooltip>
            <div id={tooltipId}>
                <Button
                    type="button"
                    onClick={onClick}
                    color="primary"
                    className="btn mx-1 btn-primary"
                    style={{ padding: "3px" }}
                >
                    <FaExternalLinkAlt
                        size={16}
                        color="#fff"
                        title="Go to Link"
                    />
                </Button>
            </div>
        </>
    );
}

export const StatusContent = ({
    hideIcons,
    value,
    iconClassName = '',
    labelClassName = '',
    iconStyle = {},
    labelStyle = {},
}) => (
    <>
        {!hideIcons && (
            <div
                className={iconClassName}
                style={{ color: value?.keyColor, fontSize: 15, marginRight: 4, ...iconStyle }}
            >
                {value?.icon}
            </div>
        )}
        <div
            className={labelClassName}
            style={{ color: value?.keyColor, fontSize: 12, ...labelStyle }}
        >
            {value?.label}
        </div>
    </>
);

export const StatusDisplayDropDown = ({ rights, hideIcons, style, value, onChange }) => {
    const isDropdown = [0, 2, 4].includes(value?.value) ? rights?.includes(2807) : [6].includes(value?.value) ? rights?.includes(2808) : false
    const dropdownOptions = applicationStatuses.filter(
        (status) => [0, 2, 4].includes(value?.value) ? status.value === 8 : [6].includes(value?.value) ? status.value === 9 : false
    );

    // Common styles and classes
    const containerStyle = {
        backgroundColor: value?.backgroundColor,
        padding: isDropdown ? '2px 10px' : '2px 6px',
        border: isDropdown ? '0px' : undefined,
        ...(style ?? {}),
    };
    const containerClassName = 'd-flex align-items-center justify-content-center rounded-2';

    // Common content using StatusContent component
    const content = (
        <StatusContent
            hideIcons={hideIcons}
            value={value}
            iconClassName={isDropdown ? 'pb-1 font-size-15' : 'pb-1'}
            labelClassName={isDropdown ? 'pb-0 font-size-12 me-1' : 'pb-0'}
        />
    );

    if (isDropdown) {
        return (
            <UncontrolledDropdown className="m-0 me-1">
                <DropdownToggle
                    caret
                    style={containerStyle}
                    className={`m-0 ${containerClassName}`}
                >
                    {content}
                </DropdownToggle>
                <DropdownMenu>
                    {dropdownOptions.map((item) => (
                        <DropdownItem key={item.value} onClick={() => onChange(item)}>
                            <div className="d-flex align-items-center">
                                <StatusContent
                                    hideIcons={false}
                                    value={item}
                                    iconClassName="me-2"
                                    labelClassName="font-size-12"
                                />
                            </div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    } else {
        return (
            <div style={containerStyle} className={containerClassName}>
                {content}
            </div>
        );
    }
};