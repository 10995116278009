import React from 'react';
import Logo from "../../assets/images/Logo.svg"
import './LoadingScreen.scss'; // Link to your SCSS file

const LoadingScreen = () => {
  return (
    <div className="fv_bank_loading_screen_container">
      <div className="fv_bank_loading_screen_logo">
        <img src={Logo} />
      </div>
      <div className="fv_bank_loading_screen_spinner"></div>
      <div className="fv_bank_loading_screen_message">
        We are setting up your application...
      </div>
    </div>
  );
};

export default LoadingScreen;
