import React from 'react';
import { Row } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { CountrySelect, InputField, SelectField, SelectFieldMultiple } from '../../../../../components';
import AntDateSelect from '../../../../../components/DateSelects/AntDateSelect';
import PhoneNumWithValidation from '../../../../../components/PhoneNum/PhoneNumWithValidation';
import UserNameField from '../../../components/userName/UserNameInputField';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { addressDesc } from '../../constants';
import Entity from './Entity';
import { QuestionRadioGroupMultiple } from './Page6';
import { InputLabel } from './ProofOfDocument';
import User from './User';


const BTN = ({ onClick, label }) => {
    const btnStyle = {
        backgroundColor: '#fff',
        borderRadius: "10px",
        border: "1px solid #1678AE",
        color: '#1678AE'
    }
    return <div><Button className='mb-3 mt-1' style={btnStyle} onClick={onClick}>{label}</Button></div>
}

const Page2 = ({
    asLabel,
    data,
    handleChange,
    setData,
    hasError,
    handleChangeViaObject,
    handleValidation,
    validationkeyValue,
    isValidUsername,
    setIsValidUsername,
    loading,
    setLoading,
    setCentralErrors,
    centralErrors
}) => {

    const AddEntity = () => {
        setData(prevItems => {
            const newItems = [...(prevItems?.entities || []), {}]
            return { ...prevItems, entities: newItems }
        });
    };
    const AddUser = () => {
        setData(prevItems => {
            const newItems = [...(prevItems?.individuals || []), { isAuthorizedUser: true, isShareHolder: false, }]
            return { ...prevItems, individuals: newItems }
        });
    }
    const AddShareholder = () => {
        setData(prevItems => {
            const newItems = [...(prevItems?.individuals || []), { isShareHolder: true, isAuthorizedUser: false }]
            return { ...prevItems, individuals: newItems }
        });
    };

    const removeEntitiy = (index) => {
        setData(prevItems => {
            const newItems = [...(prevItems?.entities || [])];
            newItems.splice(index, 1);
            return { ...prevItems, entities: newItems }
        });
    };
    const removeUser = (index) => {
        setData(prevItems => {
            const newItems = [...(prevItems?.individuals || [])];
            newItems.splice(index, 1);
            return { ...prevItems, individuals: newItems }
        });
    };
    const AddAddress = () => {
        setData(prevItems => {
            const newItems = [...(prevItems?.addressList || []), {}]
            return { ...prevItems, addressList: newItems }
        });
    }

    const removeAddress = (index) => {
        setData(prevItems => {
            const newItems = [...(prevItems?.addressList || [])];
            newItems.splice(index, 1);
            return { ...prevItems, addressList: newItems }
        });
    };


    return (
        <div>
            <p className='bold-text pt-2'>In order to apply for Plus account, we will need to review some prequalifications. Please answer the following questions:</p>

            <h1 className='pt-3'>General Business Information</h1>
            <Row>
                <InputField
                    label={"Business Legal Name"}
                    placeholder={"Enter Business Legal Name"}
                    value={data?.Business_Name}
                    onChange={(v) => { handleChange("Business_Name", v) }}
                    error={handleValidation("Business_Name", data?.Business_Name)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    required={validationkeyValue["Business_Name"]?.required}

                />
            </Row>
            <Row>
                <InputField
                    label={validationkeyValue["Doing_Business_As"]?.label}
                    placeholder={validationkeyValue["Doing_Business_As"]?.placeholder}
                    value={data?.Doing_Business_As}
                    onChange={(v) => { handleChange("Doing_Business_As", v) }}
                    error={handleValidation("Doing_Business_As", data?.Doing_Business_As)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    required={validationkeyValue["Doing_Business_As"]?.required}
                />
            </Row>

            <InputLabel required={validationkeyValue["address.buildingNumber"]?.required} label={"Address of Principal Place of Business (e.g. operating address)"} labelStyle={{ fontSize: 15 }} />
            <p className='ps-1 mb-0' style={{ fontSize: 11, fontStyle: 'italic' }}>This is the address you operate your business at, not a registered address. Please do not enter P.O. Boxes, they will not be accepted.</p>

            <Row>
                <InputField
                    placeholder="Building and Number"
                    value={data?.address?.buildingNumber}
                    onChange={(v) => handleChange(`address.buildingNumber`, v)}
                    error={handleValidation('address.buildingNumber', data?.address?.buildingNumber)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                    desc={(addressDesc["buildingNumber"])}
                />
            </Row>
            <Row>
                <InputField
                    placeholder="Street name"
                    value={data?.address?.street}
                    onChange={(v) => handleChange(`address.street`, v)}
                    error={handleValidation('address.street', data?.address?.street)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                    desc={(addressDesc["street"])}

                />
            </Row>
            <Row className='mb-3'>
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="City"
                    value={data?.address?.city}
                    onChange={(v) => handleChange(`address.city`, v)}
                    error={handleValidation('address.city', data?.address?.city)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                    desc={(addressDesc["city"])}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="State / Province / Region"
                    value={data?.address?.state}
                    onChange={(v) => handleChange(`address.state`, v)}
                    error={handleValidation('address.state', data?.address?.state)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                    maxLength={40}
                    desc={(addressDesc["state"])}
                />
                <InputField
                    className="col-sm-6 col-md-3"
                    placeholder="Postal / Zip Code"
                    value={data?.address?.pinCode}
                    onChange={(v) => handleChange(`address.pinCode`, v)}
                    error={handleValidation('address.pinCode', data?.address?.pinCode)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    type="text"
                    desc={(addressDesc["pinCode"])}
                />
                <CountrySelect
                    value={data?.address?.country}
                    onChange={(v) => handleChange(`address.country`, v?.value)}
                    error={handleValidation('address.country', data?.address?.country)}
                    showErr={hasError}
                    noLabel
                    isoCode
                    placeholder={"Select Country"}
                    className="col-sm-6 col-md-3 mt-3"
                    isDisabled={asLabel}
                    desc={(addressDesc["country"])}
                />
            </Row>

            {data?.addressList?.map((address, index) => {
                return <div className='box-tab position-relative' key={index}>

                    <div className="position-absolute end-0">
                        <div className='me-2' onClick={() => removeAddress(index)}>
                            {(!asLabel) && <FaTimes size={22} color='red' />}
                        </div>
                    </div>

                    <SelectField
                        data={validationkeyValue["addressList[].type"]?.options}
                        value={address?.type}
                        onChange={(v) => handleChange(`addressList[${index}].type`, v?.value)}
                        error={handleValidation('addressList[].type', address?.type)}
                        showErr={hasError}
                        label={validationkeyValue["addressList[].type"]?.label}
                        placeholder="Address Type"
                        isDisabled={asLabel}
                        required={validationkeyValue["addressList[].type"]?.required}

                    />

                    <Row>
                        <InputField
                            placeholder="Building and Number"
                            value={address?.buildingNumber}
                            onChange={(v) => handleChange(`addressList[${index}].buildingNumber`, v)}
                            type="text"
                            isDisabled={asLabel}
                            error={handleValidation('addressList[].buildingNumber', address?.buildingNumber)}
                            showErr={hasError}
                            maxLength={100}
                            desc={(addressDesc["buildingNumber"])}
                        />
                    </Row>
                    <Row>
                        <InputField
                            placeholder="Street name"
                            value={address?.street}
                            onChange={(v) => handleChange(`addressList[${index}].street`, v)}
                            error={handleValidation('addressList[].street', address?.street)}
                            showErr={hasError}
                            type="text"
                            isDisabled={asLabel}
                            maxLength={100}
                            desc={(addressDesc["street"])}
                        />
                    </Row>
                    <Row>
                        <InputField
                            className="col-sm-6 col-md-3"
                            placeholder="City"
                            value={address?.city}
                            onChange={(v) => handleChange(`addressList[${index}].city`, v)}
                            type="text"
                            isDisabled={asLabel}
                            error={handleValidation('addressList[].city', address?.city)}
                            showErr={hasError}
                            maxLength={100}
                            desc={(addressDesc["city"])}

                        />
                        <InputField
                            className="col-sm-6 col-md-3"
                            placeholder="State / Province / Region"
                            value={address?.state}
                            onChange={(v) => handleChange(`addressList[${index}].state`, v)}
                            type="text"
                            isDisabled={asLabel}
                            error={handleValidation('addressList[].state', address?.state)}
                            showErr={hasError}
                            maxLength={100}
                            desc={(addressDesc["state"])}
                        // error={handleValidation('state', address?.state)}
                        // showErr={hasError}
                        // required
                        />
                        <InputField
                            className="col-sm-6 col-md-3"
                            placeholder="Postal / Zip Code"
                            value={address?.pinCode}
                            onChange={(v) => handleChange(`addressList[${index}].pinCode`, v)}
                            isDisabled={asLabel}
                            error={handleValidation('addressList[].pinCode', address?.pinCode)}
                            showErr={hasError}
                            type="text"
                            maxLength={100}
                            desc={(addressDesc["pinCode"])}
                        />
                        <CountrySelect
                            value={address?.country}
                            onChange={(v) => handleChange(`addressList[${index}].country`, v?.value)}
                            noLabel
                            isoCode
                            placeholder={"Select Country"}
                            className="col-sm-6 col-md-3 mt-3"
                            isDisabled={asLabel}
                            error={handleValidation('addressList[].country', address?.country)}
                            showErr={hasError}
                            desc={(addressDesc["country"])}
                        />
                    </Row>
                </div>
            })}

            {
                asLabel || data?.addressList?.length >= 2 ? null :
                    <div className='d-flex gap-2'>
                        <BTN onClick={AddAddress} label="Add Additional Address" />
                    </div>
            }

            <SelectField
                data={validationkeyValue["Business_Registration_Country"]?.options}
                value={data?.Business_Registration_Country}
                onChange={(v) => handleChange('Business_Registration_Country', v?.value)}
                error={handleValidation('Business_Registration_Country', data?.Business_Registration_Country, data)}
                showErr={hasError}
                label={validationkeyValue["Business_Registration_Country"]?.label}
                placeholder="Select Country"
                isDisabled={asLabel}
                required={validationkeyValue["Business_Registration_Country"]?.required}
            />

            {(data?.Business_Registration_Country && (data?.Business_Registration_Country === "US")) ?
                <InputField
                    value={data?.EIN}
                    label={"Employer Identification Number(EIN)"}
                    placeholder="Employer Identification Number(EIN)"
                    onChange={(v) => { handleChange("EIN", v) }}
                    type="text"
                    isDisabled={asLabel}
                    showErr={hasError}
                    error={handleValidation('EIN', data?.EIN, data)}
                    required={validationkeyValue["EIN"]?.showIf?.required}
                />
                : null}

            {(data?.Business_Registration_Country && (data?.Business_Registration_Country != "US")) ?
                <InputField
                    value={data?.Business_Tax_ID}
                    label={validationkeyValue["Business_Tax_ID"]?.label}
                    placeholder={validationkeyValue["Business_Tax_ID"]?.placeholder}
                    onChange={(v) => { handleChange("Business_Tax_ID", v) }}
                    type="text"
                    error={handleValidation('Business_Tax_ID', data?.Business_Tax_ID)}
                    required={validationkeyValue["Business_Tax_ID"]?.showIf?.required}
                    isDisabled={asLabel}
                    showErr={hasError}
                />
                : null}

            <PhoneNumWithValidation
                group="Businesses_Lite"
                className='my-2'
                label="Mobile Phone"
                form_id={data?._id}
                value={{
                    phone: data?.mobilePhone,
                    countryCode: data?.mobileCountryCode
                }}
                onChange={(v) => {
                    handleChangeViaObject({
                        mobilePhone: v?.phone,
                        mobileCountryCode: v?.countryCode
                    })
                }}
                isDisabled={asLabel}
                notifyParent={() => { }}
                onError={(v) => {
                    setCentralErrors((prevErrors) => ({
                        ...prevErrors,
                        [`mobilePhone`]: v,
                    }));
                }}
                required={validationkeyValue["mobilePhone"]?.required}
                err={handleValidation('mobilePhone', data?.mobilePhone)}
                showErr={hasError}
            />

            <div className='mt-4'>
                <PhoneNumWithValidation
                    group="Businesses_Lite"
                    label="Landline"
                    placeholder='Landline'
                    type="landLine"
                    form_id={data?._id}
                    value={{
                        phone: data?.landlinePhone,
                        countryCode: data?.landlineCountryCode
                    }}
                    onChange={(v) => {
                        handleChangeViaObject({
                            landlinePhone: v?.phone,
                            landlineCountryCode: v?.countryCode
                        })
                    }}
                    isDisabled={asLabel}
                    notifyParent={() => { }}
                    onError={(v) => {
                        setCentralErrors((prevErrors) => ({
                            ...prevErrors,
                            [`landlinePhone`]: v,
                        }));
                    }}
                    required={validationkeyValue["landlinePhone"]?.required}
                    err={handleValidation('landlinePhone', data?.landlinePhone)}
                    showErr={hasError}
                />
            </div>


            <InputField
                placeholder=""
                value={data?.email}
                onChange={() => { }}
                type="email"
                label={("E-mail")}
                isDisabled
                className="mt-4"
                required
            />

            <UserNameField
                isValidUsername={isValidUsername}
                setIsValidUsername={setIsValidUsername}
                loading={loading}
                handleLoading={setLoading}
                value={data?.cyclosUserName}
                applicationId={data?._id}
                onChange={(v) => handleChange(`cyclosUserName`, v)}
                email={data?.email}
                required
                formType={"BUSINESS_PLUS"}
                className="col-sm-12 mt-0"
                placeholder=""
                isDisabled={asLabel}
                showErr={hasError}
                err={handleValidation('cyclosUserName', data?.cyclosUserName)}
            />

            <div className='ms-1'>
                <QuestionRadioGroupMultiple
                    field={"entityType"}
                    question={validationkeyValue["entityType"]?.label}
                    data={data}
                    isDisabled={asLabel}
                    handleChange={handleChange}
                    style={{ fontSize: 15 }}
                    options={validationkeyValue["entityType"]?.options}
                    hasError={hasError}
                    showErr={hasError}
                    error={handleValidation("entityType", data?.entityType)}
                    required={validationkeyValue["entityType"]?.required}
                />
            </div>


            <InputField
                value={data?.Business_Registration_Number}
                label="Business Registration or License Number"
                placeholder="Enter Business Registration or License Number"
                className="col-sm-6"
                isDisabled={asLabel}
                labelStlye={{ fontSize: 15 }}
                onChange={(v) => handleChange('Business_Registration_Number', v)}
                error={handleValidation('Business_Registration_Number', data?.Business_Registration_Number)}
                showErr={hasError}
                type="text"
                required={validationkeyValue["Business_Registration_Number"]?.required}
            />
            <AntDateSelect
                placeholder={"dd/mm/yyyy"}
                className="col-sm-6"
                format="dd/mm/yyyy"
                label={validationkeyValue["Business_Formation_Date"]?.label}
                value={data?.Business_Formation_Date}
                onChange={(v) => handleChange('Business_Formation_Date', v)}
                error={handleValidation('Business_Formation_Date', data?.Business_Formation_Date)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Business_Formation_Date"]?.required}
                maxDate={new Date()}
            />



            <QuestionRadioGroup
                field={"inOperation"}
                style={{ fontSize: 15 }}
                question="Has the company started operations?"
                data={data}
                handleChange={handleChange}
                isDisabled={asLabel}
                error={handleValidation('inOperation', data?.inOperation)}
                required={validationkeyValue["inOperation"]?.required}

                showErr={hasError}
                hasError={hasError}
            />



            {data?.inOperation && <AntDateSelect
                placeholder={"dd/mm/yyyy"}
                className="col-sm-6"
                format="dd/mm/yyyy"
                label="Date when the company started operations"
                value={data?.operationStartDate}
                onChange={(v) => handleChange('operationStartDate', v)}
                error={handleValidation('operationStartDate', data?.operationStartDate)}
                showErr={hasError}
                isDisabled={asLabel}
                maxDate={new Date()}
                required={validationkeyValue["operationStartDate"]?.showIf?.required}
            />}
            {!data?.inOperation && <AntDateSelect
                placeholder={"dd/mm/yyyy"}
                className="col-sm-6"
                format="dd/mm/yyyy"
                label="Estimated date of start of the operations"
                value={data?.operateEstDate}
                onChange={(v) => handleChange('operateEstDate', v)}
                error={handleValidation('operateEstDate', data?.operateEstDate)}
                showErr={hasError}
                isDisabled={asLabel}
                minDate={new Date()}
                required={validationkeyValue["operateEstDate"]?.showIf?.required}

            />}



            <SelectField
                labelStyle={{ fontSize: 15 }}
                className=""
                value={data?.Business_Industry_Type}
                onChange={(v) => handleChange('Business_Industry_Type', v.value)}
                error={handleValidation('Business_Industry_Type', data?.Business_Industry_Type)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Business_Industry_Type"]?.required}
                data={validationkeyValue["Business_Industry_Type"]?.options}
                label={validationkeyValue["Business_Industry_Type"]?.label}
                placeholder={validationkeyValue["Business_Industry_Type"]?.placeholder}

            />

            <QuestionRadioGroup
                field={"website"}
                style={{ fontSize: 15 }}
                question="Does the company have a website?"
                data={data}
                handleChange={handleChange}
                isDisabled={asLabel}
                error={handleValidation('website', data?.website)}
                showErr={hasError}
                hasError={hasError}
                required={validationkeyValue["website"]?.required}
            // hasError={hasError}

            />




            {data?.website && <InputField
                value={data?.url}
                label={"Website URL"}
                placeholder="example.com"
                onChange={(v) => { handleChange("url", v) }}
                type="text"
                isDisabled={asLabel}
                error={handleValidation('url', data?.url)}
                showErr={hasError}
                required={validationkeyValue["url"]?.showIf?.required}

            />}

            {!data?.website && <InputField
                value={data?.engage}
                label={"If website unavailable, how do you engage with your clients?"}
                placeholder="how do you engage with your clients?"
                onChange={(v) => { handleChange("engage", v) }}
                type="text"
                showErr={hasError}
                isDisabled={asLabel}
                error={handleValidation("engage", data?.engage)}
                required={validationkeyValue["engage"]?.showIf?.required}
            />}

            <SelectField
                labelStyle={{ fontSize: 15 }}
                className=""
                value={data?.empCount}
                onChange={(v) => { handleChange("empCount", v?.value) }}
                isDisabled={asLabel}
                error={!data?.empCount ? "Please Enter Employee Count" : ""}
                showErr={hasError}
                required={validationkeyValue["empCount"]?.required}
                data={validationkeyValue["empCount"]?.options}
                label={validationkeyValue["empCount"]?.label}
                placeholder={validationkeyValue["empCount"]?.placeholder}
            />


            {/* ================================================================== */}

            <div>
                <QuestionRadioGroup
                    field={"Bank_Account_Another_Financial_Institution"}
                    style={{ fontSize: 15 }}
                    question={validationkeyValue["Bank_Account_Another_Financial_Institution"]?.label}
                    data={data}
                    handleChange={handleChange}
                    isDisabled={asLabel}
                    defaultValue={false}
                    showErr={hasError}
                    hasError={hasError}
                    required={validationkeyValue["Bank_Account_Another_Financial_Institution"]?.required}
                />
                {data?.Bank_Account_Another_Financial_Institution && <InputField
                    label={"If yes, please provide the name and country of the financial institution."}
                    placeholder="Please provide the name and country of the financial institution"
                    value={data?.additionalBankAccountInstName}
                    onChange={(v) => handleChange('additionalBankAccountInstName', v)}
                    type="text"
                    isDisabled={asLabel || !data?.Bank_Account_Another_Financial_Institution}

                    required={validationkeyValue["additionalBankAccountInstName"]?.showIf?.required}
                    error={handleValidation('additionalBankAccountInstName', data?.additionalBankAccountInstName)}
                    showErr={hasError}

                />}
            </div>

            <div>
                <QuestionRadioGroup
                    field={"penalty"}
                    style={{ fontSize: 15 }}
                    question="Has the Applicant, its owners or principals, been subject to regulatory investigation, penalty or enforcement in the last 5 years?"
                    data={data}
                    handleChange={handleChange}
                    isDisabled={asLabel}
                    defaultValue={false}
                    hasError={hasError}
                    required={validationkeyValue["penalty"]?.required}

                />
                {data?.penalty && <InputField
                    label={'Explain you answer to "yes" above.'}
                    placeholder="Explain you answer to yes above"
                    value={data?.penaltyDetails}
                    onChange={(v) => handleChange('penaltyDetails', v)}
                    type="text"
                    isDisabled={asLabel || !data?.penalty}

                    error={handleValidation('penaltyDetails', data?.penaltyDetails)}
                    showErr={hasError}
                    required={validationkeyValue["penaltyDetails"]?.showIf?.required}
                />}
            </div>

            <div>
                <QuestionRadioGroup
                    field={"litigation"}
                    style={{ fontSize: 15 }}
                    question="Has the Company been involved in any litigation in the past or is there any pending or threatened litigation?"
                    data={data}
                    handleChange={handleChange}
                    isDisabled={asLabel}
                    defaultValue={false}
                    hasError={hasError}
                    error={handleValidation('litigation', data?.litigation)}
                />
                {data?.litigation && <InputField
                    label={'Explain you answer to "yes" above.'}
                    placeholder="Explain you answer to yes above"
                    value={data?.litigationDetails}
                    onChange={(v) => handleChange('litigationDetails', v)}
                    type="text"
                    isDisabled={asLabel || !data?.litigation}

                    error={handleValidation('litigationDetails', data?.litigationDetails)}
                    showErr={hasError}
                    required={validationkeyValue["litigationDetails"]?.showIf?.required}
                />}
            </div>

            <div>
                <SelectFieldMultiple
                    className="my-3"
                    labelStyle={{ fontSize: 15 }}
                    data={validationkeyValue["BUS_Source_Of_Funds"]?.options}
                    label={validationkeyValue["BUS_Source_Of_Funds"]?.label}
                    placeholder={validationkeyValue["BUS_Source_Of_Funds"]?.placeholder}
                    value={data?.BUS_Source_Of_Funds ? validationkeyValue?.BUS_Source_Of_Funds?.options?.filter(c => data?.BUS_Source_Of_Funds?.includes(c?.value)) : ""}
                    onChange={(v) => handleChange('BUS_Source_Of_Funds', v?.map(v => v?.value))}
                    error={handleValidation("BUS_Source_Of_Funds", data?.BUS_Source_Of_Funds)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required={validationkeyValue["BUS_Source_Of_Funds"]?.required}
                />

            </div>

            <div>
                <QuestionRadioGroup
                    field={"liquidFunds"}
                    style={{ fontSize: 15 }}
                    question="Do you intend on receiving funds from liquidation of your own digital assets?"
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation("liquidFunds", data?.liquidFunds)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    // defaultValue={false}
                    showErr={hasError}
                    required={validationkeyValue["liquidFunds"]?.required}
                />

                <QuestionRadioGroup
                    field={"hasEntity"}
                    style={{ fontSize: 15 }}
                    question={validationkeyValue["hasEntity"]?.label}
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation("hasEntity", data?.hasEntity)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    // defaultValue={false}
                    showErr={hasError}
                    required={validationkeyValue["hasEntity"]?.required}
                />
            </div>

            {/* ======================= entities ======================= */}
            {
                data?.hasEntity ? (data?.entities ?? [{}])?.map((_, i) => {
                    return (
                        <div className='box-tab' key={i}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div style={{ fontWeight: 'bold', fontSize: 18 }}>Entity {i + 1}</div>
                                <div className='me-2' onClick={() => removeEntitiy(i)}>
                                    {(data?.entities?.length > 1 && !asLabel) && <FaTimes size={22} color='red' />}
                                </div>
                            </div>
                            <Entity
                                index={i}
                                asLabel={asLabel}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                        </div>
                    )
                }) : <></>
            }
            {(asLabel || !data?.hasEntity) ? null : <BTN onClick={AddEntity} label="Add Entity" />}

            {/* ======================= Users ======================= */}
            <div>
                <h2>Officers or Authorized Account Users</h2>
                In this section, list all directors and officers in your organization. Officers include Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Director or other persons that exert control over the business. You may also list persons who are not controllers but whom you request have informational access to your account for operational purposes.
                <h2 className='mt-4'>Enter User information, click Add User below to add more users</h2>
            </div>
            {
                (data?.individuals ?? [{}])?.map((_, i) => {
                    return (
                        <div className='box-tab' key={i}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div style={{ fontWeight: 'bold', fontSize: 18 }}>{i == 0 ? `Primary User` : ` User ${i + 1}`}</div>
                                <div className='me-2' onClick={() => removeUser(i)}>
                                    {(data?.individuals?.length > 1 && !asLabel && i != 0) && <FaTimes size={22} color='red' />}
                                </div>
                            </div>
                            <User
                                index={i}
                                asLabel={asLabel}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                                handleChangeViaObject={handleChangeViaObject}
                                setCentralErrors={setCentralErrors}
                                centralErrors={centralErrors}
                            />
                        </div>
                    )
                })
            }
            {asLabel ? null :
                <div className='d-flex gap-2'>
                    <BTN onClick={AddUser} label="Add Officer/Authorized User" />
                    <BTN onClick={AddShareholder} label="Add Shareholder" />
                </div>}


            {/* ======================= shareholder  =======================*/}
            {/* <div>
                <h2>Shareholders</h2>
                <h2 className='mt-4'>Enter each individual shareholder controlling 10% or more of the entity.</h2>
            </div>
            {
                (data?.individuals ?? [{}])?.map((_, i) => {
                    return (
                        <div className='box-tab' key={i}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div style={{ fontWeight: 'bold', fontSize: 18 }}>Individual Shareholder {i + 1}</div>
                                <div className='me-2' onClick={() => removeShareholder(i)}>
                                    {(data?.individuals?.length > 1 && !asLabel) && <FaTimes size={22} color='red' />}
                                </div>
                            </div>
                            <Shareholder
                                index={i}
                                asLabel={asLabel}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}

                            />
                        </div>
                    )
                })
            }
            {asLabel ? null : <BTN onClick={AddShareholder} label="Add Individual Shareholder" />} */}

        </div>
    )
}

export default Page2;