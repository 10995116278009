import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';
import './style.css';
import { doGET } from '../../util/HttpUtil';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaEye } from 'react-icons/fa';

const TableHead = () => {
  return (
    <thead>
      <tr>
        <th style={{ minWidth: "140px" }}>To</th>
        <th>Message Type</th>
        <th>Subject</th>
      </tr>
    </thead>
  );
};

const EmailLogForApplication = ({ value }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailContent, setEmailContent] = useState('');

  const fetchEmailLogs = async () => {
    setLoading(true);
    try {
      let refIdQuery = `/api/email/grid?rows=-1&ref_id=${(value?.ref_id)}`;
      let response = await doGET(refIdQuery);
      let fetchedData = response.data?.rows ?? [];

      // If no data is returned from the ref_id query, proceed with the secondary query
      if (fetchedData.length === 0) {
        let secondaryQuery = `/api/email/grid?rows=-1&to=in[${(value?.emails ?? [])?.map(v => encodeURIComponent(v)).join(',')}]`;

        // Append additional query parameters based on status
        if (value?.status === 8) {
          secondaryQuery += `&createdAt=btw[${(value?.createdAt)},${(value?.updatedAt)}]`;
        } else {
          secondaryQuery += `&createdAt=gt[${(value?.createdAt)}]`;
        }

        // Fetch again with the constructed query
        response = await doGET(secondaryQuery);
        fetchedData = response.data?.rows ?? [];
      }

      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching email log data:", error);
    } finally {
      setLoading(false);
    }
  };


  const fetchEmailContent = async (log) => {
    try {

      let emailBody = log?.body ?? '';

      // Remove footer
      let parser = new DOMParser();
      let doc = parser.parseFromString(emailBody, "text/html");
      let footer = doc.querySelector(".footer");
      if (footer) footer.remove();

      setEmailContent(doc.body.innerHTML); // Set the content without footer
    } catch (error) {
      console.error("Error fetching email content:", error);
    }
  };

  const toggleModal = (log) => {
    if (log) {
      setSelectedEmail(log);
      fetchEmailContent(log);
    }
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if ((value?.emails??[])?.length) {
      fetchEmailLogs();
    }
  }, [value?.emails]);

  if (loading) {
    return (
      <div className="auditTableFixHead mb-3">
        <table bordered responsive>
          <TableHead />
          <tbody>
            <tr>
              <td><Skeleton count={10} height={30} className="mb-2" /></td>
              <td><Skeleton count={10} height={30} className="mb-2" /></td>
              <td><Skeleton count={10} height={30} className="mb-2" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="auditTableFixHead mb-3">
      <table bordered responsive>
        <TableHead />
        <tbody>
          {data.length > 0 ? (
            data.map((log, index) => (
              <tr onClick={(e) => { e.preventDefault(); toggleModal(log); }} key={index}>
                {/* To Column */}
                <td>
                  {log?.to?.[0] ?? "N/A"}
                  <br />
                  <span className='font-size-10'>{log?.sentAt ? moment.unix(log.sentAt).format('LLL') : "N/A"}</span>
                </td>

                {/* Message Type Column */}
                <td>
                  {log?.messageType ?? "N/A"}
                </td>

                {/* Subject Column */}
                <td>
                  <a href="#">
                    {log?.subject ?? "N/A"} <FaEye size={18} className='ms-2'/>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ height: "100px" }}>
                <h2 className="fs-5 d-flex justify-content-center align-items-center pe-5">No Email Logs Found</h2>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for email content */}
      <Modal isOpen={modalOpen} toggle={() => toggleModal(null)} centered size="lg">
        <ModalHeader toggle={() => toggleModal(null)}>
          {selectedEmail?.subject ?? 'Email Content'}
        </ModalHeader>
        <ModalBody>
   
            <div dangerouslySetInnerHTML={{ __html: emailContent }} />
      
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmailLogForApplication;
