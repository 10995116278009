import React, { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../../../store/context/DialogContext';
import { doPOST } from '../../../../util/HttpUtil';
import "./styles.css";
import { useTranslation } from '../../../../store/context/TranslationContext';

const groupForFormType = {
    INDIVIDUAL_LITE: 'Individuals_Lite',
    BUSINESS_LITE: 'Businesses_Lite',
    INDIVIDUAL_PLUS: 'Individuals_Lite',
    BUSINESS_PLUS: 'Businesses_Lite',
    INIDIVIDUAL_PRIME: 'Individuals',
    BUSINESS_PRIME: 'Businesses'
};

const MIN_USERNAME_LENGTH = 8;

const UserNameField = ({
    applicationId,
    email,
    formType,
    label,
    required,
    className,
    style,
    onChange,
    err,
    loading,
    showErr,
    value,
    handleLoading,
    isValidUsername,
    setIsValidUsername,
    isDisabled = false,
}) => {

    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const { showError, showMessage } = useContext(DialogContext);
    const { t, } = useTranslation();

    useEffect(() => {
        if ((showErr && err?.length) && !error?.length) {
            setError(err);
        }
    }, [err, error, showErr]);

    useEffect(() => {
        setUsername(value);
    }, [value]);

    useEffect(() => {
        if (username?.length >= MIN_USERNAME_LENGTH && (isValidUsername !== false && isValidUsername !== true)) {
            handleUsernameBlur({ isFocused });
        }
    }, [username]);

    useEffect(() => {
        setHasError(showErr);
    }, [showErr]);

    const checkUsernameAvailability = async (usernameToCheck) => {
        try {
            const response = await doPOST('/api/username/get', {
                username: usernameToCheck,
                email: email,
                group: groupForFormType[formType],
                applicationId
            });
            return response?.data ?? [];
        } catch (error) {
            showError(error);
            return [];
        }
    };

    const handleUsernameBlur = async ({ isFocused }) => {
        if (isFocused) {
            return;
        }

        if (!username) {
            setHasError(true);
            setError(t('Username cannot be empty.'));
            onChange(username);
            setIsValidUsername(false);
            return;
        }

        if (username?.length < MIN_USERNAME_LENGTH) {
            setHasError(true);
            setError(t(`Username must be at least ${MIN_USERNAME_LENGTH} characters long.`));
            onChange(username);
            setIsValidUsername(false);
            return;
        }

        handleLoading(true);
        const response = await checkUsernameAvailability(username);
        if (response?.length > 0) {
            setError('');
            setHasError(false);
            onChange(username);
            setIsValidUsername(true);
        } else {
            setHasError(true);
            setError(t('Username is not available.'));
            setIsValidUsername(false);
            onChange(username);
        }
        handleLoading(false);
    };

    const handleUsernameChange = (e) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9_.]/g, '');
        setUsername(sanitizedValue);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        handleUsernameBlur({ isFocused: false });
    };

    return (
        <div className={`${className} py-3`} style={{ fontSize: '14px', ...style }}>
            <label htmlFor="username"
                style={{
                    minWidth: "120px",
                    fontSize: 16,
                    margin: "0px 0px 5px 5px",
                    fontWeight: "500",
                    textAlign: "left",
                    display: 'block',
                    marginBottom: '5px'
                }}>
                {t(label ?? "Username")}
                {required && <font color="red">*</font>}
            </label>

            <input
                id="username"
                type="text"
                value={username}
                disabled={isDisabled}
                maxLength={16}
                onChange={handleUsernameChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={t('Username')}
                className="inputfield flex-1 user-name-input-field"
            />

            {loading ? <em className="selectfield__info">{t("Validating Username..")}.</em> : null}
            {(error?.length && hasError && !loading) ? <em className="selectfield__error">{t(error)}</em> :
                !username?.length && hasError && !loading ? <em className="selectfield__error">({t("Username is empty")})</em> :
                    (!loading && !isFocused && isValidUsername && username?.length) ? <em className="selectfield__message">{t("Username is available")}</em> : null}
        </div>
    );
};

export default UserNameField;
