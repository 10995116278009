import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Card, CardBody, Col } from 'reactstrap';
import { doPOST } from '../../../util/HttpUtil';

export const ApplicationStatusCard = ({ status, actionable, title }) => {
  const [customerCount, setCustomerCount] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCustomers = async (currentDay) => {
    if (loading) return;
    setLoading(true);
    try {
      let body = {
        inFilters: {
          status
        },
        groupBy: {
          status: "status",
        }
      }
      if (actionable !== undefined && actionable !== null) {
        body.inFilters.actionable = actionable;
      }

      const response = await await doPOST(`/api/application-form/statistics`, body);
      const customersLoggedIn = response?.data?.reduce((acc, item) => acc + item.count, 0);
      setCustomerCount(customersLoggedIn)
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentDay = parseInt(moment().format('YYYYMMDD'));
    fetchCustomers(currentDay);
  }, [])

  return (
    <React.Fragment>
      <Card className='mb-1  mb-md-2 flex-1' style={{ height: "90px" }}>
        <CardBody>
          <div className='d-flex flex-row flex-md-column h-100 justfy-content-between align-items-center'>
            <h5 className="text-center d-block d-md-none text-muted mb-0"><b>{title}</b></h5>
            <h3 className="d-flex justify-content-end justify-content-md-center mb-1 mt-1 flex-1">
              <CountUp
                end={customerCount}
                separator=","
                prefix=""
                suffix=""
                decimals={0}
              />
            </h3>
            <h5 className="text-center d-none d-md-block text-muted mb-0"><b>{title}</b></h5>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
};

