import React from 'react';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';

const Page3 = ({ asLabel, data, handleChange, hasError, handleValidation, validationkeyValue }) => {

    return (
        <div>
            <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>About your business</h2>

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.Primary_Business_Activities}
                onChange={(v) => { handleChange("Primary_Business_Activities", v) }}
                error={handleValidation("Primary_Business_Activities", data?.Primary_Business_Activities)}
                showErr={hasError}
                label={validationkeyValue["Primary_Business_Activities"]?.label}
                className="mb-2 mt-3"
                isDisabled={asLabel}
                required={validationkeyValue["Primary_Business_Activities"]?.required}
            />

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                label="Describe your company’s current products and services."
                value={data?.Business_Product_Services}
                onChange={(v) => { handleChange("Business_Product_Services", v) }}
                error={handleValidation("Business_Product_Services", data?.Business_Product_Services)}
                showErr={hasError}
                type="text"
                className="my-2"
                isDisabled={asLabel}
                required={validationkeyValue["Business_Product_Services"]?.required}
            />

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.Customer_Description}
                onChange={(v) => { handleChange("Customer_Description", v) }}
                error={handleValidation("Customer_Description", data?.Customer_Description)}
                showErr={hasError}
                type="text"
                label={validationkeyValue["Customer_Description"]?.label}
                className="mt-3"
                isDisabled={asLabel}
                required={validationkeyValue["Customer_Description"]?.required}
            />

            <QuestionRadioGroup
                style={{ fontSize: 15 }}
                field={"Business_Plan_Disclosure"}
                question={validationkeyValue["Business_Plan_Disclosure"]?.label}
                data={data}
                handleChange={handleChange}
                hasError={hasError}
                isDisabled={asLabel}
                error={handleValidation("Business_Plan_Disclosure", data?.Business_Plan_Disclosure)}
                // showErr={hasError}
                showErr={hasError}
                required={validationkeyValue["Business_Plan_Disclosure"]?.required}
            />
            <CheckBoxFieldMutiple
                // className="my-3"
                labelStyle={{ fontSize: 15 }}
                label="Is your business involved in any of the following (check all that apply)"
                data={validationkeyValue["Illegal_Immoral_Disclosure"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                placeholder="Select"
                value={data.Illegal_Immoral_Disclosure?data.Illegal_Immoral_Disclosure:[]}
                onChange={(v) => handleChange('Illegal_Immoral_Disclosure', v)}
                error={handleValidation('Illegal_Immoral_Disclosure', data.Illegal_Immoral_Disclosure)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Illegal_Immoral_Disclosure"]?.required}


            />

            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Is your business registered in any of these countries?"
                data={validationkeyValue["regCountries"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                placeholder="Select"
                value={data.regCountries?data.regCountries:[]}
                onChange={(v) => handleChange('regCountries', v)}
                error={handleValidation('regCountries', data.regCountries)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["regCountries"]?.required}

            />
    

            <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Expected Bank Account Activity</h2>
            <CheckBoxFieldMutiple
                className="my-1 col-sm-12 d-flex"
                labelStyle={{ fontSize: 15 }}
                label="Select all reasons you will keep receiving deposits into your account"
                data={validationkeyValue["Deposit_Reasons"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                placeholder="Select"
                value={data.Deposit_Reasons?data.Deposit_Reasons:[]}
                onChange={(v) => handleChange('Deposit_Reasons', v)}
                error={handleValidation('Deposit_Reasons', data.Deposit_Reasons)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Deposit_Reasons"]?.required}
                desc={"Check All that apply"}

            />
    
            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Select all reasons you will be making payments from your account"
                data={validationkeyValue["Payment_Reasons"]?.options?.map((v, index) => {return {...( v ?? {}), key : index}})}
                placeholder="Select"
                value={data.Payment_Reasons?data.Payment_Reasons:[]}
                onChange={(v) => handleChange('Payment_Reasons', v)}
                error={handleValidation('Payment_Reasons', data.Payment_Reasons)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Payment_Reasons"]?.required}
                desc={"Check All that apply"}


            />
            
            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label={validationkeyValue["Deposit_Locations"]?.label}
                data={validationkeyValue["Deposit_Locations"]?.options}
                placeholder="Select"
                value={data.Deposit_Locations ? data.Deposit_Locations : []}
                onChange={(v) => handleChange('Deposit_Locations', v)}
                error={handleValidation('Deposit_Locations', data.Deposit_Locations)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Deposit_Locations"]?.required}
                desc={"Check All that apply"}
            />
       
            <CheckBoxFieldMutiple
                labelStyle={{ fontSize: 15 }}
                label="Locations you intend on making payments to"
                data={validationkeyValue["Payment_Locations"]?.options}
                placeholder="Select"
                value={data?.Payment_Locations ? data?.Payment_Locations : []}
                onChange={(v) => handleChange('Payment_Locations', v)}
                error={handleValidation('Payment_Locations', data.Payment_Locations)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Payment_Locations"]?.required}
                desc = {"Check All that apply"}
            />
            
        </div>
    )
}

export default Page3;