import { makeAutoObservable } from "mobx";

import UserGuideService from "../HorizontalLayout/UserGuide/UserGuideService";
import DownloadService from "../Download/DownloadService";


class Service {
    isVisible = false;
    modules = []
    module = null;
    id = null;
    title = "";
    description = "";
    constructor() {
        makeAutoObservable(this);
    }

    closeModal = () => {
        this.isVisible = false
        this.modules = []
        this.module = null;
        this.id = null;
    }

    toggleModal = ({ description, title, modules, module, id}) => {
        if (!this.isVisible) {
            if (modules?.length) { this.modules = modules }
            if (module) { this.module = module }
            if (title) { this.title = title }
            if (id) { this.id = id }
            if (description) { this.description = description } else { this.description = "" }
            DownloadService.closeModal()
            UserGuideService.closeModal()
            this.isVisible = true
        } else {
            this.closeModal()
        }
    }
}

const AuditLogService = new Service();
export default (AuditLogService);
