import React from "react";

function ToggleSelector({ tabClassName, data, value, onChange, style, customStyleClass, zeroMargin, compact, disabled = false }) {

    const handleoptionTypeClick = (selectedoptionType) => {
        if (onChange && !disabled) {
            onChange(selectedoptionType); // Call the onChange prop with the selected value
        }
    };

    const optionWidth = () => {
        return "calc(" + (100 / data.length) + "% )";
    }

    return (
        <div
            className={`nav nav-pills  d-flex justify-content-start align-items-center  ${zeroMargin ? "" : "  mt-3 mb-3 "}   ${customStyleClass} `}
            style={{ borderRadius: '6px', border: '1px solid #D5D5D5', padding: '5px', pointerEvents: disabled ? 'none' : 'auto', ...style, }}>
            {data?.map((option, index) => (
                <span
                    key={index}
                    className={`nav-item waves-effect waves-light pill-${1}`}
                    role="presentation"
                    style={!compact ? { width: optionWidth() } : {}}
                >
                    <span
                        className={` nav-link ${value === option.value ? "active" : ""}  ${tabClassName}`}
                        data-bs-toggle="tab"
                        role="tab"
                        aria-selected={value === option.value ? "true" : "false"}
                        onClick={() => {
                            if (disabled) {
                                return;
                            }
                            handleoptionTypeClick(option.value);
                        }}
                        style={{ borderRadius: "6px", }}
                    >
                        <span className="d-block d-flex flex-wrap justify-content-around align-items-center px-3" >{option.label}</span>
                    </span>
                </span>
            ))}
        </div>
    );
}

export default ToggleSelector;
