export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/utm-tracker/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    gridAll: (page, sizePerPage, filterUrl) => {
        let url = `/api/utm-tracker/grid?rows=-1`;
        return url;
    },
    get: (id) => `/api/utm-tracker/detail?id=${id}`,
    save: `/api/utm-tracker/create`,
    delete: (id) => `/api/utm-tracker/delete?id=${id}`,
    bulkDelete: (id) => `/api/utm-tracker/delete`,
    update: `/api/utm-tracker/update`,
};

export const STRUCTURE = [
    {
        label: "Introducer Name",
        filterLabel: "Introducer Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "Referrer Code",
        filterLabel: "Referrer Code",
        filterName: "utm_referrer",
        type: "text",
    },
];
