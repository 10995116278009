import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { RiDownloadCloudFill } from "react-icons/ri"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import ToggleSidebar from '../../assets/images/icons/ToggleSidebar.svg'
import userGuide from '../../assets/images/icons/user-guide.png'
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import { UserContext } from '../../store/context/UserContext'
import DownloadService from '../Download/DownloadService'
import { menuItems } from "./Constant"
import UserGuideService from './UserGuide/UserGuideService'
import "./layout.scss"

const Header = props => {

  const {  currentPageName, setCurrentPageName } = useContext(UserContext);
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const { userContent } = useContext(UserContext);

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const currentSection = menuItems.find(section =>
      section.items.some(item => {
        // Check item path
        if (item?.path === `/${pathSegments[1]}`) {
          setCurrentPageName(item.name);
          return true;
        }

        // If item path not found, check subItems
        if (item?.subItems) {
          const subItem = item.subItems.find(subItem =>
            subItem?.path === `/${pathSegments[1]}`
          );
          if (subItem) {
            setCurrentPageName(subItem.name);
            return true;
          }
        }

        return false;
      })
    );
  }, [location.pathname, menuItems]);


  useEffect(() => {
    setActiveLink(window.location.pathname.split('/')[1])
  }, [window.location.pathname])
  const isSmallScreen = window.innerWidth <= 768;

  const pendingCount = DownloadService.pendingReports.length;
  const allReportsCount = DownloadService.reportsLength;
  const colorClass = pendingCount > 0 ? "bg-danger" : "bg-success";


  return (
  <React.Fragment key={userContent?.rights}>
      <div className="navbar-header d-flex justify-content-between align-items-center mx-0">
        <div className='d-flex col-md-4 col-8'>
          <div className='col-1 px-2 d-flex align-items-center'>
            <img src={ToggleSidebar} onClick={props?.ToggleSidebarMenu} style={{ cursor: "pointer", }} />
          </div>
          <div className='col-10 ms-2 d-flex align-items-center' style={{ fontSize: "18px", color: "black" }}>
            <b>{currentPageName}</b>
          </div>
        </div>
        <div className='d-flex me-0 '>
          <div className='d-flex  align-items-center'>


            <div onClick={UserGuideService.toggleModal} className='cursor-pointer ms-3 d-flex align-items-center justify-content-center pt-1'>
              {<img style={{ height: "22px", marginTop: "0px" }} src={userGuide} alt="user-guide" />}
            </div>

            {userContent?.rights?.includes(2802) ? <div onClick={DownloadService.toggleModal} className='position-relative cursor-pointer ms-3 d-flex align-items-center justify-content-center pt-1'>
              <RiDownloadCloudFill size = {26} />
              <div
                className={`position-absolute     translate-middle ${colorClass}`}
                style={{ top : 3, right : -15, fontSize : "12px", color: "white", borderRadius: "50%", width: "18px", height: "18px", display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                {pendingCount > 0 ? pendingCount : allReportsCount}
              </div>
            </div> : null}
     
        
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default (connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(observer(Header))))
