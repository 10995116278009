import React, { useState } from 'react'
import moment from 'moment'

const MonthPicker = ({ className, label, value, onChange }) => {
    const currentMonth = moment().format('YYYY-MM');
    const handleMonthChange = (v) => {
        const month = moment(v).format('YYYYMM');
        onChange(month);
    }

    return (
        <div>
            {label &&
                <label
                    htmlFor="example-month-input"
                    className={`${className} col-form-label`}
                >
                    {label}
                </label>
            }
            <div className="col-md-10">
                <input
                    className="form-control"
                    type="month"
                    defaultValue={currentMonth}
                    id="example-month-input"
                    onChange={(v) => {
                        handleMonthChange(v?.target?.value);
                    }}
                />
            </div>
        </div >
    )
}

export default MonthPicker
