import { observer } from "mobx-react-lite";
import React from "react";
import DownloadService from "../Download/DownloadService";
import Download from "../Download/Download";
import "./layout.scss";
import "./style.css";
import UserGuideService from "./UserGuide/UserGuideService";
import UserGuide from "./UserGuide";
import AuditLogService from "../Audit/AuditLogService";
import AuditLogs from "../Audit/index"
const CoreModals = () => {
    return (
        <React.Fragment>
            
            <div className="sidebar-right">
                <div className="d-flex h-100 flex-column justify-content-between">
                    <div className="d-flex flex-column">
                        <div className={`${DownloadService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
                            {DownloadService.isVisible ? <Download /> : null}
                        </div>

                        <div className={`${UserGuideService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
                            {UserGuideService.isVisible ? <UserGuide /> : null}
                        </div>

                        <div className={`${AuditLogService?.isVisible ? " map-card " : " map-card map-card-hide "}`}>
                            {AuditLogService.isVisible ? <AuditLogs description={AuditLogService?.description} title={AuditLogService?.title} modules={AuditLogService?.modules} module={AuditLogService?.module} id={AuditLogService?.id}/> : null}
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default observer(CoreModals);
