import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'reactstrap';
import { ModalPopup } from '../../../../components/Modal/ModalPopup';
import { DialogContext } from '../../../../store/context/DialogContext';
import { doPOST, doGET } from '../../../../util/HttpUtil';
import { ThreeBounce } from 'better-react-spinkit';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./styles.css";

const groupForFormType = {
    INDIVIDUAL_LITE: 'Individuals_Lite',
    BUSINESS_LITE: 'Businesses_Lite',
    INDIVIDUAL_PLUS: 'Individuals_Lite',
    BUSINESS_PLUS: 'Businesses_Lite',
    INIDIVIDUAL_PRIME: 'Individuals',
    BUSINESS_PRIME: 'Businesses'
};

const UsernameModal = ({ isOpen, setOpenModal, email, formId, formType, onCreateAccount, userName }) => {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isValidUsername, setIsValidUsername] = useState(false);
    const { showError, showMessage } = useContext(DialogContext);

    useEffect(() => {
        if (userName)  {
            setUsername(userName);
            preCheckUsernameAvailability(userName);
        }
    }, [userName])


    const checkUsernameAvailability = async (usernameToCheck) => {
        try {
            const response = await doPOST('/api/username/get', {
                username: usernameToCheck,
                email: email,
                applicationId: formId,
                group: groupForFormType[formType]
            });
            return response?.data ?? [];
        } catch (error) {
            showError("error", error);
            console.log("error", error);
            return [];
        }
    };

    const preCheckUsernameAvailability = async (usernameToCheck) => {
        if (!(username || usernameToCheck)) {
            setError('Username cannot be empty.');
            setIsValidUsername(false);
            return;
        }
        setLoading(true);
        const response = await checkUsernameAvailability(usernameToCheck ? usernameToCheck : username);
        if (response.length > 0) {
            setError('');
            setIsValidUsername(true);
        } else {
            setError('Username is not available.');
            setIsValidUsername(false);
        }
        setLoading(false);
    };

    const handleUsernameChange = (e) => {
        const inputValue = e.target.value;
        const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9_.]/g, '');
        setUsername(sanitizedValue);
    };

    const closeModalAndReload = () => {
        onCreateAccount();
        setOpenModal(false);
    }

    const createAccount = async () => {
        if (loading || !isValidUsername) return;
        setLoading(true);
        try {
            const response = await doGET(`/api/application-form/account/create?username=${username}&id=${formId}`);
            if (response?.status === 200) {
                showMessage('Account Created Successfully.');
            }
        } catch (error) {
            showError(JSON?.stringify(JSON?.parse(error?.message)?.customMessage));
        } finally {
            setLoading(false);
            closeModalAndReload()
        }
    };

    return (
        <ModalPopup
            isOpen={isOpen}
            setIsOpen={setOpenModal}
            title="Create Account"
            className="d-flex justify-content-center align-items-center"
            modalStyle={{ fontSize: '24px', marginTop: '200px' }}
            body={
                <div className="pb-2" style={{ backgroundColor: '#F1FAFC', width: '650px', borderRadius: '10px' }}>
                    <div className="p-4" style={{ fontSize: '14px' }}>
                        <label htmlFor="username" style={{ display: 'block', marginBottom: '10px' }}>Username</label>
                        {loading ? (
                            <Skeleton height={40} width={600} />
                        ) : (
                            <input
                                id="username"
                                type="text"
                                value={username}
                                disabled
                                onChange={handleUsernameChange}
                                onBlur={(e) => preCheckUsernameAvailability(username)}
                                className="inputfield flex-1 user-create-input"
                                style={{
                                    border: error ? '2px solid red' : isValidUsername ? '2px solid green' : '1px solid #C6F3FF',
                                }}
                            />
                        )}
                        {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
                        {!loading && isValidUsername && <div style={{ color: 'green', marginTop: '10px' }}>Username is available</div>}
                    </div>
                    <div className="d-flex justify-content-end mt-4" style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: '28px' }}>
                        <Button
                            color="primary"
                            onClick={createAccount}
                            disabled={!isValidUsername || loading}
                        >
                            {loading ? <ThreeBounce color="#fff" /> : 'Create Account'}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

export default UsernameModal;
