import _ from 'lodash';
import moment from 'moment';
import AssignSupervisorFromGrid from './components/AssignSupervisorFromGrid/AssignSupervisorFromGrid';
import StatusProgressSquares from './components/StatusProgressSquares';
import { StatusDisplayInvert, applicationStatuses } from './thirdParty/applicationDocument/ApplicationDocument';
import { FaPlus, FaRobot } from 'react-icons/fa';
import SupervisiorAssigner from '../../components/Dropdowns/SupervisiorSelect/SupervisiorAssigner';
import { MdSystemUpdate } from 'react-icons/md';

const formTypeShortForm = {
  "BUSINESS_PLUS": <b className='d-flex align-items-center'>B <FaPlus size={10} className='ms-1' /></b>,
  "INDIVIDUAL_PLUS": <b className='d-flex align-items-center'>I <FaPlus size={10} className='ms-1' /></b>,
  "INIDIVIDUAL_PRIME": <b>IP</b>,
  "BUSINESS_PRIME": <b>BP</b>,
};

const isUnActionable = (dataObj) => {
  if ([0, 6, 8, 9]?.includes(dataObj?.status)) {
    return true
  }
  return dataObj?.actionable == false
}

export const GridColumns = (rights) => {
  const columns = [
    {
      title: "Application No.",
      accessor: "applicationNo",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold text-decoration-underline'}>{dataObj?.applicationNo ?? ""}</div>;
      },
      width: 105,
      show: true,
    },
    {
      title: "Name",
      accessor: "name",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>{dataObj?.customerName ?? ""}</div>;
      },
      show: true,
    },
    {
      title: "Email",
      accessor: "email",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>{dataObj?.email ?? ""}</div>;
      },
      show: true,
      width: 230,
    },
    {
      title: "Type",
      accessor: "applicationType",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>{formTypeShortForm[dataObj?.formType]}</div>;
      },
      show: true,
      width: 50,
    },
    {
      title: "Created At",
      accessor: "createdAt",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>{moment((dataObj?.createdAt) * 1000).format('DD-MM-YYYY, hh:mm a')}</div>;
      },
      show: true,
      width: 130,
    },
    {
      title: "Updated At",
      accessor: "updatedAt",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>{moment((dataObj?.updatedAt ?? dataObj?.createdAt) * 1000).format('DD-MM-YYYY, hh:mm a')}</div>;
      },
      show: false,
      width: 130,
    },
    {
      title: "Introducer Name",
      accessor: "introducer Name",
      renderer: (dataObj) => {
        return <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>{dataObj?.introducer ?? ""}</div>;
      },
      show: true,
      width: 120,
    },
    {
      title: "Aging",
      accessor: "lastApplicantAction",
      renderer: (dataObj) => {
        // Check if `lastApplicantAction` exists
        if (dataObj?.lastApplicantAction) {
          const lastActionTime = moment(dataObj.lastApplicantAction * 1000); // Convert UNIX timestamp to moment object
          const currentTime = moment(); // Get the current time
          const agingInDays = currentTime.diff(lastActionTime, 'days'); // Calculate difference in days

          // Determine the label to show (Today, Yesterday, or X days ago)
          let agingLabel;
          if (agingInDays === 0) {
            agingLabel = "Today";
          } else if (agingInDays === 1) {
            agingLabel = "Yesterday";
          } else {
            agingLabel = `${agingInDays} days ago`;
          }

          // Format the timestamp for tooltip
          const tooltipText = lastActionTime.format('DD-MM-YYYY, hh:mm a');

          return (
            <div className={isUnActionable(dataObj) ? "" : 'fw-bold'} title={tooltipText}>
              {agingLabel}
            </div>
          );
        }

        return <></>; // If no lastApplicantAction, return empty
      },
      show: true,
      width: 130,
    },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return (
          <div className={isUnActionable(dataObj) ? "" : 'fw-bold'}>
            <StatusDisplayInvert
              style={{ padding: "0px 6px", minWidth: "120px" }}
              hideIcons
              value={applicationStatuses?.find((v) => dataObj?.status == v.value)}
              By={dataObj?.statusUpdatedBy ? (
                <div className="me-2">
                  <SupervisiorAssigner
                    value={[dataObj?.statusUpdatedBy]}
                    asLabel
                    small
                  />
                </div>
              ) : (
                // If statusUpdatedBy is null, display the system icon
                  <FaRobot size={18} />
  
              )}
            />
          </div>
        );
      },
      show: true,
      width: 170,
    },
    {
      title: "Steps",
      accessor: "steps",
      renderer: (dataObj) => {
        const steps = dataObj.steps ?? [];
        return (
          <div>
            <StatusProgressSquares
              data={steps}
              isAccountCreated={[6, 9]?.includes(dataObj?.status)}
              isDeclined={[8]?.includes(dataObj?.status)}
              isQueued={dataObj?.status == 5}
            />
          </div>
        );
      },
      show: true,
      width: 140,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 80,
      show: true,
      required: true,
    },
  ];

  // Conditionally append "Assigned To" column if rights include 2300
  if (rights?.includes(2300)) {
    columns.splice(6, 0, {
      title: "Assigned To",
      accessor: "assignedTo",
      show: true,
      renderer: (dataObj) => {
        return <AssignSupervisorFromGrid dataObj={dataObj} />;
      },
      width: 120,
    });
  }

  return {
    version: 1,
    columns: columns,
  };
};
