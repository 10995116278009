import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { InfoPair, InfoPairColumn } from '../../ondato/Ondato';
import { StatusBadge } from '../ProofOfAddress';
import _ from 'lodash';

const ProofOfAddressDisplay = ({ poa }) => {
  return (
    <div>
      <Card>
        <CardBody>
          <InfoPair label="Status" value={<StatusBadge passed={poa?.isPassed} />} />
          <InfoPair label="Status Reason" value={poa?.statusReason} />
          <InfoPairColumn label="Status Explanation" value={poa?.statusExplanation} />
        </CardBody>
      </Card>


      <Card>
        <CardBody>
          <h4><b>Input Data</b></h4>
          <InfoPair label="Full Name" value={poa?.input?.fullName} />
          <InfoPair label="Address" value={Object?.values(poa?.input?.address ?? {})?.filter(v => v && v != "")?.join(", ")} />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <h4><b>Verification Data</b></h4>
          {(poa?.poaVerificationData ?? [])?.map((doc, index) => (
            <div key={index} >
              <Card  >
                <CardBody>
                  <h5>{doc?.documentType}</h5>
                  <InfoPair label="Document Date" value={doc?.documentDate} />
                  <InfoPair label="Name on Document" value={doc?.nameOnDocument} />
                  <InfoPair label="Address on Document" value={Object?.values(doc?.address ?? {})?.filter(Boolean)?.join(', ')} />
                  <InfoPair label="Document Summary" value={doc?.summary} />
                  <InfoPair label="Analysis" value={doc?.analysis} />
                  <InfoPair label="Validity" value={<StatusBadge passed={doc?.isValidDocument} />} />

                </CardBody>
              </Card>
              {doc?.alerts?.length ? <Card  >
                <CardBody>
                  <h5 className='text-danger'>Alerts</h5>
                  {_.map(doc?.alerts ?? [], (alert, index) => (
                    <InfoPair key={index} label={_.startCase(alert?.key ?? "-")} value={alert?.value ?? "-"} />
                  ))}
                </CardBody>
              </Card> : null}
            </div>

          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProofOfAddressDisplay