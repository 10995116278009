import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditReasonCode from "./EditReasonCode";
import { STRUCTURE } from "./ReasonCodeConstant";
import { ReasonCodeGridColumns } from "./ReasonCodeGridColumns";
import ReasonCodeService from "./ReasonCodeService";
import PagesNoRights from "../Utility/pages-no-rights";

const ReasonCode = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
    let { reasonCodeEdit_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(reasonCodeEdit_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/reason-code/create" || reasonCodeEdit_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)


    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await ReasonCodeService.fetch(filterUrl);
            setLoading(false);

        } catch (e) {
            setLoading(false);
            showError(e);
        }
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])


    useEffect(() => {
        if (reasonCodeEdit_id) {
            setEditID(reasonCodeEdit_id)
        } else {
            if (window.location.pathname !== "/reason-code/create") {
                setShowDetailPage(false)
            }
            setEditID(null);
        }
    }, [reasonCodeEdit_id, window.location.pathname])

    const onSave = async (e) => {
        e.preventDefault();

        if (!detailData?.title) {
            setHasErr(true);
            showError(t("Please enter Title"));
            return;
        }

        setLoading(true);
        try {
            if (editID) {
                await ReasonCodeService.edit({...(detailData ?? {}), title : detailData?.title?.trim() }, editID);
                setLoading(false);
                showMessage(t("Reason Code updated successfully."));
            } else {
                const reasonCode = await ReasonCodeService.save({ ...(detailData ?? {}), title: detailData?.title?.trim() });
                setLoading(false);
                showMessage(t("Reason Code saved successfully."));
                if (!insidePane) navigate(`/reason-code/edit/${reasonCode}`);
            }
            fetchData(filterURL);

        } catch (e) {
            setLoading(false);
            showError(e);
        }
        setHasErr(false)
    };
    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await ReasonCodeService.delete(id);
                setLoading(false);
                showMessage("Reason Code Deleted Successfully", "Deleted");
                navigate("/reason-code");
                setShowDetailPage(false);

            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };


    const renderLastCol = useCallback((reasonCode) => {
        return (
            <>
                {userContent?.rights?.includes(3002) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/reason-code/edit/${reasonCode?._id}`);
                            setEditID(reasonCode?._id);
                            setShowDetailPage(true);

                        }}
                    />
                }
                {userContent?.rights?.includes(3003) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, reasonCode?._id)}
                    />
                    : null}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(reasonCode?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])


    if (!userContent?.rights?.includes(3000)) {
        return <div key={userContent?.rights} > <PagesNoRights /></div>
    }


    return (
        <React.Fragment key={userContent?.rights}>
            <Layout
                hideAdd={!userContent?.rights?.includes(3001)}
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/reason-code");
                    setEditID(null);
                }}
                title={t("Reason Code")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/reason-code/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={ReasonCodeService.page}
                rows={ReasonCodeService.rows}
                total={ReasonCodeService.total}
                fetch={ReasonCodeService.fetch}
            >

                <Layout.Table>
                    <DataGrid
                        data={ReasonCodeService.records}
                        total={ReasonCodeService.total}
                        gridLoading={loading}
                        uiPreference="reasonCode.grid"
                        headers={ReasonCodeGridColumns}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        selectedIDs={selectedIDs}
                        page={ReasonCodeService.page}
                        rowsPerPage={ReasonCodeService.rowsPerPage}
                        onPaginationChange={ReasonCodeService.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>


                <Layout.DetailPageTitle>
                    {detailData?._id ? t("Edit Reason Code") : t("Add Reason Code")}
                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditReasonCode
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>

                    {(detailData?._id && userContent?.rights?.includes(3003)) ?
                        <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
                        : null}

                    {(userContent?.rights?.includes(3002) || userContent?.rights?.includes(3001)) ?
                        <SaveButton onClick={onSave} loading={loading} />
                        : null}

                </Layout.DetailPageFooter>
            </Layout>
        </React.Fragment>
    );
};

export default observer(ReasonCode);


