import React from 'react';

export const UTMTrackerGridColumns = {
    version: 1,
    columns: [
        {
            title: "Introducer Name",
            renderer: (dataObj) => {
                return <div>{dataObj?.name}</div>;
            },
            show: true,
        },
        {
            title: "Referrer Code",
            renderer: (dataObj) => {
                return <div>{dataObj?.utm_referrer}</div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
