import { makeAutoObservable } from "mobx";
import toastr from "toastr";
import { toastrOptions } from "../../store/context/DialogContext";
import { doDELETE, doGET } from "../../util/HttpUtil";
import { ENDPOINTS } from './DownloadConstant';
import UserGuideService from "../HorizontalLayout/UserGuide/UserGuideService";
import AuditLogService from "../Audit/AuditLogService";


toastr.options = toastrOptions;


class Service {
    isVisible = false;
    pageTx = 1;
    rowsPerPageTx = 10000000;
    pendingReports = [];
    reportsLength = 0;
    reports = []

    constructor() {
        makeAutoObservable(this);
        this.startPolling()
    }

    closeModal = () => {
        this.isVisible = false
    }
    
    toggleModal = (module) => {
        if(!this.isVisible) {
            UserGuideService.closeModal()
            AuditLogService.closeModal()
        }
        this.isVisible = !this.isVisible;
        this.module = module;
    }

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        this.fetchAllReports()
        return response;
    };

    fetchAllReports = async () => {
        try {
            const response = await doGET(ENDPOINTS.gridAll());
            this.reportsLength = (response?.data?.rows ?? []).length;
            this.reports = response?.data?.rows || [];
            return response?.data?.rows || [];
        } catch (error) {
            console.error('Error fetching reports:', error);
            return [];
        }
    };

    startPolling = () => {
        if (localStorage.getItem("BearerToken")) {
            const poll = async () => {
                const reports = await this.fetchAllReports();

                reports.forEach(report => {
                    if (this.pendingReports.some(r => r._id === report._id && report.status !== 'ENQUEUE' && report.status !== 'IN_PROGRESS')) {
                        if (report.status === 'GENERATED') {
                            toastr.success(`Report - ${report?.applicationNo} Generated Successfully`, 'Generated');
                        } else if (report.status === 'FAILED') {
                            toastr.error(`Report - ${report?.applicationNo} Generation Failed`);
                        }
                        this.pendingReports = this.pendingReports.filter(r => r._id !== report._id);
                    }
                });

                this.pendingReports = reports.filter(report => report.status === 'ENQUEUE' || report.status === 'IN_PROGRESS');

                if (this.pendingReports.length > 0) {
                    setTimeout(poll, 20000);
                }
            };
            poll();
        }
    };
}

const DownloadService = new Service();
export default DownloadService;
