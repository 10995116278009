import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./store/context/UserContext";
import { connect } from "react-redux";
import "./components/SuperResponsiveTableStyle.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "toastr/build/toastr.min.css";
import HorizontalLayout from "./components/HorizontalLayout/";
import { authRoutes, userRoutes } from "./routes/allRoutes";
import "./assets/scss/module.scss";
import "./assets/scss/theme.scss";
import { changeTopbarTheme } from "./store/actions";
import { I18nContext } from "./store/context/i18nContext";
import Pages404 from "./pages/Utility/pages-404";
import LoadingScreen from "./pages/Utility/pages-loader";
import PagesNoRights from "./pages/Utility/pages-no-rights";

const App = (props) => {
  const { isLoggedIn, isAppLoading } = useContext(UserContext);
  const { translations, lang } = useContext(I18nContext);
  const [loggedIn, setLoggedIn] = useState(true);
  function getLayout() {
    let layoutCls = HorizontalLayout;
    changeTopbarTheme("dark");
    return layoutCls;
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authorization = localStorage.getItem("BearerToken");
      if (authorization) {
        setLoggedIn(true);
      } else {
        console.log("Authorization not present");
        setLoggedIn(false);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map(({ path, Component }, key) => (
          <Route path={path} element={<Component />} key={key} />
        ))}
        {userRoutes.map(({ path, Component, forUser }, key) => (
          <Route
            path={path}
            element={
              localStorage.getItem("BearerToken") ? (
                isAppLoading ? (
                  <LoadingScreen />
                ) : isLoggedIn ? (
                  <Layout>
                    <Component />
                  </Layout>
                ) : (
                  <PagesNoRights />
                )
              ) : (
                <Navigate to="/" />
              )
            }
            key={key}
          />
        ))} 
        <Route path="*" element={<Pages404 />} />

      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

// {/* <Switch>
// { authRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={NonAuthLayout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={false}
//   />
// ))}

// { userRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={Layout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={true}
//     exact
//   />
// ))}
// </Switch> */}
