import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const REPORT_STATUS_ICONS = {
    false: <div className='d-flex align-items-center' style={{ color: '#FF6347' }}><FaTimesCircle className='me-1' size={17} /></div>,
    true: <div className='d-flex align-items-center' style={{ color: '#32CD32' }}><FaCheckCircle className='me-1' size={17} /> </div>,
};


export const ReasonCodeGridColumns = {
    version: 1,
    columns: [
        {
            title: "Title",
            renderer: (dataObj) => {
                return <div>{dataObj?.title}</div>;
            },
            show: true,
        },
        {
            title: "Can Re-Apply",
            renderer: (dataObj) => {
                return <div>{REPORT_STATUS_ICONS[dataObj?.allowReapply]}</div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
