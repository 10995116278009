import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Input, Row } from "reactstrap";
import {
    InputField
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import ReasonCodeService from "./ReasonCodeService";

const EditReasonCode = ({ editId, onChange = () => { } }) => {

    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        allowReapply : false
    });

    const fetch = async () => {
        setLoading(true);
        try {
            let form = await ReasonCodeService.get(editId);
            setData({ ...form });
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setData({
            allowReapply: false
        });
        if (editId) {
            fetch();
        } else {
            setLoading(false);
        }
    }, [editId, ReasonCodeService?.version]);

    useEffect(() => {
        onChange(data);
    }, [data]);



    return (
        <React.Fragment>
            {loading ? (
                <ThreeBounce />
            ) : (
                <div className="row px-3" style={{ width: "calc(100% - 5px)" }}>
                    <Row>
                        <InputField
                            value={data?.title}
                            required
                            label="Title"
                            onChange={(v) => {
                                setData({
                                    ...data,
                                    title: v,
                                });
                            }}
                            className="col-12"
                            type="text"
                        />
                    </Row>

                    <Row className="d-flex align-items-center">


                        <div className="d-flex align-items-center col-12 col-sm-4">
                            <Input
                                type="checkbox"
                                checked={data.allowReapply}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        allowReapply: e.target.checked
                                    });
                                }}
                                label="Can Re-Apply?"
                            />
                            <label className="ms-2 mt-2">Can Re-Apply</label>
                        </div>

                    </Row>
                </div>
            )}
        </React.Fragment>
    );
};

export default observer(EditReasonCode);
