import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';
import './style.css';
import { doGET } from '../../util/HttpUtil';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaEye } from 'react-icons/fa';

const formatJson = (json) => {
  try {
    return JSON.stringify(json, null, 2);
  } catch (e) {
    return json;
  }
};

// Function to safely parse the body field if it's a JSON string
const parseBody = (body) => {
  if (typeof body === 'string') {
    try {
      return JSON.parse(body);
    } catch (e) {
      console.error('Error parsing JSON body:', e);
      return body; // Return as is if parsing fails
    }
  }
  return body; // Return as is if it's not a string
};

const TableHead = () => {
  return (
    <thead>
      <tr>
        <th>Request URL</th>
        <th>Time</th>
      </tr>
    </thead>
  );
};

const ApiLog = ({ refId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const fetchApiLogs = async () => {
    setLoading(true);
    try {
      const response = await doGET(`/api/http-log/grid?ref_id=${encodeURIComponent(refId)}&rows=-1`);
      const fetchedData = response.data?.rows ?? [];
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching API log data:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = (log) => {
    if (log) {
      setSelectedLog(log);
    }
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (refId) {
      fetchApiLogs();
    }
  }, [refId]);

  if (loading) {
    return (
      <div className="auditTableFixHead mb-3">
        <table bordered responsive>
          <TableHead />
          <tbody>
            <tr>
              <td><Skeleton count={10} height={30} className="mb-2" /></td>
              <td><Skeleton count={10} height={30} className="mb-2" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="auditTableFixHead mb-3">
      <table bordered responsive>
        <TableHead />
        <tbody>
          {data.length > 0 ? (
            data.map((log, index) => (
              <tr onClick={() => toggleModal(log)} key={index}>
                <td>
                  <a href="#">
                    {log?.request?.url ?? "N/A"}  <FaEye size={18} className='ms-2' />
                  </a>
                </td>
                <td>
                  <b>Request Time : </b> {log?.at ? moment.unix(log.at).format('LLL') : "N/A"} <br />
                  <b>Response Time : </b>{log?.responseAt ? moment.unix(log.responseAt).format('LLL') : "N/A"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ height: "100px" }}>
                <h2 className="fs-5 d-flex justify-content-center align-items-center pe-5">No API Logs Found</h2>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for full log details */}
      <Modal isOpen={modalOpen} toggle={() => toggleModal(null)} centered size="lg">
        <ModalHeader toggle={() => toggleModal(null)}>
          API Log Details
        </ModalHeader>
        <ModalBody className="modal-body-scrollable">
          {modalLoading ? (
            <Skeleton count={5} />
          ) : (
            selectedLog && (
              <div>
                <h5>Request</h5>
                <pre className="json-pretty">
                  {formatJson({
                    ...selectedLog.request,
                    body: parseBody(selectedLog.request?.body) // Parse and display the body separately
                  })}
                </pre>
                <h5>Response</h5>
                <pre className="json-pretty">
                  {formatJson({
                    ...selectedLog.response,
                    body: parseBody(selectedLog.response?.body) // Parse and display the body separately
                  })}
                </pre>
                {selectedLog.ioExceptionTrace && (
                  <>
                    <h5>Exception Trace</h5>
                    <pre className="json-pretty">{selectedLog.ioExceptionTrace}</pre>
                  </>
                )}
              </div>
            )
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ApiLog;
