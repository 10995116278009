import { makeAutoObservable } from "mobx";
import DownloadService from "../../Download/DownloadService";
import AuditLogService from "../../Audit/AuditLogService";


class Service {
    isVisible = false

    constructor() {
        makeAutoObservable(this);
    }

    closeModal = () => {
        this.isVisible = false
    }

    toggleModal = () => {
        if (!this.isVisible) {
            DownloadService.closeModal()
            AuditLogService.closeModal()
        }
        this.isVisible = !this.isVisible
    }
}

const UserGuideService = new Service();
export default (UserGuideService);
