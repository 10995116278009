import _ from 'lodash';

export const DocumentTypeGridColumns = {
  version: 1,
  columns: [

    {
      accessor : "doctype",
      title: "Document Type",
      renderer: (dataObj) => {
        return <div>
         { dataObj?.name}
        </div>
      },
      show: true,
    },

    {
      accessor: "aiAssistance",
      title: "AI Assistance Required",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.isParchaRequired ? "Yes" :  "No")}
        </div>
      },
      show: true,
      // width: 120,
    },
    // {
    //   title: "Key",
    //   renderer: (dataObj) => {
    //     return <div>
    //       {(dataObj?.key)}
    //     </div>
    //   },
    //   show: true,
    //   width: 200,
    // },

    {
      title: "For System",
      accessor: "forSystem",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.modifiable ? "No" : "Yes")}
        </div>
      },
      show: true,
      // width: 90,
    },
    {
      title: "Document For",
      accessor: "documentFor",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.forBusiness ? "Business" : "Individual/UBO")}
        </div>
      },
      show: true,
      // width: 90,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
