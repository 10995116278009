import { toJS } from "mobx";
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SelectField } from '../../SelectField/SelectField';
import { SelectFieldMultiple } from '../../SelectField/SelectFieldMultiple';
import { BottomBarButton, ModalComponent, SearchButton } from '../../index';
import UTMTrackerSelectService from './UTMTrackerSelectService';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';

const UTMTrackerSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label = "Introducer Name",
    showErr,
    outline,
    inline,
    borderNone,
    multi,
    ...props
}) => {
    const [trackers, setTrackers] = useState([]);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);

    const fetchTrackers = async () => {
        try {
            await UTMTrackerSelectService.fetch();
        } catch (error) {
            showError(t("Error"), error);
        }
    };

    useEffect(() => {
        if (UTMTrackerSelectService.trackers.length === 0) fetchTrackers();
    }, []);

    const updateTrackers = () => {
        setTrackers(
            toJS(UTMTrackerSelectService.trackers)
                ?.map((introducer) => ({
                    value: introducer.name,
                    label: <div>{introducer.name} {" "}(<b>{introducer?.utm_referrer}</b>)</div>,
                    utm_referrer: introducer.utm_referrer,
                }))
                .filter(Boolean)
        );
    };

    useEffect(() => {
        updateTrackers();
    }, [UTMTrackerSelectService.trackers]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const filterOption = useCallback((option, inputValue) => {
        return (
            !inputValue ||
            option?.data?.utm_referrer?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
            option?.value?.toLowerCase()?.includes(inputValue?.toLowerCase())
        );
    }, []);

    const renderSelectField = ({ disabled }) => (
        <SelectField
            filterOption={filterOption}
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage UTM Trackers")} />
            )}
            outline={outline}
            className={className}
            onChange={onChange}
            data={trackers}
            required={required}
            value={value}
            label={label || (!isDisabled && t("Introducer Name"))}
            error={!value ? t(`Please Select ${label || "Introducer Name"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            RightComponent={() => <SearchButton onClick={openModal} />}
            {...props}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            filterOption={filterOption}
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage UTM Trackers")} />
            )}
            outline={outline}
            className={className}
            onChange={(selectedValues) =>
                onChange(selectedValues.map((c) => c?.value ?? c))
            }
            data={trackers}
            value={value ? trackers.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "UTM Trackers"}`) : ""}
            required={required}
            label={label || (!isDisabled && t("Select UTM Trackers"))}
            showErr={showErr}
            RightComponent={() => <SearchButton onClick={openModal} />}
        />
    );

    return (
        <>
            {modal && (
                <ModalComponent
                    position="top"
                    size="large"
                    onToggle={closeModal}
                    isOpen={modal}
                >
                    <ModalComponent.Title>{t("Manage UTM Trackers")}</ModalComponent.Title>
                    <ModalComponent.Body>
                        {/* Add the body content that manages UTM trackers */}
                    </ModalComponent.Body>
                </ModalComponent>
            )}

            {trackers.length > 0
                ? multi
                    ? renderSelectFieldMultiple()
                    : renderSelectField({ disabled: false })
                : renderSelectField({ disabled: true })}
        </>
    );
};

export default observer(UTMTrackerSelect);
