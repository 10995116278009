import { doGET } from "./HttpUtil";

export const prepareFilterArtifacts = (queryParams, STRUCTURE) => {
    let filterObject = {};
    let filterUrl = ""
    STRUCTURE.forEach((filter) => {
        const { filterName } = filter;
        const filterValue = queryParams?.get(filterName)
        if (queryParams?.get(filterName))
            filterObject[filterName] = filterValue;
        if (filterUrl) filterUrl += ("&")
        if (filterValue) filterUrl += (filterName + "=" + encodeURIComponent(filterValue))
    });
    return { filterObject, filterUrl };
};


export const getUiPrefRows = async (uiPreference) => {
    try {
        const response = await doGET("/api/ui-preference" + "/detail?id=" + `${uiPreference}.rows`);
        if (response?.status == 200 && response?.data?.value) {
            return response.data?.value;
        }
    } catch (error) {
        return null
    }

    return null
}