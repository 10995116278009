export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/reason-code/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    gridAll: (page, sizePerPage, filterUrl) => {
        let url = `/api/reason-code/grid?rows=-1`;
        return url;
    },
    get: (id) => `/api/reason-code/detail?id=${id}`,
    save: `/api/reason-code/create`,
    delete: (id) => `/api/reason-code/delete?id=${id}`,
    bulkDelete: (id) => `/api/reason-code/delete`,
    update: `/api/reason-code/update`,
};

export const STRUCTURE = [
    {
        label: "Title",
        filterLabel: "Title",
        filterName: "title",
        type: "text",
    },
];
