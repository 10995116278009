
import 'chart.js/auto';
import React from "react";
import { Card, Row } from "reactstrap";
import '../../../node_modules/react-vis/dist/style.css';
import "./style.css";
import { ApplicationStatusCard } from "../../components/DashboardCard";
import AccountStatusChart from '../../components/DashboardCard/HeaderCards/AccountStatusChart';
import ApplicationsManagedByAgent from '../../components/DashboardCard/HeaderCards/ApplicationsManagedByAgent';

const Dashboard = () => {

  return (
    <React.Fragment >
      <div className="mt-1">
        <Card className="py-2 px-4 flex-1" >
          <div className='d-flex flex-column flex-md-row mt-1 gap-3'>
            <ApplicationStatusCard status={[1, 2, 3, 4, 5, 7]} actionable={[null, true]} title="Actionable" />
            <ApplicationStatusCard status={[0]} title="Draft" />
            <ApplicationStatusCard status={[2]} title="Incomplete" />
            <ApplicationStatusCard status={[3]} title="Approved" />
            <ApplicationStatusCard status={[4]} title="Rejected" />
          
          </div>
          <div className='d-flex flex-column flex-md-row mt-1 gap-3'>
            <ApplicationStatusCard status={[5]} title="Account Requested" />
            <ApplicationStatusCard status={[6]} title="Account Created" />
            <ApplicationStatusCard status={[7]} title="Account Creation Failed" />
            <ApplicationStatusCard status={[8]} title="Declined" />
            <ApplicationStatusCard status={[9]} title="Archived" />
          </div>
        </Card>

        <AccountStatusChart />

        <ApplicationsManagedByAgent/>
        
      </div>
    </React.Fragment >
  )
}

export default Dashboard