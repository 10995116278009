import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import AuditLogButton from "../../components/Audit/AuditLogButton";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import PagesNoRights from "../Utility/pages-no-rights";
import EditRiskCalculationWrapper from "./EditRiskCalculationWrapper";
import { STRUCTURE } from "./RiskCalculationConstant";
import { RiskCalculationGridColumns } from "./RiskCalculationGridColumns";
import RiskCalculationService from "./RiskCalculationService";
import "./style.css";

const formTypeLabel = {
  INDIVIDUAL_PLUS: "Individual Plus",
  BUSINESS_PLUS: "Business Plus",
  BUSINESS_PRIME: "Business Prime",
  INIDIVIDUAL_PRIME: "Individual Prime"
}

const RiskCalculation = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { qedit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(qedit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/risk-calculation/create" || qedit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await RiskCalculationService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(2500)) {
      const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
      setFilterURL(filterUrl)
      setFilterObject(filterObject)
      fetchData(filterUrl);
      multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }
  }, [userContent?.rights])




  useEffect(() => {
    if (qedit_id) {
      setEditID(qedit_id)
    } else {
      if (window.location.pathname !== "/risk-calculation/create") {
        setShowDetailPage(false)
      }
      setEditID(null);
    }
  }, [qedit_id, window.location.pathname])

  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (editID) {
        await RiskCalculationService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Risk Calculation updated successfully."));
      } else {
        const riskCalculation = await RiskCalculationService.save(detailData);
        setLoading(false);
        showMessage(t("Risk  Calculation saved successfully."));
        if (!insidePane) navigate(`/risk-calculation/edit/${riskCalculation}`);
      }
      fetchData(filterURL);
      // setShowDetailPage(false)

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };

  const renderLastCol = useCallback((riskCalculation) => {
    return (
      <>
        {userContent?.rights?.includes(2501) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/risk-calculation/edit/${riskCalculation?._id}`);
              setEditID(riskCalculation?._id);
              setShowDetailPage(true);

            }}
          />
        }

        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(riskCalculation?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])



  if (!userContent?.rights?.includes(2500)) {
    return <div key={userContent?.rights} > <PagesNoRights /></div>
  }


  return (
    <React.Fragment key={userContent?.rights}>
      <Layout
        hideAdd
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/risk-calculation");
          setEditID(null);
        }}
        title={t("Risk Calculation")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/risk-calculation/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={RiskCalculationService.page}
        rows={RiskCalculationService.rows}
        total={RiskCalculationService.total}
        fetch={RiskCalculationService.fetch}
      >

        <Layout.Table>
          <DataGrid
            data={RiskCalculationService.records}
            total={RiskCalculationService.total}
            uiPreference="risk-calculation.grid"
            gridLoading={loading}
            headers={RiskCalculationGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={RiskCalculationService.page}
            rowsPerPage={RiskCalculationService.rowsPerPage}
            onPaginationChange={RiskCalculationService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          <div className="d-flex justify-content-between align-items-center">
            {detailData?._id ? (`Edit Risk Calculation for ${formTypeLabel?.[detailData?.formType]}`) : t("Add Risk Calculation")}

          
          </div>
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditRiskCalculationWrapper
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>

          <div className="d-flex justify-content-between align-items-center">

            {detailData?._id ? (
              <AuditLogButton
                description="All numeric values (weightage, ratings, min, max) are multiplied by 1000."
                title="Audit Logs - Risk Calculation"
                module="RiskCalculation"
                id={detailData?._id}
              />
            ) : null
            }
 

          {userContent?.rights?.includes(2501) ?
            <SaveButton onClick={onSave} loading={loading} />
            : null}
          </div>

        </Layout.DetailPageFooter>
      </Layout>



    </React.Fragment>
  );
};

export default observer(RiskCalculation);


