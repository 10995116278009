import React, { useContext, useState } from "react";
import { Button } from "reactstrap";
import { ModalComponent } from "../../../../components";
import ReasonCodeSelect from "./../../../../components/Dropdowns/ReasonCodeSelect/ReasonCodeSelect";  // Assuming ReasonCodeSelect is your component
import { DialogContext } from "../../../../store/context/DialogContext";

const DeclineStatus = ({ isOpen, toggle, onConfirm }) => {
    const [selectedReason, setselectedReason] = useState(null);
    const {showError} = useContext(DialogContext)

    const handleConfirm = () => {
        if (!selectedReason) {
            showError("Please select a reason.");
            return;
        }
        onConfirm(selectedReason); 
    };

    return (
        <ModalComponent
            isOpen={isOpen}
            onToggle={toggle}
            size="md"
            position="centered"
    
        >
            <ModalComponent.Title>
                Confirm Status Change
            </ModalComponent.Title>
            <ModalComponent.Body>
                <h3 className="ps-1 py-2">Are you sure you want to mark the application as "Declined"?</h3>
                <div style={{marginBottom : "200px"}} className="">
                    <ReasonCodeSelect
                        className={` my-3`}
                        onChange={(v) => setselectedReason(v.value)}  // Update selected tracker
                        label={"Reason"}
                    />
                </div>
            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button color="secondary" outline className="me-2 mb-2" onClick={toggle}>Cancel</Button>
                <Button color="primary" className="mb-2" onClick={handleConfirm}>Confirm</Button>
            </ModalComponent.Footer>
        </ModalComponent>
    );
};

export default DeclineStatus;
