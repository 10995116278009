import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight, FaCheck, FaExpand, FaExternalLinkAlt, FaFileDownload, FaRedo, FaSpinner } from 'react-icons/fa';
import { RiDownloadLine } from "react-icons/ri";
import { Tooltip } from 'react-tooltip'; // Import the ReactTooltip component
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import { DialogContext } from '../../../../store/context/DialogContext';
import { UserContext } from '../../../../store/context/UserContext';
import { doGET, doPUT } from '../../../../util/HttpUtil';
import { StatusDisplay, stepStatusOptions } from '../applicationDocument/ApplicationDocument';
import "./style.scss";
import { getBaseUrls } from '../../../../constants';

export const downloadBlob = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};


export const DownloadReport = ({ reportId, }) => {
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(DialogContext);
    const downloadReport = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/ondato/form/pdf/report?id=${reportId}`);
            if (response.status == 200) {
                const blob = response.data;
                const fileName = 'reportId.pdf';
                downloadBlob(blob, fileName);
            } else {
                throw new Error('Failed to download report');
            }
        } catch (error) {
            console.error('Error downloading the report:', error);
            showError(error)
        } finally {
            setLoading(false)
        }

    };

    if (loading) return <div className="fv_ondato_rule">
        <FaSpinner size={14} className="spinner" />
    </div>

    const tooltipId = `tooltip-${reportId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    return (
        <div>
            <FaFileDownload
                style={{ cursor: 'pointer' }}
                onClick={downloadReport}
                title="Download Report"
                size={20}
                id={tooltipId}
            />
            <Tooltip
                style={{ zIndex: '200000' }}
                anchorSelect={`#${tooltipId}`}
            >
                Download Report
            </Tooltip>
        </div>
    );
};

export const ImageFromBase64 = ({ id, fileId, file }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState({});
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const downloadImage = () => {
        const a = document.createElement('a');
        a.href = `data:image/jpeg;base64,${img?.encodedBase64String}`;
        a.download = file.fileName || 'download.jpeg';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const fetchImage = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/ondato/file/string?id=${id}&fileId=${fileId}`)
            if (response?.status == 200) {
                setImg(response?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id && fileId)
            fetchImage()
    }, [id, fileId])




    if (loading) {
        return <div className="image-skeleton" style={{ backgroundColor: "#eee" }}></div>;
    }

    if (error) {
        return <div>Error loading image</div>;
    }

    if (!img?.encodedBase64String) return <div>No image available</div>;

    const src = `data:image/jpeg;base64,${img?.encodedBase64String}`;
    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2 '>
                <img
                    style={{ borderRadius: "5px", width: "100%", cursor: "pointer" }}
                    src={src}
                    alt={`Image of ${img?.fileName}`}
                    onClick={toggleModal}
                />
            </div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                <small>{new Date(file.createdUtc).toLocaleString()}</small>
                <div>
                    <RiDownloadLine style={{ cursor: 'pointer', marginRight: '10px' }} onClick={downloadImage} />
                    <FaExpand style={{ cursor: 'pointer' }} onClick={toggleModal} />
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} centered>
                <ModalBody>
                    <img src={src} alt={`Image of ${img?.fileName}`} style={{ width: '100%' }} />
                </ModalBody>
            </Modal>
        </>
    );
};

export const Faces = ({ kycDocument }) => {
    const [current, setCurrent] = useState(0);
    const files = kycDocument?.face?.files || [];

    const handleNext = () => {
        setCurrent((prev) => (prev + 1) % files.length);
    };

    const handlePrevious = () => {
        setCurrent((prev) => (prev - 1 + files.length) % files.length);
    };


    const arrowStyle = (isActive) => ({
        cursor: isActive ? "pointer" : "default",
        color: isActive ? "black" : "#ddd",
        padding: "5px",
        margin: "0 10px",
        borderRadius: "4px",
    });


    return (

        <div>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <h3>Attempts</h3>
                <div className='d-flex justify-content-between align-items-center  mb-2'>
                    <div
                        style={arrowStyle(files.length > 1)}
                        className='me-3 card p-1 mb-0'
                        onClick={handlePrevious}>
                        <FaArrowLeft />
                    </div>
                    <span>{`${current + 1}/${files.length}`}</span>
                    <div
                        style={arrowStyle(files.length > 1)}
                        className='ms-3 card p-1 mb-0'
                        onClick={handleNext}>
                        <FaArrowRight />
                    </div>
                </div>

            </div>
            <div>
                {files.length > 0 ? (
                    <ImageFromBase64
                        id={kycDocument?.id}
                        fileId={files[current]?.fileId}
                        file={files[current]}
                    />
                ) : (
                    <div>No image available</div>
                )}
            </div>

        </div>
    );
};

export const reduceOcrValidations = (validations) => {
    return validations.reduce((acc, { key, isValid }) => {
        const formattedKey = _.startCase(key).replace(/ /g, '');
        acc[formattedKey] = isValid;
        return acc;
    }, {});
};

export const StatusWithBadge = ({ title, status, reason }) => (

    <div style={{ borderRight: "1px solid #EBEBEB" }} className='d-flex pe-2 flex-column align-items-start border-right'>

        <b>{title}</b>
        <StatusBadge label={reason || status} status={status} />

    </div>
);

export const StatusBadge = ({ status, label }) => {
    let color = '';
    let backgroundColor = '';
    let textColor = '';


    switch (status) {
        case 'Approved':
        case 'Completed': // Fall through to the next case
            color = 'success';
            backgroundColor = '#d4edda';
            textColor = '#155724';
            break;
        case 'Rejected':
        case 'Expired': // Fall through to the next case
            color = 'danger';
            backgroundColor = '#f8d7da';
            textColor = '#721c24';
            break;
        case 'Awaiting':
        case 'InProgress': // Fall through to the next case
        case 'Pending': // Fall through to the next case
            color = 'warning';
            backgroundColor = '#fff3cd';
            textColor = '#856404';
            break;
        default:
            color = 'secondary';
            backgroundColor = '#e2e3e5';
            textColor = '#383d41';
            break;
    }



    return <div style={{
        display: "inline",
        borderRadius: 5,
        padding: "6px 8px",
        fontSize: "13px",
        backgroundColor: backgroundColor,
        color: textColor,
        textAlign: "center"

    }} >{_.startCase((label))}</div>;
};

export const InfoPair = ({ label, value, isValid, applyValidation }) => (
    <div className="fv_ondato_info-pair">
        <span className={`fv_ondato_label ${!applyValidation ? '' : isValid ? 'text-success' : 'text-danger'}`}>{label}</span>
        <span className={`fv_ondato_value text-wrap truncate`}>
            {value}
        </span>
    </div>
);

export const InfoPairColumn = ({ label, value, isValid, applyValidation }) => (
    <div className="fv_ondato_info-pair d-flex flex-column justify-content-start align-items-start">
        <div className={`fv_ondato_label ${!applyValidation ? '' : isValid ? 'text-success' : 'text-danger'}`}>{label}</div>
        <div className={`fv_ondato_value-column mt-1 `}>
            {value}
        </div>
    </div>
);


export const Rule = ({ name, status }) => (
    <div className="fv_ondato_rule">
        {status === "Success" ? <FaCheck size={14} color='green' /> : <i style={{ fontSize: "14px", backgroundColor: "red", color: "#fff", borderRadius: "10px" }} className="dripicons-cross" />}
        <span className="fv_ondato_rule-name">{_.startCase((name))}</span>
    </div>
);

const Refresh = ({ onClick, formId, }) => {
    const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    return <>
        <FaRedo
            id={tooltipId}
            size={20}
            className='me-2'
            style={{ cursor: 'pointer', outline: "none" }}
            onClick={onClick}
            title="Refresh Data"
        />
        <Tooltip
            style={{ zIndex: '200000' }}
            anchorSelect={`#${tooltipId}`}
        >
            Refresh from Ondato
        </Tooltip>
    </>
}
export const OndatoViewer = ({ isLocked, reload, formId, ondatoReport, highestSeqReport }) => {
    const [loading, setLoading] = useState(true)
    const baseUrls = getBaseUrls()
    const { showError, showConfirm, showMessage } = useContext(DialogContext);

    const fetchOndatoStatusToRefreshData = async () => {
        setLoading(true)
        try {
            const ondatoResponse = await doGET(`/api/ondato/refresh?id=${ondatoReport?._id}`);
            if (ondatoResponse?.status == 200 && ondatoResponse?.data == "reload") {
                reload()
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSelectThis = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: ("Do you want to accept this kyc verification?"),
            })
        ) {
            setLoading(true);
            try {
                const response = await doGET(`/api/kyc/inc/seq?id=${ondatoReport?._id}`)
                if (response?.status == 200) {
                    showMessage("Selected Successfully", "Success");
                    reload();
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };


    useEffect(() => {
        fetchOndatoStatusToRefreshData()
    }, [])

    if (loading) return <OndatoSkeleton />


    const kycDocument = ondatoReport.kycDocument || {};
    const registration = ondatoReport.registration || {};
    const documentDetails = kycDocument.document || {};
    const rules = kycDocument.rules || [];
    const ocrValidationsKeyValue = reduceOcrValidations((documentDetails?.ocrValidations ?? []));
    const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    return (

        <Container className='mt-2' fluid>

            <div className='d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center'>


                <div className='flex-1 d-flex flex-wrap gap-2 justify-content-start'>
                    {kycDocument?.status && <StatusWithBadge
                        title="KYC Status"
                        status={kycDocument?.status}
                    />}
                    {kycDocument?.statusReason && <StatusWithBadge
                        title="KYC Status Reason"
                        status={kycDocument?.status}
                        reason={kycDocument?.statusReason}
                    />}
                    <StatusWithBadge
                        title="Registration Status"
                        status={ondatoReport?.status ?? "Pending"}
                    />


                </div>

                <div className='d-flex align-items-center mb-2'>
                    {(!isLocked &&
                        (highestSeqReport?.flowStatus !== 3
                            && (kycDocument?.status && kycDocument?.status == "Approved" && (ondatoReport?.seq != highestSeqReport?.seq))
                        ))
                        && <Button
                            className='py-1 mb-1 me-2 '
                            style={{ width: "max-content" }}
                            color="success"
                            onClick={handleSelectThis}>
                            Select This
                        </Button>
                    }

                    {!isLocked && <Refresh
                        onClick={fetchOndatoStatusToRefreshData}
                        formId={formId}
                    />}

                    <a
                        href={`${baseUrls?.ondato}/identifications/kyc/view/${kycDocument?.id}`}
                        target="_blank"
                        className='me-2'
                        rel="noopener noreferrer"
                        data-tip="View KYC Document"
                        id={tooltipId}
                    >
                        <FaExternalLinkAlt size={20} style={{ color: '#007bff' }} />
                    </a>
                    <Tooltip
                        style={{
                            zIndex: '200000',
                        }}
                        anchorSelect={`#${tooltipId}`}
                    >
                        View on Ondato
                    </Tooltip>


                    <DownloadReport reportId={ondatoReport?._id} />
                </div>

            </div>

            {Object.keys(ondatoReport)?.length ? <Row>
                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4><b>Documents</b></h4>

                            {Object.keys(ocrValidationsKeyValue)?.length ?
                                <div>
                                    <InfoPair isValid={ocrValidationsKeyValue?.FirstName} applyValidation label="First name" value={documentDetails?.firstName || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.LastName} applyValidation label="Last name" value={documentDetails?.lastName || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.Gender} applyValidation label="Gender" value={documentDetails?.gender || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DateOfBirth} applyValidation label="Date of birth" value={documentDetails?.dateOfBirth || "—"} />
                                    <InfoPair label="Country" value={documentDetails?.countryIso3 || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.Nationality} applyValidation label="Nationality" value={documentDetails?.nationalityIso3 || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.PersonalCode} applyValidation label="Personal Code" value={documentDetails?.personalCode || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DocumentNumber} applyValidation label="Document number" value={documentDetails?.documentNumber || "—"} />
                                    <InfoPair label="Document type" value={documentDetails?.type || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.Category} applyValidation label="Document Category" value={documentDetails?.category || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DateOfIssue} applyValidation label="Issue date" value={documentDetails?.dateOfIssue || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DateOfExpiration} applyValidation label="Expiration date" value={documentDetails?.dateOfExpiration || "—"} />
                                </div>
                                :
                                <div>
                                    <InfoPairSkeleton />
                                    <InfoPairSkeleton />
                                    <InfoPairSkeleton />
                                </div>}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h4><b>Rules</b></h4>
                            {rules?.length ? <>
                                {(rules ?? []).map((rule, index) => (
                                    <Rule key={index} name={rule?.name} status={rule?.status} />
                                ))}
                            </> :
                                <>
                                    <RuleSkeleton />
                                    <RuleSkeleton />
                                    <RuleSkeleton />
                                </>

                            }



                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Faces kycDocument={kycDocument} />
                        </CardBody>
                    </Card>

                </Col>

                <Col xl="6">
                    <Card>
                        <CardBody>
                            <h4><b>Registration</b></h4>
                            <InfoPair label="Registration Started" value={ondatoReport?.createdUtc ? moment(ondatoReport?.createdUtc).format("YYYY-MM-DD hh:mm") : "—"} />
                            <InfoPair label="Finished" value={ondatoReport?.modifiedUtc ? moment(ondatoReport?.modifiedUtc).format("YYYY-MM-DD hh:mm") : "—"} />
                            <InfoPair label="Email" value={registration?.email || "—"} />
                            <InfoPair label="Phone" value={registration?.phoneNumber || "—"} />
                            <InfoPair label="First name" value={registration?.firstName || "—"} />
                            <InfoPair label="Middle name" value={registration?.middleName || "—"} />
                            <InfoPair label="Last name" value={registration?.lastName || "—"} />
                            <InfoPair label="Date of birth" value={registration?.dateOfBirth || "—"} />
                            <InfoPair label="External ID" value={ondatoReport?.externalReferenceId || "—"} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <div>
                                {documentDetails?.files?.map(v => v?.fileId ? <div className='mb-2'> <ImageFromBase64
                                    id={kycDocument?.id}
                                    file={v}
                                    fileId={v?.fileId}
                                /> </div> : null)}

                            </div>

                        </CardBody>
                    </Card>



                </Col>
            </Row> : <OndatoSkeleton />}
        </Container>
    )
}
export const OndatoGroupedByUBOs = ({ isLocked, reload, formId, refreshId }) => {
    const [ondatoReports, setOndatoReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uboIdName, setUboIdName] = useState({});
    const { showConfirmWithTextArea, showConfirm, showError, showMessage } = useContext(DialogContext);
    const { userContent, } = useContext(UserContext);
    const [activeAccordions, setActiveAccordions] = useState({});
    const baseUrls = getBaseUrls()

    const fetchOndatoData = async () => {
        setLoading(true);
        try {
            const ondatoResponse = await doGET(`/api/ondato/grid?form_id=${formId}&sortBy=seq&sortAsc=false`);
            if (ondatoResponse?.status === 200 && ondatoResponse?.data?.rows) {
                setOndatoReports(ondatoResponse.data?.rows);
            }
        } catch (error) {
            console.error('Failed to fetch Ondato data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUboData = async () => {
        if (formId) {
            try {
                const uboResponse = await doGET(`/api/ubo/grid?application_id=${formId}`);
                if (uboResponse.status === 200) {
                    const obj = {};
                    (uboResponse?.data?.rows ?? []).forEach((v) => {
                        obj[v?._id] = v;
                    });
                    setUboIdName(obj);
                } else {
                    throw new Error('Failed to fetch UBO data');
                }
            } catch (error) {
                console.error('Failed to fetch UBO data:', error);
            }
        }
    };


    const handleReDoKycForUbo = async (event, id, uboName, highestSeqReport) => {
        event.stopPropagation();
        const result = await showConfirmWithTextArea({
            title: `Do you want to redo KYC for ${uboName}?`,
            description: `The system will send an email to ${uboName} to redo the KYC process.`,
            remarkRequired: true,
            remarkPlaceholder: "Remark for Applicant"
        })
        if (result === true || (result && result.confirmed)) {
            setLoading(true);
            try {
                await doPUT('/api/ondato/update', {
                    ...highestSeqReport,
                    flowStatus: 2,
                    remark: result?.remark ?? ""
                });
                showMessage("Reminder sent successfully", "Success");
                reload()
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };




    useEffect(() => {
        fetchUboData();
        fetchOndatoData();
    }, [formId, refreshId]);

    if (loading) return <OndatoSkeleton />;

    if (!ondatoReports?.length) return <>
        <div>
            <h4><b>Ondato KYC Verification is not performed yet</b></h4>
            <OndatoSkeleton />
        </div>
    </>;

    const groupedReports = _.groupBy(ondatoReports, 'ubo_id');
    const maxSeqIndex = ondatoReports.reduce((acc, current, idx, arr) => {
        return (arr[acc].seq > current.seq) ? acc : idx;
    }, 0);

    const hasUnrejectRight = (status) => {
        if (status == 4) {
            return userContent?.rights?.includes(2804);
        }
        return true;
    };


    const handleRedirectToOndato = async (event, id, uboName, highestSeqReport) => {
        event.stopPropagation();
        const result = await showConfirm({
            title: (
                <span>
                    KYC status can only be changed from Ondato platform.  <br />
                    <a
                        href={`${baseUrls?.ondato}/identifications/kyc/view/${highestSeqReport?.kycDocument?.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => setActiveAccordions({})}
                        style={{
                            color: '#007bff',
                            textDecoration: 'underline',
                            fontWeight: 'bold'
                        }}
                    >
                        Click here
                    </a> to go to Ondato.
                </span >
            ),
            hideCancelButton: true
        });

        if (result === true || (result && result.confirmed)) {
            setLoading(true)
            try {
                const ondatoResponse = await doGET(`/api/ondato/refresh?id=${highestSeqReport?._id}`);
                if (ondatoResponse?.status == 200 && ondatoResponse?.data == "reload") {
                    reload()
                }
            } catch (error) {
                showError(error)
            } finally {
                setLoading(false)
            }
        }
    };

    const renderAccordion = (reports, uboId, isLocked, highestSeqReport) => {

        const handleSelect = (eventKey) => {
            setActiveAccordions(prev => ({
                ...prev,
                [uboId]: eventKey === prev[uboId] ? null : eventKey
            }));
        };


        return <Accordion activeKey={activeAccordions[uboId]} onSelect={handleSelect}>
            {reports.map((report, index) => (
                <Accordion.Item eventKey={`${index}`} key={index}>
                    <Accordion.Header>
                        <div className='d-flex justify-content-between align-items-center w-100 '>
                            <span className='d-flex'>
                                {report?.seq}. {report?.registration?.firstName} {report?.registration?.middleName} {report?.registration?.lastName}
                                <span className='ms-2'><StatusDisplay value={stepStatusOptions?.find(v => report?.flowStatus == v.value)} /></span>

                            </span>
                            <span className='me-3'>
                                {report?.kycDocument?.status ?
                                    <>KYC Status: {report?.kycDocument?.status} </>
                                    : <>Registration Status: {report?.status ?? "Pending"} </>
                                }
                            </span>
                        </div>
                    </Accordion.Header>
                    {activeAccordions[uboId] === `${index}` && (
                        <Accordion.Body>
                            <OndatoViewer
                                isLocked={isLocked}
                                reload={reload}
                                highestSeqReport={highestSeqReport}
                                formId={formId}
                                ondatoReport={report}
                            />
                        </Accordion.Body>
                    )}
                </Accordion.Item>
            ))}
        </Accordion>
    };

    return (
        <Container key={userContent?.rights} fluid>

            {Object.keys(groupedReports).map(uboId => {
                const reports = groupedReports[uboId];
                const highestSeqReport = reports.reduce((prev, current) => (prev.seq > current.seq ? prev : current), reports[0]);
                const uboName = `${uboIdName[uboId]?.firstName ?? ''} ${uboIdName[uboId]?.lastName ?? ''}`.trim();

                return (
                    <Card key={uboId}>
                        <CardBody>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h4>KYC Verification for {uboName || 'Unknown UBO'} <span className='ms-2'><StatusDisplay value={stepStatusOptions?.find(v => highestSeqReport?.flowStatus == v.value)} /></span></h4>
                                {(
                                    !isLocked &&
                                    userContent?.rights?.includes(2802)
                                ) &&
                                    <div className='d-flex justify-content-between align-items-center'>
                                        {highestSeqReport?.flowStatus != 4 &&
                                            <Button className='py-1 mb-1 me-2 ' style={{ width: "max-content" }} color="danger" onClick={(event) => handleRedirectToOndato(event, uboId, uboName, highestSeqReport)}>
                                                Reject
                                            </Button>}

                                        {(highestSeqReport?.flowStatus != 3 &&
                                            hasUnrejectRight(highestSeqReport?.flowStatus)) &&
                                            <Button className='py-1 mb-1 me-2 ' style={{ width: "max-content" }} color="success" onClick={(event) => handleRedirectToOndato(event, uboId, uboName, highestSeqReport)}>
                                                Approve
                                            </Button>}

                                        {hasUnrejectRight(highestSeqReport?.flowStatus) && <Button className='py-1 mb-1 ' style={{ width: "max-content" }} color="warning" onClick={(event) => handleReDoKycForUbo(event, uboId, uboName, highestSeqReport)}>
                                            Re-do KYC
                                        </Button>}
                                    </div>}
                            </div>
                            {renderAccordion(reports, uboId, isLocked, highestSeqReport)}
                        </CardBody>
                    </Card>
                );
            })}
            {!Object.keys(groupedReports).length && (
                <OndatoViewer
                    highestSeqReport
                    reload={reload}
                    onRefresh={fetchOndatoData}
                    formId={formId}
                    ondatoReport={ondatoReports[0]}
                />
            )}
        </Container>
    );
};

export const SkeletonLoader = ({ width }) => (
    <div className={`fv_ondato_skeleton`} style={{ width }}></div>
);

export const InfoPairSkeleton = () => (
    <div className="fv_ondato_info-pair">
        <SkeletonLoader width="30%" />
        <SkeletonLoader width="60%" />
    </div>
);

export const RuleSkeleton = () => (
    <div className="fv_ondato_rule">
        <FaSpinner size={14} className="spinner" />
        <SkeletonLoader width="80%" />
    </div>
);

export const OndatoSkeleton = () => (
    <Container className='p-0 m-0' fluid>
        <Row className='p-0 m-0'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h4><b>Documents</b></h4>
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h4><b>Rules</b></h4>
                            <RuleSkeleton />
                            <RuleSkeleton />
                            <RuleSkeleton />
                            <RuleSkeleton />
                            <RuleSkeleton />
                        </CardBody>
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <CardBody>
                            <h4><b>Registration</b></h4>
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <div>
                                <ImageFromBase64
                                    label="Face"

                                />
                            </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <div>
                                <ImageFromBase64
                                    label="Face"

                                />
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Row>
    </Container>
)

export default OndatoGroupedByUBOs;
