export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/email/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/email/detail?id=${id}`,
};

export const STRUCTURE = [
  {
    label: "Email",
    filterLabel: "Email",
    filterName: "email",
    type: "text",
  },
  {
    label: "Message Type",
    filterLabel: "Message Type",
    filterName: "messageType",
    type: "select",
    data: [
      { label: "Redo Link", value: "REDO_LINK" },
      { label: "Custom Email", value: "CUSTOM_EMAIL" },
      { label: "Otp", value: "OTP_SMS" },
    ],
  },
];
