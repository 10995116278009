import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaDatabase, FaSpinner } from "react-icons/fa";
import { MdReportGmailerrorred } from "react-icons/md";
import { RiMailSendFill } from "react-icons/ri";
import { TbCloudDownload } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Alert, Button } from "reactstrap";
import { ModalComponent, generateRandomString } from "../../components";
import AuditLogService from "../../components/Audit/AuditLogService";
import ApproveButton from "../../components/Buttons/ApproveButton";
import RejectButton from "../../components/Buttons/RejectButton";
import Comment from "../../components/CommentByModule";
import DownloadService from "../../components/Download/DownloadService";
import SupervisiorAssigner from "../../components/Dropdowns/SupervisiorSelect/SupervisiorAssigner";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET, doPOST, doPUT } from "../../util/HttpUtil";
import PagesNoRights from "../Utility/pages-no-rights";
import ApplicationsService from "./ApplicationsService";
import DeclineStatus from "./components/DeclineStatus/DeclineStatus";
import EmailModal from "./components/EmailModal/EmailModal";
import { MarkNonActionableButton, MarkActionableButton, RedirectToLink, Refresh, StatusDisplayDropDown } from "./components/HeaderButtons/HeaderButtons";
import StatusProgress from "./components/StatusProgress";
import { ApproveModal } from "./components/modals/ApproveModal";
import { AskForMoreInfoModal } from "./components/modals/AskForMoreInfoModal";
import { RejectModal } from "./components/modals/RejectModal";
import UserNameModal from "./components/modals/UserNameModal";
import ApplyIndividualLite from "./forms/ApplyIndividualLite";
import ApplyBusinessLite from "./forms/BusinessLiteForms/ApplyBusinessLite";
import ApplyBusinessPrime from "./forms/BusinessPrime/ApplyBusinessPrime";
import WebhookDisplay from "./forms/components/WebhookDisplay/WebHookDisplay";
import "./style.css";
import ApplicationDocument, { applicationStatuses } from "./thirdParty/applicationDocument/ApplicationDocument";
import ComplyAdvantageGroupedByUBOs from "./thirdParty/complyAdvantage/ComplyAdvantageGroupedByUBOs";
import { downloadBlob } from "./thirdParty/ondato/Ondato";
import OndatoGroupedByUBOs from "./thirdParty/ondatoGroupedByUBOs/OndatoGroupedByUBOs";
import RiskAssessment, { formatRiskValue, getRiskLabel } from "./thirdParty/riskAssessment/RiskAssessment";

const getStatusColor = (status) => {
  switch (status) {
    case 2:
      return "#fff3cd";
    case 3:
      return "#d4edda";
    case 4:
      return "#f8d7da";
    default:
      return "#F2F2F2";
  }
};

const EditApplications = ({ fetchData, editId, hideButtons, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const navigate = useNavigate()
  const { userContent } = useContext(UserContext);
  const { showError, showMessage, showConfirm, showConfirmWithTextArea } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [openModal, setOpenModal] = useState(false)
  const [approvalRequested, setApprovalRequested] = useState(false)
  const [askForMoreModal, setAskForMoreModal] = useState(false)
  const [editApplicationMode, setEditApplicationMode] = useState({ editable: false, data: {} })
  const [step, setStep] = useState(1);
  const [refreshId, setRefreshId] = useState(generateRandomString(10));
  const [stepList, setStepList] = useState([])
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [usernameModal, setUsernameModal] = useState(false)
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [stepsInfo, setStepsInfo] = useState({})
  const [riskScore, setRiskScore] = useState()
  const toggleShowMessages = () => {
    setShowMessageModal(!showMessageModal);
  }

  const toggleEmailModal = () => {
    setEmailModalOpen(!emailModalOpen);
  };

  const getRiskScore = async (form) => {
    try {
      const response = await doGET(`/api/application-risk/detail?id=${form?._id}`);
      if (response.status === 200) {
        const riskData = response.data;
        const riskCalculationResponse = await doGET(`/api/risk-calculation/grid?formType=${form?.formType}`);
        const riskCalculationData = (riskCalculationResponse?.data?.rows?.[0] ?? {})
        const formattedScore = formatRiskValue(riskData.finalScore, 1000000);
        const riskLabel = getRiskLabel(formattedScore, riskCalculationData.riskLabels ?? []);
        setRiskScore(riskLabel?.name)
      }
    } catch (error) {

    }
  }

  const fetch = async () => {
    setLoading(true)
    try {
      let form = await ApplicationsService.get(editId);
      if (form?.steps) {
        const t = form?.steps?.map((v, index) => {
          return { ...v, value: index + 1, backgroundColor: getStatusColor(v.status) }
        })
        setStepList(t);
        setStepsInfo(t.reduce((acc, item, index) => {
          acc[item?.label] = item;
          return acc;
        }, {}));
      }
      if (form?._id) {
        let formData = await ApplicationsService.getFormData(editId, form?.formType);
        setData({ ...form, formData: formData });
        getRiskScore(form)
      }
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    setData({})
    if (editId && userContent?.rights?.includes(2800)) {
      fetch();
    }
  }, [editId, ApplicationsService?.version, userContent?.rights]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  const reload = () => {
    fetch()
    setRefreshId(generateRandomString(10))
  }

  const askForMoreInfo = async (v) => {
    fetch()
    setAskForMoreModal(false)
  }

  const downloadReport = async () => {
    if (loading) return;
    setLoading(true)
    if (data?.formType == "BUSINESS_LITE" || data?.formType == "BUSINESS_PLUS") {
      try {
        const response = await doGET(`/api/generated-report/get?id=${editId}&emailReport=true`);
        const status = response?.data?.status;
        showMessage(status === "GENERATED" ? "Downloading Report...." : ["ENQUEUE", "IN_PROGRESS"].includes(status) ? "Report download has been queued." : status);
        if (status === "GENERATED") {
          const pdfResponse = await doGET(response.data.path);
          downloadBlob(pdfResponse.data, 'report.pdf');
        } else if (["ENQUEUE", "IN_PROGRESS"].includes(status)) {
          DownloadService.startPolling();
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
      return;
    }
    try {
      const response = await doGET(`/api/application-form/${editId}/download/report`)
      if (response.status == 200) {
        const blob = response.data;
        const fileName = 'reportId.pdf';
        downloadBlob(blob, fileName);
      }
    } catch (error) {
      showError(error)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const tooltipId = generateRandomString(15)

  const handleAssignesChange = async (v) => {
    try {
      await ApplicationsService.edit({ ...data, supervisor_ids: v });
    } catch (error) {
      showError(error)
    }
  }

  const extractErrors = (errorMessage) => {
    if (!errorMessage) return [];
    return errorMessage?.split(',')?.filter(msg => msg?.trim())?.map((msg, index) => (
      <li key={index}>{msg?.trim()}</li>
    ));
  };

  const handleEditApplicationForm = async () => {

    const result = await showConfirmWithTextArea({
      title: `Do you want to update application?`,
      description: `Please enter reason for updating the application`,
      remarkRequired: true,
      remarkPlaceholder: "Reason"
    })
    if (result === true || (result && result.confirmed)) {
      setLoading(true);
      try {
        await doPOST(`/api/validate/json?formType=${data?.formType}`, {
          ...(editApplicationMode?.data ?? {}),
        });
        if (data?.formType == "INDIVIDUAL_PLUS") {
          await doPUT(`/api/individual-lite/update?remark=${encodeURIComponent(result?.remark ?? "")}`, {
            ...(editApplicationMode?.data ?? {}),
          });
        } else if (data?.formType == "BUSINESS_PLUS") {
          await doPUT(`/api/business-lite/update?remark=${encodeURIComponent(result?.remark ?? "")}`, {
            ...(editApplicationMode?.data ?? {}),
          });
        }
        if (data?.cyclosUserName != editApplicationMode?.data?.cyclosUserName) {
          await ApplicationsService.edit({ ...data, cyclosUserName: editApplicationMode?.data?.cyclosUserName });
        }
        showMessage("Application Data Updated Successfully", "Success");
        setEditApplicationMode({ editable: false, data: {} });
        reload()
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const hasUnrejectRight = (status) => {
    if (status == 4) { // rejected
      return userContent?.rights?.includes(2804);
    }
    return true; // if not rejected then can approve or askformore
  };

  const handleStatusChange = async (v) => {
    const statusLabel = applicationStatuses[v]?.label;
    if (v === 8) {
      setIsDeclineModalOpen(true);
    } else if (v === 9) {
      const result = await showConfirm({
        title: `Are you sure you want to mark the application as "${statusLabel}"?`,
      });

      if (result === true || (result && result.confirmed)) {
        setLoading(true)
        try {
          await ApplicationsService.edit({ ...data, status: v });
        } catch (error) {
          showError(error)
        } finally {
          setLoading(false)
        }
      }
    }
  }


  const handleDeclineStatus = async (reasonCode_id) => {
    setLoading(true);
    try {
      const response = await doPOST("/api/application-form/decline", {
        _id: data?._id,
        reasonCode_id: reasonCode_id
      })
      if (response?.status == 200) {
        reload();
      }
      setIsDeclineModalOpen(false);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const changeActionable = async (isActionable) => {
    setLoading(true)
    try {
      await ApplicationsService.edit({ ...data, actionable: isActionable });
      if (isActionable) {
        showMessage("Application marked as Actionable.")
      } else {
        showMessage("Application marked as Non-Actionable.")
      }
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false)
    }
  }

  function renderActionButton() {
    if (![0, 6, 8, 9].includes(data?.status)) {
      if (data?.actionable === false) {
        return <MarkActionableButton onClick={() => changeActionable(true)} />;
      } else {
        return <MarkNonActionableButton onClick={() => changeActionable(false)} />;
      }
    }
    return null;
  }

  if (!userContent?.rights?.includes(2800)) {
    return <div key={userContent?.rights}><PagesNoRights /></div>;
  }

  return (
    <div className="d-flex flex-column h-100">

      <div className="zr_layout-header d-flex justify-content-between" style={{ flexShrink: 0 }} >
        <h4 style={{ fontWeight: 600 }} className="py-0 my-0 d-flex align-items-center "> <span style={{ color: "#888" }} className="me-2  "> #{data?.applicationNo ?? "Application"}  </span>  <b> {data?.customerName ?? ""}  </b>  </h4>

        <div className="d-flex align-items-center me-4 pe-2">
          <span className="me-2">
            <StatusDisplayDropDown
              rights={userContent?.rights}
              value={applicationStatuses?.find(v => data?.status == v.value)}
              onChange={(v) => { handleStatusChange(v?.value) }}
            />
          </span>

          <div className={`${(data?.supervisor_ids ?? [])?.length ? "me-3" : "me-0"}`}>
            <SupervisiorAssigner
              value={data?.supervisor_ids}
              asLabel={!userContent?.rights?.includes(2806)}
              onChange={(v) => { handleAssignesChange(v) }}
            />
          </div>


          <Tooltip
            style={{
              zIndex: '200000',
            }}
            anchorSelect={`#${tooltipId}`}
          >
            Download Report
          </Tooltip>

          {userContent?.rights?.includes(2802) ? <div id={tooltipId}>
            <Button
              type="button"
              color="primary"
              onClick={downloadReport}
              className={"btn ms-2 me-1 btn-primary"}
              style={{ padding: "3px" }}
            >
              {loading ? <div className="fv_ondato_rule py-0">
                <FaSpinner size={16} className="spinner" />
              </div> : <> <TbCloudDownload color="#fff" className="" size={16} /> </>}
            </Button>
          </div> : null}

          <Refresh onClick={() => { reload() }} />

          {renderActionButton()}


          {data?.cyclosUserId && ([5, 6, 9]?.includes(data?.status)) && userContent?.rights?.includes(2700) ? <RedirectToLink onClick={() => { navigate(`/accounts/detail/${data?.cyclosUserId}`) }} /> : null}

          <Tooltip
            style={{
              zIndex: '200000',
            }}
            anchorSelect={`#emailTooltip`}
          >
            Send Email
          </Tooltip>

          {userContent?.rights?.includes(2802) ? <div id="emailTooltip">
            <Button
              type="button"
              color="primary"
              onClick={toggleEmailModal}
              className={"btn mx-1 btn-primary"}
              style={{ padding: "3px" }}
            >
              <RiMailSendFill color="#fff" size={17} />
            </Button>
          </div> : null}

          <Tooltip
            style={{
              zIndex: '200000',
            }}
            anchorSelect={`#logsTooltip`}
          >
            Logs
          </Tooltip>

          {userContent?.rights?.includes(2800) ? <div id="logsTooltip">
            <Button
              type="button"
              color="primary"
              onClick={() => {
                AuditLogService?.toggleModal({
                  modules: [
                    // { title: "Email", key: "EMAIL_LOG", value: data?.formType == "BUSINESS_PLUS" ? [data?.email, ...(data?.formData?.individuals?.map(v => v?.email) ?? [])] : [data?.email] },
                    {
                      title: "Email", key: "EMAIL_LOG", value: {
                        emails: data?.formType == "BUSINESS_PLUS" ? [data?.email, ...(data?.formData?.individuals?.map(v => v?.email) ?? [])] : [data?.email],
                        createdAt: data?.createdAt,
                        ref_id: data?._id,
                        updatedAt: data?.updatedAt,
                        status: data?.status
                      }
                    },
                    { title: "Api Logs", key: "API_LOG", value: data?._id },
                  ],
                  id: data?._id
                  , title: "Logs"
                })
              }}
              className={"btn ms-1 me-1 btn-primary"}
              style={{ padding: "3px" }}
            >
              <FaDatabase color="#fff" size={16} />
            </Button>
          </div> : null}
        </div>

      </div>
      {
        <div style={{ width: "calc(100% - 5px)" }} className="row px-0 ">
          <div className="col-12 col-md-8 px-0">
            <StatusProgress
              data={stepList}
              value={step}
              refreshId={refreshId}
              onChange={setStep}
              toggleShowMessages={toggleShowMessages}
            />


            {editApplicationMode?.editable && <Alert color="info" role="alert">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Edit Application Mode On.
                </div>
                <div>
                  <Button
                    onClick={() => setEditApplicationMode(prev => ({ ...prev, editable: !prev?.editable }))}
                    className="py-0">
                    Disable
                  </Button>
                </div>
              </div>
            </Alert>}

            {stepList[step - 1]?.label === "Application Form" && (
              <div>
                <div className={`scrollable-form-area card p-3 px-4 m-0 mb-2 ${editApplicationMode?.editable ? "edit" : ""}`}>
                  {data?.status == 7 && <Alert color="danger" role="alert" className="mb-0">
                    <div className="d-flex justify-content-start align-items-center">
                      <MdReportGmailerrorred
                        size={22}
                        className="me-1"
                        color="#eb2d3a"
                      />
                      <div className="font-size-18">
                        Account Creation Error
                      </div>

                    </div>

                    {extractErrors(data?.errorMessage)}

                  </Alert>}


                  {data?.formType == "BUSINESS_PRIME" ? <ApplyBusinessPrime refreshId={refreshId} asLabel formId={editId} value={data} /> : data?.formType == "BUSINESS_LITE" || data?.formType == "BUSINESS_PLUS" ?
                    <ApplyBusinessLite
                      refreshId={refreshId}
                      asLabel={!editApplicationMode?.editable}
                      editMode={editApplicationMode?.editable}
                      formId={editId}
                      value={data}
                      onChange={(v) => {
                        setEditApplicationMode(prev => ({ ...prev, data: v }));
                      }} />
                    : <ApplyIndividualLite
                      refreshId={refreshId}
                      asLabel={!editApplicationMode?.editable}
                      editMode={editApplicationMode?.editable}
                      formId={editId}
                      value={data}
                      onChange={(v) => {
                        setEditApplicationMode(prev => ({ ...prev, data: v }));
                      }}
                    />}

                  {(data?.webhookData && (data?.status > 4)) ? <WebhookDisplay webhookData={data?.webhookData} /> : null}
                </div>
                <div className="d-flex justify-content-end align-items-center w-100">

                  {(!hideButtons && ![0, 5, 6, 8, 9]?.includes(data?.status) && userContent?.rights?.includes(2805) && [3, 7]?.includes(data?.status) && ![0]?.includes(stepsInfo["Application Form"]?.status)) ?
                    <div className="d-flex justify-content-end my-2 me-2">
                      <Button
                        onClick={() => {
                          if (riskScore == "Extreme Risky") {
                            showError("The risk assessment indicates that this is an extremely Risky application. Please review the Risk Assessment if you believe that this is not risky.")
                          } else {
                            setUsernameModal(true)
                          }
                        }}
                        className=""
                        disabled={editApplicationMode?.editable}
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "140px", borderRadius: "10px" }}
                        color="outline-primary"
                      >
                        {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{t("Create Account")} </>}
                      </Button>
                    </div> : null}

                  {(!editApplicationMode?.editable &&
                    userContent?.rights?.includes(2802) &&
                    stepList[step - 1]?.label === "Application Form" &&
                    (!hideButtons && ![0, 5, 6, 8, 9]?.includes(data?.status))) ?
                    <div className="d-flex justify-content-end my-2 me-2">
                      <Button
                        onClick={() => {
                          setEditApplicationMode(prev => ({ ...prev, editable: !prev?.editable }));
                        }}
                        className=""
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "145px", borderRadius: "10px" }}
                        color="outline-primary"
                      >
                        {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{t("Edit Application")} </>}
                      </Button>
                    </div> : null
                  }

                  {(editApplicationMode?.editable &&
                    userContent?.rights?.includes(2802) &&
                    stepList[step - 1]?.label === "Application Form" &&
                    (!hideButtons && ![0, 5, 6, 8, 9]?.includes(data?.status))) ?
                    <div className="d-flex justify-content-end my-2 me-2">
                      <Button
                        onClick={() => {
                          handleEditApplicationForm()
                        }}
                        className=""
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "145px", borderRadius: "10px" }}
                        color="outline-primary"
                      >
                        {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{t("Save Application")} </>}
                      </Button>
                    </div> : null
                  }


                  {(
                    userContent?.rights?.includes(2802) &&
                    hasUnrejectRight(stepList[step - 1]?.status) &&
                    !hideButtons &&
                    ![0, 5, 6, 8, 9]?.includes(data?.status) &&
                    ![0]?.includes(stepsInfo["Application Form"]?.status)
                  ) ?
                    <div className="d-flex justify-content-end my-2 me-2">
                      <Button
                        onClick={() => { setAskForMoreModal(true) }}
                        className=""
                        disabled={editApplicationMode?.editable}
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
                        color="outline-primary"
                      >
                        {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{t("Ask More")} </>}
                      </Button>
                    </div> : null
                  }

                  {(
                    !hideButtons &&
                    ![0, 5, 6, 8, 9]?.includes(data?.status) &&
                    ![0]?.includes(stepsInfo["Application Form"]?.status) &&
                    userContent?.rights?.includes(2802))
                    ? <RejectButton
                      onClick={(e) => {
                        setOpenModal(true);
                        setApprovalRequested(false);
                      }}
                      disabled={stepList[step - 1]?.status == 4 || editApplicationMode?.editable}
                      loading={loading}
                    /> : null}

                  {(
                    !hideButtons &&
                    ![0, 5, 6, 8, 9]?.includes(data?.status) &&
                    ![0]?.includes(stepsInfo["Application Form"]?.status) &&
                    hasUnrejectRight(stepList[step - 1]?.status) &&
                    userContent?.rights?.includes(2802))
                    ? <ApproveButton
                      onClick={(e) => {
                        setOpenModal(true);
                        setApprovalRequested(true);
                      }}
                      disabled={stepList[step - 1]?.status == 3 || editApplicationMode?.editable}
                      loading={loading}
                    /> : null}
                </div>

              </div>
            )}

            {stepList[step - 1]?.label === "KYC Verification" && (
              <div className="scrollable-content-area  p-1 m-3">
                <OndatoGroupedByUBOs
                  isLocked={[0, 5, 6, 8, 9]?.includes(data?.status)}
                  reload={reload}
                  refreshId={refreshId}
                  formId={editId}
                />
              </div>
            )}

            {stepList[step - 1]?.label === "Sanctions Screening" && (
              <div className="scrollable-content-area p-1  m-3">
                {<ComplyAdvantageGroupedByUBOs
                  isLocked={[0, 5, 6, 8, 9]?.includes(data?.status)}
                  reload={reload}
                  refreshId={refreshId}
                  formId={editId}
                />}
              </div>
            )}

            {(stepList[step - 1]?.label === "Document Verification" || stepList[step - 1]?.label === "Documents") && (
              <div className="scrollable-content-area p-1 m-3">
                <ApplicationDocument
                  reload={reload}
                  refreshId={refreshId}
                  application_id={editId}
                  formType={data?.formType}
                  isLocked={[0, 5, 6, 8, 9]?.includes(data?.status)}
                />

              </div>
            )}


            {stepList[step - 1]?.label === "Risk Assessment" && (
              <div className="scrollable-content-area p-1 m-3">
                <RiskAssessment
                  isLocked={[0, 5, 6, 8, 9]?.includes(data?.status)}
                  reload={reload}
                  refreshId={refreshId}
                  formId={editId}
                  formType={data?.formType}
                />
              </div>
            )}

          </div>

          <div className="col-0 d-none d-md-block col-md-4 px-0">
            <Comment
              recordType={"application-form"}
              recordOf={editId}
              refreshId={refreshId}
              formType={data?.formType}
              byType={5}
              forType={[5]}
            />
          </div>


        </div>
      }


      <AskForMoreInfoModal
        isOpen={askForMoreModal}
        onToggle={() => { setAskForMoreModal(false) }}
        formType={data?.formType}
        stepName={stepList[step - 1]?.label ?? ""}
        onSubmit={(v) => {
          reload()
          askForMoreInfo(v)
        }}
        formId={editId}
      />

      <ApproveModal
        isOpen={openModal && approvalRequested}
        stepName={stepList[step - 1]?.label ?? ""}
        setOpenModal={setOpenModal}
        onSubmit={async (v) => {
          reload()
          setOpenModal(false);
        }}
        formType={data?.formType}
        formId={editId}

      />

      <RejectModal
        isOpen={openModal && !approvalRequested}
        stepName={stepList[step - 1]?.label ?? ""}
        setOpenModal={setOpenModal}
        onSubmit={async (v) => {
          reload()
          setOpenModal(false);
        }}
        formType={data?.formType}
        formId={editId}
      />

      <UserNameModal
        isOpen={usernameModal}
        setOpenModal={setUsernameModal}
        onCreateAccount={async (v) => {
          reload()
          setUsernameModal(false);
        }}
        formType={data?.formType}
        formId={editId}
        email={data?.email}
        userName={data?.cyclosUserName}
      />

      <ModalComponent
        position={"centered"}
        size={"full-screen"}
        isOpen={showMessageModal}
        onToggle={toggleShowMessages}
      >
        <ModalComponent.Title>
          Messages
        </ModalComponent.Title>
        <ModalComponent.Body>
          <Comment
            recordType={"application-form"}
            recordOf={editId}
            refreshId={refreshId}
            formType={data?.formType}
            byType={5}
            forType={[5]}
          />
        </ModalComponent.Body>
      </ModalComponent>

      <EmailModal
        isOpen={emailModalOpen}
        toggle={toggleEmailModal}
        onSend={() => {
          toggleEmailModal();
          reload();
        }}
        toEmail={data?.email}
        applicationId={editId}
      />

      <DeclineStatus
        isOpen={isDeclineModalOpen}
        toggle={() => setIsDeclineModalOpen(!isDeclineModalOpen)}
        onConfirm={handleDeclineStatus}
      />


    </div >
  );
};

export default observer(EditApplications);