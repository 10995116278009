import _ from "lodash";
const productionUrls = {
    complyAdvantage: 'https://app.eu.complyadvantage.com',
    ondato: 'https://os.ondato.com',
    parcha: 'https://preview.parcha.ai/jobs/fvbank-kyc-v2'
};

const stagingUrls = {
    complyAdvantage: 'https://app.eu.complyadvantage.com',
    ondato: 'https://sandbox-os.ondato.com',
    parcha: 'https://preview.parcha.ai/jobs/fvbank-kyc-v2'
};

export function getBaseUrls() {
    const host = window.location.host;
    const productionHost = 'complyai.fvbank.us';
    return _.toLower(host) === productionHost ? productionUrls : stagingUrls;
}

export const hostMap = {
    'caas-dev.fvbank.us': 'apply-dev.fvbank.us',
    'caas-uat.fvbank.us': 'apply-uat.fvbank.us',
    'complyai.fvbank.us': 'apply.fvbank.us',
    'caas-console.logicfirst.io': 'caas-front.fvbank.us'
};