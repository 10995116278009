import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditRoles from "./EditRoles";
import { STRUCTURE } from "./RoleConstant";
import { GridColumns } from "./RoleGridColumns";
import Roleservice from "./RoleService";
import { UserContext } from "../../store/context/UserContext";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import PagesNoRights from "../Utility/pages-no-rights";
import AuditLogButton from "../../components/Audit/AuditLogButton";

const Roles = ({ value, insidePane, multiMode, onSelect = () => { } }) => {

    let { role_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const { userContent, populateUserContent, forSuperadmin } = useContext(UserContext);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(role_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/role/create" || role_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)



    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await Roleservice.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (userContent?.rights?.includes(2200)) {
            const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
            setFilterURL(filterUrl)
            setFilterObject(filterObject)
            fetchData(filterUrl);
            multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
        }
    }, [userContent?.rights])

    useEffect(() => {
        if (!insidePane) {
            if (role_id) {
                setEditID(role_id)
            } else {
                if (window.location.pathname !== "/role/create") {
                    setShowDetailPage(false)
                }
                setEditID(null);
            }
        }
    }, [role_id, window.location.pathname])

    const onSave = async (e) => {
        e.preventDefault();
        if (!detailData?.name) {
            setHasErr(true)
            showError(t("Please enter role name"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await Roleservice.edit(detailData, editID);
                setLoading(false);
                showMessage(t("Role updated successfully."));
            } else {
                const roleID = await Roleservice.save(detailData);
                setLoading(false);
                showMessage(t("Role saved successfully."));
                if (!insidePane) navigate(`/role/edit/${roleID}`);
            }
            fetchData(filterURL);
            await populateUserContent();
        } catch (e) {
            setLoading(false);
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await Roleservice.delete(id);
                setShowDetailPage(false);
                navigate(`/role`);
                setLoading(false);
                showMessage(t(" Role Deleted"), t("Deleted "));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };

    const renderLastCol = useCallback((role) => {
        return (
            <>
                {(userContent?.rights?.includes(2202) || (userContent?.userType && userContent?.userType === "super-admin")) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/role/edit/${role?._id}`);
                            setEditID(role?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {(userContent?.rights?.includes(2203) || (userContent?.userType && userContent?.userType === "super-admin")) ?
                    <DeleteLink
                        onClick={(event) => onDelete(event, role?._id)}
                    /> :
                    null}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(role?._id);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])


    if (!userContent?.rights?.includes(2200)) {
        return <div key={userContent?.rights} > <PagesNoRights /></div>
    }


    return (
        <React.Fragment key={userContent?.rights}>
            <Layout
                hideAdd={!userContent?.rights?.includes(2201) && !(userContent?.userType && userContent.userType === "super-admin")}
                showDetailPage={showDetailPage}
                backDetailPage={() => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/role");
                    setEditID(null);
                }}
                title={t("Roles")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/role/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={Roleservice.page}
                rows={Roleservice.rows}
                total={Roleservice.total}
                fetch={Roleservice.fetch}
            >

                <Layout.Table>
                    <DataGrid
                        gridLoading={loading}
                        data={Roleservice.records}
                        total={Roleservice.total}
                        uiPreference="Role.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={Roleservice.page}
                        rowsPerPage={Roleservice.rowsPerPage}
                        onPaginationChange={Roleservice.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    {role_id ? t("Edit Role") : t("Add Role")}
                </Layout.DetailPageTitle>
                <Layout.DetailPageBody>
                    <EditRoles
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}
                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    <div className="d-flex justify-content-between align-items-center">
                        {detailData?._id ? (
                            <AuditLogButton title="Audit Logs - Role" module="Role" id={detailData?._id} />
                        ) : null}
                        {(userContent?.rights?.includes(2203) || (userContent?.userType && userContent?.userType === "super-admin")) && editID ?
                            <DeleteButton loading={loading} onClick={(e) => onDelete(e, detailData?._id)} />
                            : null}
                        {(userContent?.rights?.includes(2202) || userContent?.rights?.includes(2201) || (userContent?.userType && userContent?.userType === "super-admin")) ?
                            <SaveButton
                                disabled={!detailData?.name}
                                loading={loading}
                                onClick={onSave} />
                            : null}
                    </div>
                </Layout.DetailPageFooter>
            </Layout>
        </React.Fragment>
    );
};
export default observer(Roles);
